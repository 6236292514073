import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init';
// Connects to data-controller="back--supplier-purchase-order-new"
export default class extends Controller {
  static targets = ['suppliable', 'purchasable']
  choose() {
    Turbo.visit(`/back/supplier_purchase_orders/new?suppliable=${$(this.suppliableTarget).val()}&purchasable=${$(this.purchasableTarget).val()}`)
  }

  next() {
    $(this.suppliableTarget).html('')
    console.log($(this.element).data())
    $.each($(this.element).data().suppliables, (name, key) => {
      if(key != $(this.purchasableTarget).val()) {
        $(this.suppliableTarget).append(`
          <option value="${key}">${name}</option>
        `)
      }
    })
    $(this.element).find('> div').eq(0).hide()
    $(this.element).find('> div').eq(1).show()
    initSelect2($(this.suppliableTarget))
  }

  prev() {
    $(this.element).find('> div').eq(1).hide()
    $(this.element).find('> div').eq(0).show()
  }
}
