import { Controller } from "@hotwired/stimulus"
import { ExportXlsx } from '../../../../shared/export_xlsx';

// Connects to data-controller="back--statistics--v2--statistics-product-category-show"
export default class extends Controller {
  connect() {
  }

  exportXlsx() {
    new ExportXlsx('stats_table_category_products', {}, 'export-statistics-product-category').render()
  }
}
