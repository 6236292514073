import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--customer-invoice-delete-modal"
export default class extends Controller {
  deleteInvoice() {
    $.get(`/admin/customer_invoices/${$(this.element).data().id}/delete_invoice`).done(() => {
      $('#modal_rails_5').modal('hide')
      $(`#customer-invoice-tr-${$(this.element).data().id}`).remove()
    })
  }
}
