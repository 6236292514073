import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--listing--basket-item-lines-item"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
  }

  askConfirmation(e) {
    initModal()
    let text = `Confirmer que le produit ${$(this.element).find('.basket_item_line_name').text()} `
    text += $(e.currentTarget).data().type == 'processing' ? "est prêt" : "a été retiré"
    $('#listing_basket_item_lines_view').addClass('updating_basket_item_line')
    $('#modal_rails_5').modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    })
    $('.modal-body').hide()
    $('.modal-title').text(text)
    $('.modal-footer').html(`
      <div class='text-right' data-controller="back--listing--basket-item-lines-modal">
        <button type="button" class="btn btn-outline-primary" data-action="back--listing--basket-item-lines-modal#cancel">Annuler</button>
        <button id="modal-action" type="button" class="btn btn-primary" data-id="${$(this.element).data().id}" data-type="${$(e.currentTarget).data().type}"
                 data-action="back--listing--basket-item-lines-modal#confirm">Confirmer</button>
      </div>

    `)
  }

  confirmAction(type) {
    $('#listing_basket_item_lines_view').removeClass('updating_basket_item_line')
    $('#modal_rails_5').modal('hide')
    this[type]()
  }

  processing() {
    $(this.element).find('div').eq(0).toggleClass('listing_basket_item_line_cell_waiting listing_basket_item_line_cell_processing')
    this.updateStatus('processing')
  }

  delivered() {
    $(this.element).remove()
    this.updateStatus('delivered')
  }

  updateStatus(status) {
    $.ajax({
      method: 'PATCH',
      url: `/back/listing_basket_item_lines/${$(this.element).data().id}`,
      data: {status: status}
    })
  }
}
