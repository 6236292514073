import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--ai-event-exception-fields"
export default class extends Controller {
  connect() {
    this.checkType()
    $(this.element).find('select').on('select2:select',(e) => {
      this.checkType()
    })
  }

  checkType() {
    this.loadDatepicker()
    $(this.element).find('.ai_event_exception_newDate').toggle($(this.element).find('select').val() == 'POSTPONED')
  }

  removeException() {
    $(this.element).addClass('deleted')
    $(this.element).find('.remove_ai_event_exception_link').click()
    $(this.element).parents('.ai_event_date_configuration_fields').get(0).controller.checkEventExceptions()
  }

  loadDatepicker() {
    setTimeout(() => {
      $(this.element).find('.turbo_datepicker:visible').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
        var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
        $(".datepicker").css("top", top)
      }).on('changeDate', () => {
        $(this.element).parents('form').valid()
      })
    }, 200)
  }
}
