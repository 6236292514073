import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--stock--ingredient--ingredient-form"
export default class extends Controller {
  connect() {
    this.element[this.identifier] = this
    $(this.element).find('select').on('select2:select', function() {this.dispatchEvent(new Event('change', {bubbles: true}))})
    this.measureChanged()
    this.intermediateChanged()
    if($('#ingredient_container_name').length) {
      this.priceChanged()
    }
    let container_quantity = $('.ingredient_container_quantity input')
  }

  measureChanged() {
    this.setMeasure()
    this.setContainerName()
  }

  intermediateChanged() {
    let is_intermediate = $(this.element).find('#ingredient_intermediate').prop('checked')
    $(this.element).find('.ingredient_form-supplier-ids-container').toggle(!is_intermediate)
    $(this.element).find('.ingredient_form-container-container').toggle(!is_intermediate)
    $(this.element).find('.ingredient_price').toggle(!is_intermediate)
    if(is_intermediate) {
      $(this.element).find('.ingredient_price input').val(0)
    }
  }

  setMeasure() {
    $(this.element).find('.ingredient_price .input-group-text').text(`€ / ${$('.ingredient_measure :selected').text()}`)
  }

  priceChanged() {
    let container_quantity = $('.ingredient_container_quantity input')
    if($('#ingredient_price').val() && $('#ingredient_price').valF() && container_quantity.val() && container_quantity.valF()) {
      $('#ingredient_price_per_container').val(($('#ingredient_price').valF() * container_quantity.valF()).toFixed(4))
    }
  }

  setContainerName() {
    if($('#ingredient_container_name').length) {
      if($('#ingredient_container_name').val().length) {
        $('.ingredient_container_quantity .input-group-append').show()
        $('.ingredient_container_quantity .input-group-text').text(`${$('.ingredient_measure :selected').text()} / ${$('#ingredient_container_name').val()}`)
        $('.ingredient_price_per_container .input-group-text').text(`€ / ${$('#ingredient_container_name').val()}`)
      } else {
        $('.ingredient_container_quantity .input-group-append').hide()
        $('.ingredient_price_per_container .input-group-append').hide()
      }      
    }

  }

  pricePerContainerChanged(e) {
    let container_quantity = $('.ingredient_container_quantity input')
    if($(e.currentTarget).val() && $(e.currentTarget).valF() && container_quantity.val() && container_quantity.valF()) {
      $('.ingredient_price input').val(($(e.currentTarget).valF() / container_quantity.valF()).toFixed(4))
    }
  }
}
