require('../libs/notify');

export function flashMessage (title, message, type) {
  $.notify({
    title: title,
    message: message,
  }, {
    type: type,
    autoHide: false,
    clickToHide: true,
    z_index: 2000,
    placement: {
      from: 'top',
      align: 'left'
    }
  })
}