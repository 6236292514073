import Utils__TurboNavController from "controllers/utils/turbo_nav_controller"

// Connects to data-controller="admin--goal-historical"
export default class extends Utils__TurboNavController {
  toggleCallback() {
    if(this.currentTab.find('.dataTables_scroll').length == 0) {
      this.currentTab.find('table').DataTable({
        "pageLength": 2000,
        'order': [],
        'scrollX': true,
        "caseInsensitive": true
      })
    }
  }
}
