import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--commercial-action-type-result-bindings"
export default class extends Controller {
  toggle(e) {
    $.ajax({
      url: `/admin/commercial_action_types/${$(this.element).data().id}/update_bindings`,
      type: 'PATCH',
      data: {origin: $(e.currentTarget).data().origin, target: $(e.currentTarget).data().target, value: !$(e.currentTarget).find('input').prop('checked')}
    })
    $(e.currentTarget).find('input').prop('checked', !$(e.currentTarget).find('input').prop('checked'))
  }
}
