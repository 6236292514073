import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--statistics--export-categories"
export default class extends Controller {
  connect() {
    $('#modal .modal-footer').hide()
  }
  export(e) {
    let data = $('.filter_card').data()
    window.location = `/back/statistics/v2/dashboard/export_categories?type=${$(e.currentTarget).data().type}&dates=${data.dates.split('/').join('_')}&ca=${data.ca}&group_orders=${data.grouporders}&shops=${data.shops}&sourceOrderType=${data.sourceOrderType.join('_')}&contact_type=${data.contactType}`

    $('#modal .modal-body').html('Votre fichier est en cours de préparation. Cela peut prendre un peu de temps. Vous pourrez fermer cette fenêtre à la fin du téléchargement')
    $('#modal .modal-footer').html(`
      <p class='btn btn-outline-primary' onClick="location.reload()">J'ai reçu mon fichier</p>
    `)
    $('#modal .modal-footer').show()
  }
}
