import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--statistics--statistics-general-filter"
export default class extends Controller {
  connect() {
    $('#statistics_general_daterangepicker').daterangepicker({
      parentEl: ".filter_card",
      maxDate: moment().add(60, 'days'),
      ranges: {
        "Aujourd'hui": [moment(), moment()],
        'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Les 7 derniers jours': [moment().subtract(7, 'days'), moment()],
        'Les 30 derniers jours': [moment().subtract(29, 'days'), moment()],
        "L'année en cours": [moment().startOf('year'), moment()],
        'Les 30 prochains jours': [moment(), moment().add(30, 'days')]
      },
      startDate: moment($('.filter_card').data().dates.split('-')[0].split('/').reverse().join('-')),
      endDate: moment($('.filter_card').data().dates.split('-')[1].split('/').reverse().join('-'))
    }, (start, end, picker) => {
      if(picker == "Personnalisé") {
        $('#statistics_general_daterangepicker span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      } else {
        $('#statistics_general_daterangepicker span').text(picker)
      }
      $('.filter_card').data().dates_2 = `${start.format(('DD/MM/YYYY'))}-${end.format(('DD/MM/YYYY'))}`
    });
  }

  reloadDatas() {
    reloadDatas()
  }
}
