import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--prospect-ask-lost"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.sub_reasons = $(this.element).data().reasons
      this.checkSubReasons() 
      this.subs = $(this.element).data().commercialActionSubTypes
      this.checkSubs() 

      $('#prospect_prospect_lost_main_reason_id').on('select2:select', () => {
        this.checkSubReasons()
      })     
      $('#prospect_next_commercial_action_attributes_commercial_action_type_id').on('select2:select', () => {
        this.checkSubs()
      })     
    }, 300)
    setTimeout(() => {
      $(this.element).find('.prospect_next_commercial_action_date').removeClass('d-none')
      $(this.element).find('.datepicke').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
        var top = parseInt($('main').css('paddingTop')) + parseInt($(this.element).find('.datepicke').css("top")) - 40;
        $(this.element).find('.datepicke').css("top", top);
      });
    }, 1000)
  }

  checkSubReasons() {
    $('#prospect_prospect_lost_sub_reason_id option').remove()
    $('#prospect_prospect_lost_sub_reason_id').val('')
    if(this.sub_reasons[$('#prospect_prospect_lost_main_reason_id').val()]) {
      let subs = this.sub_reasons[$('#prospect_prospect_lost_main_reason_id').val()]
      for(const sub in subs) {
        $('#prospect_prospect_lost_sub_reason_id').append(`<option value='${subs[sub].id}'>${subs[sub].reason}</option>`)
      }
      $('.prospect_prospect_lost_sub_reason_id').removeClass('d-none')
      initElementSelect2($('#prospect_prospect_lost_sub_reason_id'))
    } else {
      $('.prospect_prospect_lost_sub_reason_id').addClass('d-none')
    }
  }

  checkSubs() {
    $('#prospect_next_commercial_action_attributes_commercial_action_sub_type_id option').remove()
    $('#prospect_next_commercial_action_attributes_commercial_action_sub_type_id').val('')
    if(this.subs[$('#prospect_next_commercial_action_attributes_commercial_action_type_id').val()]) {
      let subs = this.subs[$('#prospect_next_commercial_action_attributes_commercial_action_type_id').val()]
      for(const sub in subs) {
        $('#prospect_next_commercial_action_attributes_commercial_action_sub_type_id').append(`<option value='${subs[sub].id}'>${subs[sub].name}</option>`)
      }
      $('.prospect_next_commercial_action_commercial_action_sub_type_id').removeClass('d-none')
      initElementSelect2($('#prospect_next_commercial_action_attributes_commercial_action_sub_type_id'))
    } else {
      $('.prospect_next_commercial_action_commercial_action_sub_type_id').addClass('d-none')
    }
  }
}
