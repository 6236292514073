import Utils__NestedFormController from "controllers/utils/nested_form_controller"
import Utils__TurboModalFormSliderController from 'controllers/utils/turbo_modal_form_slider_controller';
import { Controller } from "@hotwired/stimulus"
import { classAgregator } from 'shared/class_agregator'
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="back--stock--intermediate-ingredient-recipe"
export default class extends classAgregator(Controller, Utils__NestedFormController, Utils__TurboModalFormSliderController) {
  static targets = ['nav', 'templateIntermediateIngredientRecipeIngredient', 'contentIntermediateIngredientRecipeIngredient']

  connect() {
    this.initFormSlider()
    $('.modal-footer .float-right .btn').eq(1).html('<span>Terminer</span>')
    setTimeout(() => {
      $.ajax({
        url: `/back/intermediate_ingredient_recipes/fetch_form_datas`,
        method: 'get',
        dataType: "json",
        success: (response) => {
          this.ingredients = response
          this.ingredientSelect.html(this.populateSource(this.ingredients))
          this.loadIngredients()
          this.calcCost()
        }
      })
    }, 500)    
  }

  get ingredientSelect() {
    return $('.recipe-form_ingredients-container select')
  }

  addIngredient() {
    this.addAssociation('IntermediateIngredientRecipeIngredient', {id: this.ingredientSelect.val()})
    this.ingredientSelect.find(':selected').prop('disabled', true)
    this.toggleSelect(this.ingredientSelect)
  }

  loadIngredients() {
    $.each($('.intermediate-ingredient-recipe-ingredient-fields'), (i, element) => {
      let id = $(element).find('.intermediate_ingredient_recipe_intermediate_ingredient_recipe_ingredients_ingredient_id input').val()
      this.ingredientSelect.find(`option[value="${id}"]`).prop('disabled',true)
      this.decorateIngredient($(element), id)
    })
    this.toggleSelect(this.ingredientSelect)
  }

  toggleSelect(select) {
    if(select.length == 0) { return }
    select.val(select.find('option').not(':disabled').eq(0).val())
    select.parent().parent().toggle(select.find('option').not(':disabled').length > 0)
    if(select.hasClass('select2-hidden-accessible')) {
      updateSelect2(select)
    } else {
      initSelect2(select)
    }

    select.trigger('change')
  }

  callbackAfterAddIntermediateIngredientRecipeIngredient(element, options) {
    element.find('.intermediate_ingredient_recipe_intermediate_ingredient_recipe_ingredients_quantity input').val(1)
    this.decorateIngredient(element, options.id)
    this.calcCost()
  }

  callbackBeforeRemoveIntermediateIngredientRecipeIngredient(element) {
    return new Promise((resolve, reject) => {
      let ingredient_id = element.find('.intermediate_ingredient_recipe_intermediate_ingredient_recipe_ingredients_ingredient_id input').val()
      this.ingredientSelect.find(`option[value="${ingredient_id}"]`).prop('disabled', false)
      this.toggleSelect(this.ingredientSelect)
      resolve()
    })
  }

  quantityChanged(e) {
    if ($(e.currentTarget).val().length == 0 || $(e.currentTarget).valF() < 0) {$(e.currentTarget).val(1)}
    this.calcCost()
  }  

  populateSource(source) {
    let html = ''
    for(const id in source) { html += `<option value='${id}'>${source[id].name}</option>` }
    return html
  }  

  decorateIngredient(element, id) {
    element.find('.recipe-ingredient-fields__ingredient-name').text(this.ingredients[id].name)
    element.find('.intermediate_ingredient_recipe_intermediate_ingredient_recipe_ingredients_ingredient_id input').val(id)
    element.find('.intermediate_ingredient_recipe_intermediate_ingredient_recipe_ingredients_quantity .input-group-text').text(this.ingredients[id].referent_unit)
  }  

  callbackAfterRemoveIntermediateIngredientRecipeIngredient() {
    this.calcCost()
  }  

  calcCost() {
    if($('#intermediate_ingredient_recipe_quantity').valF() == 0) {return false;}
    this.sum = 0
    $.each($('.recipe-import-fields').not(".deleted"), (index, fields) => {
      let quantity = $(fields).find('.recipe_recipe_imports_quantity input').valF()
      let price = parseFloat(this.base_recipes[$(fields).find('.recipe_recipe_imports_recipe_base_id input').valF()].price)
      this.sum += price * quantity
    })

    $.each($('.intermediate-ingredient-recipe-ingredient-fields').not(".deleted"), (index, fields) => {
      let quantity = $(fields).find('.intermediate_ingredient_recipe_intermediate_ingredient_recipe_ingredients_quantity input').valF()
      let price = parseFloat(this.ingredients[$(fields).find('.intermediate_ingredient_recipe_intermediate_ingredient_recipe_ingredients_ingredient_id input').valF()].price)
      this.sum += price * quantity
    })

    if (this.sum == 0) {
      $('.recipe_cost').text(this.sum.toFixed(2))
      $('.unit_cost').text(0)
      $('#recipe_price').val(0)
      $('#recipe_price_total').val(0)
    } else {
      $('.recipe_cost').text(this.sum.toFixed(2))
      this.unit_cost = (this.sum / $('#intermediate_ingredient_recipe_quantity').val()).toFixed(2)
      $('.unit_cost').text(this.unit_cost)
      $('#recipe_price').val(this.unit_cost)
      $('#recipe_price_total').val(this.sum.toFixed(2))
    }
  }
}
