import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--group-selections-form"
export default class extends Controller {
  connect() {
    $(this.element).find('.preview_color_container').spectrum({
      type: "component",
      hideAfterPaletteSelect: true,
      showButtons: false,
      allowEmpty: false,
      showInput: true,
      preferredFormat: "hex",
      palette: [
          ["#000","#444","#666","#999"],
          ["#f00","#f90","#ff0","#0f0","#0ff","#00f","#90f","#f0f"],
          ["#e06666","#f6b26b","#ffd966","#93c47d","#76a5af","#6fa8dc","#8e7cc3","#c27ba0"],
          ["#c00","#e69138","#f1c232","#6aa84f","#45818e","#3d85c6","#674ea7","#a64d79"],
          ["#900","#b45f06","#bf9000","#38761d","#134f5c","#0b5394","#351c75","#741b47"],
          ["#600","#783f04","#7f6000","#274e13","#0c343d","#073763","#20124d","#4c1130"]
      ],      
      color: $(this.element).find('.preview_color_container').find('div').css('backgroundColor'),
      move: function(color) {
        let hex = color.toHexString();
        $(this).find('div').css('backgroundColor', hex)
        $($(this).data().input).val(hex)
      }
    });
  }
}
