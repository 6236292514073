import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--stock--ingredient--ingredient-stock-movement-form"
export default class extends Controller {
  static targets = ['quantity', 'priceLine', 'price', 'containerQuantity']

  connect() {
    this.containerQuantity = parseFloat($(this.element).data().containerquantity)
  }

  quantityChanged() {
    let quantity = $(this.quantityTarget).valF() ? $(this.quantityTarget).valF() : 0
    if(this.containerQuantity) {
      $("#ingredient_stock_movement_container_quantity").val((quantity / this.containerQuantity).toFixed(2))
    }
    if(this.hasPriceLineTarget) {
      this.calcPriceLine()
    }
    
  }

  calcPriceLine() {
    $(this.priceLineTarget).val(($(this.quantityTarget).valF() * $(this.priceTarget).valF()).toFixed(2))
    if(this.containerQuantity) { this.calcPriceLineContainer() }
  }

  calcPriceLineContainer() {
    $('#ingredient_stock_movement_price_per_container').val(($(this.priceTarget).valF() * this.containerQuantity).toFixed(2))
  }

  priceLineChanged() {
    $(this.priceTarget).val(($(this.priceLineTarget).valF() / $(this.quantityTarget).valF()).toFixed(2))
    if(this.containerQuantity) { this.calcPriceLineContainer() }
  }

  containerQuantityChanged() {
    $(this.quantityTarget).val(($(this.containerQuantityTarget).valF() * this.containerQuantity).toFixed(2))
    this.calcPriceLine()
  }

  pricePerContainerChanged(e) {
    if($(e.currentTarget).val() && $(e.currentTarget).valF()) {
      $('.ingredient_stock_movement_price input').val(($(e.currentTarget).valF() / this.containerQuantity).toFixed(2))
      this.calcPriceLine()
    }
  }
}
