import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="back--stock--product-stock--filter"
export default class extends Controller {
  static targets = ['supplier', 'name', 'category', 'remainingPermanent', 'suppliable', 'date']
  connect() {
    if(this.hasRemainingPermanentTarget) {
      this.remainingPermanent = $(this.remainingPermanentTarget).val()
    }
    if(this.hasSuppliableTarget) {
      this.suppliable = $(this.suppliableTarget).val()
    }
    $(this.element).find('select').on('select2:select',function(){this.dispatchEvent(new Event('change',{bubbles:true}))})
    $.ajax({url: '/back/replenishement_stocks/fetch_autocomplete', dataType: 'json'}).done(data => {
      this.loadAutocomplete(data)
    })
  }

  blurName() {
    this.urlParams = new URLSearchParams(window.location.search);
    let previousValue = this.urlParams.get('name')
    this.urlParams.delete('name')
    this.urlParams.delete('page')
    if($(this.nameTarget).val().length) { this.urlParams.set('name', $(this.nameTarget).val())}
    if(previousValue != this.urlParams.get('name')) { this.filter() }
  }

  loadAutocomplete(names) {
    $(this.nameTarget).autocomplete({
      minLength: 3,
      source: function( request, response ) {
        var matcher = new RegExp("\\b" + $.ui.autocomplete.escapeRegex(request.term), "i");
        response($.grep(names, function(item){
          return matcher.test( item.value );
        }));
      },
      select: ( event, ui ) => {
        $(this.nameTarget).val(ui.item.label)
        this.blurName()
        return false;
      }
    })
  }

  supplierChanged() {
    this.selectChanged($(this.supplierTarget).val(), 'supplier')
  }

  categoryChanged() {    
    this.selectChanged($(this.categoryTarget).val(), 'category')
  }

  dateChanged(e) {
    let field = 'start_date'
    let target = $(e.currentTarget).data().date
    this.urlParams = new URLSearchParams(window.location.search);
    this.urlParams.set(field, target)
    this.urlParams.set('date_page', $(e.currentTarget).data().page)
    this.filter()
    $(this.dateTarget).val($(e.currentTarget).data().date.replaceAll('-', '/'))
  }

  inputDateChanged(e) {
    setTimeout(() => {
      this.urlParams = new URLSearchParams(window.location.search);
      if($(this.dateTarget).val().length && this.urlParams.get('start_date') != $(this.dateTarget).val().replaceAll('/', '-')) {
        this.urlParams.set('start_date', $(this.dateTarget).val().replaceAll('/', '-'))
        this.filter()      
      }
    }, 100)
  }

  blurRemainingPermanent() {
    let remainingPermanent = isNaN($(this.remainingPermanentTarget).valF()) ? '' : $(this.remainingPermanentTarget).valF()
    if(remainingPermanent === '') { $(this.remainingPermanentTarget).val('') }
    console.log(remainingPermanent)
    console.log(this.remainingPermanent)
    if(remainingPermanent !== this.remainingPermanent) {
      this.urlParams = new URLSearchParams(window.location.search);
      this.urlParams.delete('remaining_permanent')
      this.urlParams.delete('start_date')
      this.urlParams.delete('page')
      if(remainingPermanent !== '') { 
        this.urlParams.set('remaining_permanent', remainingPermanent)
      }
      this.filter()
      this.remainingPermanent = remainingPermanent
    }
  }

  suppliableChanged() {
    this.selectChanged($(this.suppliableTarget).val(), 'suppliable')
  }

  selectChanged(target, field) {
    this.urlParams = new URLSearchParams(window.location.search);
    this.urlParams.delete('page')
    this.urlParams.delete(field)
    if(target == 'tous') {
      this.urlParams.delete(field)
    } else {
      this.urlParams.set(field, target)
    }
    this.filter()
  }

  filter() {
    let url = window.location.pathname
    console.log(this.urlParams.toString())
    if(this.urlParams.toString().length > 0) { url += `?${this.urlParams.toString()}`}
    if($('#replenishment_stock_container .tab-pane').length) {
      history.pushState({}, null, url.toString())
      Turbo.navigator.history.replace(url.toString())        
      $('#replenishment_stock_container .tab-pane').html('')
      $('#replenishment_stock_container .turbo_nav').get(0).controller.toggleCallback()
    } else {
      ajaxTurboStream(url, true)
    }

  }
}
