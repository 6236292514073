import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream'
// Connects to data-controller="catalog--product-index-tmp"
export default class extends Controller {
  showPlu(e) {
    ajaxTurboStream(
      `/back/products/${$(e.currentTarget).parents('tr').data().id}/show_plu_codes`,
      false
    )
  }

  showPpuPrices(e) {
    ajaxTurboStream(
      `/back/products/${$(e.currentTarget).parents('tr').data().id}/show_ppu_prices`,
      false
    )    
  }
}
