import Utils__NestedFormController from "controllers/utils/nested_form_controller"
import { Controller } from "@hotwired/stimulus"
import { classAgregator } from 'shared/class_agregator'
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="admin--prospect-lost-main-reason-form"
export default class extends classAgregator(Controller, Utils__NestedFormController) {
  static targets = ['templateProspectLostSubReason', 'contentProspectLostSubReason']

  callbackAfterAddProspectLostSubReason(element, options) { }
  callbackBeforeRemoveProspectLostSubReason(element) {
    return new Promise((resolve, reject) => { resolve() })
  }
  callbackAfterRemoveProspectLostSubReason() {}
}
