import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="no-quota-ticket-configuration"
export default class extends Controller {
  connect() {
    this.interval_orders = setInterval(() => {
      $.ajax({
        url: `/no_quota_ticket_module/check_progress?shop_group_id=${$(this.element).data().shopGroupId}`,
        dataType: 'json',
        success: (data) => {
          if(data.status == 'active') {
            clearInterval(this.interval_orders)
            if($(this.element).data().context == 'bo') {
              window.location = '/'
            } else {
              webkit.messageHandlers.callbackHandler.postMessage(JSON.stringify({
                status: data.status
              }));
            }
            
          }
        }
      })
    }, 5000)
  }
}
