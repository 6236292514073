export class CustomCommentCalendarForm {
  constructor(calendar) {
    this.calendar = calendar
    $(document).on('click', '#calendar_custom_comment_focusout', () => {
      $('#calendar_custom_comment_focusout').hide()
      $('#calendar_custom_comment_container .form-group').show()
    })

    $(document).on('blur', '#calendar_custom_comment_container textarea', () => {
      $('#calendar_custom_comment_focusout').show()
      $('#calendar_custom_comment_container .form-group').hide()
      $('#calendar_custom_comment_focusout p').eq(1).text($('#calendar_custom_comment_container textarea').val())
      $('#calendar_custom_comment_focusout').toggleClass('no_custom_comment', $('#calendar_custom_comment_container textarea').val().length < 1)

      $.ajax({
        method: 'PUT',
        url: `/back/orders/calendar/update_status/${this.calendar.get_currentOrder().id}`,
        data: {field: 'custom_comment', value: $('#calendar_custom_comment_container textarea').val()}
      })
    })
  }
}
