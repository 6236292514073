import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="freemium-shop-group-processing"
export default class extends Controller {
  connect() {
    this.interval_orders = setInterval(() => {
      $.ajax({
        url: `/configuration/check_configuration?secret_key=${$(this.element).data().key}`,
        dataType: 'json',
        success: (data) => {
          if(data.status == 'completed') {
            clearInterval(this.interval_orders)
            try {
              webkit.messageHandlers.callbackHandler.postMessage(JSON.stringify({
                alias: data.alias
              }));
            } catch (e) { }
            $('.card-body').html(`
              <p class='text-15'>Votre compte a été configuré.</p>
              <p class='text-15'>Vous pouvez désormais vous connecter à votre tableau de bord en utilisant l'adresse email <em>${data.email}</em></p>
              <p class='text-15'>Pour commencer à utiliser votre caisse vous devrez utiliser l'identifiant <em>${data.alias}</em></p>
              <div class='text-center'>
                <a href="/" class='btn btn-primary'>Je me connecte à mon tableau de bord</a>
              </div>
            `)
          }
        }
      })
      // clearInterval(this.interval_orders)
    }, 5000)
  }
}
