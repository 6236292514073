import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--settings"
export default class extends Controller {
  connect() {
    if($('#setting_select_shop_logo').length) {
      $(`#setting_shop_logo_${$('#setting_select_shop_logo').val()}`).addClass('active')
      $(this.element).find('#setting_select_shop_logo').on('select2:select', () => {
        $('.setting_shop_logo_container').removeClass('active')
        $(`#setting_shop_logo_${$('#setting_select_shop_logo').val()}`).addClass('active')
      })
    }
  }
}
