import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--statistics--product-prices-table"
export default class extends Controller {
  connect() {
    $(this.element).DataTable({
      "pageLength": 2000,
      'order': [],
      'scrollX': true,
      "caseInsensitive": true
    })
  }
}
