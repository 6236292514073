import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--stock--recipe--recipe-import-field"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    this.formatter = new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR', maximumFractionDigits: 3, minimumFractionDigits: 2})
  }
  
  quantityChanged(e) {
    if ($(e.currentTarget).val().length == 0 || $(e.currentTarget).valF() < 0 || $(e.currentTarget).valF() == 0) {$(e.currentTarget).val(1)}
    $('#modal .modal').get(0).controller.calcCost()
  }

  setPrice(price) {
    this.price = price
  }

  get recipeSum() {
    return $('#modal .modal').get(0).controller.recipeSum
  }

  // Old
  set_recipe() {
    this.recipe = base_recipes[$(this.element).find('.recipe_recipe_imports_recipe_base_id select').val()]
    this.quantity = parseFloat($(this.element).find('.recipe_recipe_imports_quantity input').val())
    if(this.quantity == 0) { this.quantity = 1}
    this.price = parseFloat(this.recipe.price * this.quantity)
    $('.recipe_form').get(0).recipe.calc_cost()
    $(this.element).find('.input-group-text').text(`x ${this.recipe.quantity} ${this.recipe.measure}`)
  }

  displayPrices() {
    let quantity = $(this.element).find('.recipe_recipe_imports_quantity input').valF()
    let price_total = this.price * quantity
    $(this.element).find('.recipe-import-fields__price').text(this.formatter.format(this.price))
    $(this.element).find('.recipe-import-fields__total').text(this.formatter.format(price_total))
    if(this.recipeSum && this.recipeSum > 0) {
      $(this.element).find('.recipe-import-fields__percentage').text(((price_total / this.recipeSum) * 100).toFixed(2))
    }
  }

  remove() {
    $(this.element).addClass('deleted_import')
    $(`#base_step_${this.id}`).remove()
    $(this.element).find('a').click()
    setTimeout(() => {$('.recipe_form').get(0).recipe.calc_cost()}, 100)
  }

  price() {
    return this.price
  }

  change_recipe(e) {
    this.set_recipe()
    $(this.element).find('.import_ingredients_list').html(this.ingredients_html())
    $(`#base_step_${this.id}`).html(this.steps_html())
  }

  set_ingredients() {
    $(this.element).find('.import_ingredients_list').html(this.ingredients_html())
  }

  add_step() {
     $('#base_steps').append(`
      <div id="base_step_${this.id}">
        ${this.steps_html()}
      </div>
    `)
  }

  steps_html() {
    return `
      <p class='mb-0'><u>${this.recipe.name}</u></p>
      ${this.recipe.steps.map((step) => {
        return `
          <p class='mb-0'><b>${step.name}</b> - ${step.duration} minutes - ${step.description}</p>
        `
      }).join('')}
    `
  }

  ingredients_html() {
    return `
      <p class='mb-0'>${this.recipe.ingredients.map((ing) => {return `${ing.quantity * this.quantity} ${ing.measure} ${ing.name}`}).join(', ')}
    `

  }
}
