import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catalog--menus--menus-form"
export default class extends Controller {
  connect() {
    this.checkToggleOverridenSC()
    this.checkToggleOverridenSCImpactPrice()
    $.each($('.smart_collect_day_configuration_product_available'), (i, input) => {
      this.toggleSCConfigurationAvailable($(input))
    })    
  }

  nameChanged(e) {
    if($('#menu_smart_collect_menu_name').length && ($('#menu_smart_collect_menu_name').val().length == 0 || $('#new_menu').length)) {
      $('#menu_smart_collect_menu_name').val($(e.currentTarget).val())
      $('#menu_smart_collect_menu_name').valid()
    }
  }

  clickTabSCShoppable(e) {
    if(!$(e.currentTarget).hasClass('active')) {
      $('.smart_collect_product_day_configuration_tab .active').removeClass('active')
      $('.smart_collect_product_day_configuration_container.active').removeClass('active')
      $(e.currentTarget).addClass('active')
      $(`#smart_collect_product_day_configuration_${$(e.currentTarget).data().target}`).addClass('active')
    }
  }  

  clickTabSCShoppableImpactPrice(e) {
    if(!$(e.currentTarget).hasClass('active')) {
      $('.smart_collect_product_impact_price_tab .active').removeClass('active')
      $('.smart_collect_product_impact_price_container.active').removeClass('active')
      $(e.currentTarget).addClass('active')
      $(`#smart_collect_product_impact_price_${$(e.currentTarget).data().target}`).addClass('active')
    }
  }  

  checkToggleOverridenSC() {
    $('.smart_collect_day_configuration_product_infos').toggle(!$('#menu_smart_collect_menu_override_day_configuration').prop('checked'))
    $('.smart_collect_day_configuration_product_overriding').toggle($('#menu_smart_collect_menu_override_day_configuration').prop('checked'))
    initElementSelect2($('.smart_collect_product_day_configuration_container select:visible'))
  }

  checkToggleOverridenSCImpactPrice() {
    $('.smart_collect_impact_price_product_infos').toggle(!$('#menu_smart_collect_menu_override_impact_price').prop('checked'))
    $('.smart_collect_impact_price_product_overriding').toggle($('#menu_smart_collect_menu_override_impact_price').prop('checked'))
  }

  toggleSCConfigurationAvailable(input) {
    input.parents('.smart_collect_day_configuration_product_fields').eq(0).find('.smart_collect_day_configuration_product_delay')
      .toggleClass('d-none', !input.prop('checked'))
  }  

  SCConfigurationAvailableChanged(e) {
    this.toggleSCConfigurationAvailable($(e.currentTarget))
  }

  editMenuSC() {
    $('.dish_fields.active').removeClass('active')
    $('.menu_dish_list.active').removeClass('active')
    $('#menu_form_edit_dish_group_nav').removeClass('active')
    $('#menu_form_edit_dish_group_tab').hide()
    $('#menu_form_edit_smart_collect_tab').show()
    $('#menu_form_edit_smart_collect_nav').addClass('active')
    initElementSelect2($('select:visible'))
  }

  toggleDishGroup() {
    $('.dish_fields.active').removeClass('active')
    $('.menu_dish_list.active').removeClass('active')
    $('#menu_form_edit_smart_collect_nav').removeClass('active')
    $('#menu_form_edit_smart_collect_tab').hide()
    $('#menu_form_edit_dish_group_tab').show()
    $('#menu_form_edit_dish_group_nav').addClass('active')
  }

  initDishGroup(new_group) {
    initModal()
    $('.modal').modal('show')
    $('.modal-footer').hide()
    $('.modal-title').text(new_group ? "Nouveau groupe de plats" : "Modification d'un groupe de plats")
    $('.modal-body').html(`
      <div class="loading_modal">
        <div class="loading_circle"></div>
      </div>
    `)    
  }

  addDishGroup() {
    this.initDishGroup(true)

    setTimeout(() => {
      $('.modal-body').html(`
        <div data-controller="catalog--menus--menu-dish-group" id="modal-menu-dish-group"></div>
      `)
    }, 500)
  }

  editDishGroup(e) {
    this.initDishGroup(false)
    let target = $(e.currentTarget)
    setTimeout(() => {
      $('.modal-body').html(`
        <div data-controller="catalog--menus--menu-dish-group" data-existing="${target.parents('.dish_group_field').get(0).id}" id="modal-menu-dish-group"></div>
      `)
    }, 500)
  }

  removeDishGroup(e) {
    $(e.currentTarget).parents('.dish_group_field').remove()
  }
}
