import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--supplier-purchase-order-ask-import"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    $(this.element).validate()
    $(this.element).on('submit', () => {
      if($(this.element).valid()) {
        $('#loading_app').addClass('d-flex')
      }
    })
  }

  calc_total() {}
}
