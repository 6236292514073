import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--export-z-ticket-ca"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      $('.stock_export_date_container').html(`
        <div class="form-label-group string optional">
          <label class="has-float-label">
            <input class="form-control string optional form-control-line datepicke" id="z_ticket_ca_ask_date_begin" data-type="begin_date" type="text" value="${$(this.element).data().date}" data-action="back--export-z-ticket-ca#dateChanged">
            <span class="float-span">Date de début</span>
          </label>
        </div>  
        <div class="form-label-group string optional">
          <label class="has-float-label">
            <input class="form-control string optional form-control-line datepicke" id="z_ticket_ca_ask_date_end" type="text" value="${$(this.element).data().date}" data-action="back--export-z-ticket-ca#dateChanged">
            <span class="float-span">Date de fin</span>
          </label>
        </div>          
      `)
      $(this.element).find('input').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
        var top = parseInt($('main').css('paddingTop')) + parseInt($('.datepicker').css("top")) - 40 + 22;
        $('.datepicker').css("top", top);
      }).on('change', (e) => {
        let date_begin = $('#z_ticket_ca_ask_date_begin').val().split('/').join('-')
        let date_end = $('#z_ticket_ca_ask_date_end').val().split('/').join('-')
        $('.export_stock_link').eq(0).attr('href', `/back/export/z_ticket_ca?date_begin=${date_begin}&date_end=${date_end}`)
      })   
    }, 500)

    $(this.element).find('a').on('click', () => {
      $('.modal-body').html('Votre fichier est en cours de préparation. Cela peut prendre un peu de temps. Vous pourrez fermer cette fenêtre à la fin du téléchargement')
      $('.modal-footer').html(`
        <p class='btn btn-outline-primary' onClick="location.reload()">J'ai reçu mon fichier</p>
      `)      
    })    
  }
}
