import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--listing--configure-sort"
export default class extends Controller {
  connect() {
    this.views = {
      orders: [
        {key: 'continousSequence', text: "Par numéro de commande"},
        {key: 'clientLastName', text: 'Par nom du client'},
        {key: 'delivery_at', text: "Par date et<br>heure de livraison"},
        {key: 'is_paid', text: "Non payé / Payé"}
      ],
      sticker: [
        {key: 'continousSequence', text: "Par numéro de commande"},
        {key: 'clientLastName', text: 'Par nom du client'},
        {key: 'name', text: 'Par nom du produit'}
      ]
    }
    this.selectedView = $(this.element).data().view
    console.log(this.selectedView)
    this.existingKeys = $(`.sort_${this.selectedView} .listing-sort__configuration-display div`).map((i, e) => $(e).data().key).get()
    $(this.element).html(`
      <p>Faites glisser les champs sur lesquels vous souhaitez trier vos commandes dans l'ordre souhaité.</p>
      <p>Par exemple si vous souhaitez que vos commandes soient d'abord triées en fonction de la date et de l'heure de livraison puis par le nom du client, placez ci-dessous d'abord le champ de date puis, à la suite, celui du nom.</p>

      <p class='mb-1 font-13 font-semibold'>Options disponibles :</p>
      <div id="listing-sort-configure__options">${this.populateOptions()}</div>
      <p class='mb-1 font-13 font-semibold'>Sélection :</p>
      <div id="listing-sort-configure__selected">${this.populateSelected()}</div>
      <p id="listing-sort-configure__error" class='text-toporder d-none'>Vous devez sélectionner au moins une option</p>
      <div class='text-center'>
        <div class='btn btn-primary font-semibold' style='width: 250px; border-radius: 8px;' data-action="click->back--listing--configure-sort#valid">Valider</div>
      </div>
      
    `)

    $('#listing-sort-configure__options').sortable({
      //containment: 'parent',
      cursor: 'move',
      items: '.listing-sort-configure__item',
      connectWith: '#listing-sort-configure__selected',
      tolerance: 'pointer'
    })
    $('#listing-sort-configure__selected').sortable({
      //containment: 'parent',
      cursor: 'move',
      items: '.listing-sort-configure__item',
      connectWith: '#listing-sort-configure__options',
      tolerance: 'pointer'
    })
  }

  valid() {
    if($('#listing-sort-configure__selected .listing-sort-configure__item').length == 0) {
      $('#listing-sort-configure__error').removeClass('d-none')
      return false
    }
    $('#listing-sort-configure__error').addClass('d-none')
    let html = []
    let values = []
    $.each($('#listing-sort-configure__selected .listing-sort-configure__item'), (i, e) => {
      html.push($(e).get(0).outerHTML);
      values.push($(e).data().key)
    })
    $(`.listing_sort_window .sort_${this.selectedView} .listing-sort__configuration-display`).html(html.join('<i class="fas fa-chevron-right"></i>'))
    $('#modal .modal').modal('hide')
    $('#listing').get(0).listingmanager.updateSort(this.selectedView, values)
    $('#listing').get(0).listingmanager.reloadView()

  }

  populateOptions() {
    let html = ''
    console.log(this.existingKeys)
    for(const element of this.views[this.selectedView]) {
      if(!this.existingKeys.includes(element.key)) {
        html += `
          <div class='listing-sort-configure__item' data-key=${element.key}>
            ${element.text}
          </div>
        `
      }

    }
    return html
  }

  populateSelected() {
    let html = ''
    $.each($(`.sort_${this.selectedView} .listing-sort__configuration-display div`), (i, e) => {
      html += $(e).get(0).outerHTML
    })
    return html
  }

}
