import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--smart-collect-shoppable-vitrine-linables-index"
export default class extends Controller {
  connect() {
    $(this.element).sortable({
      axis: 'y',
      containment: 'parent',
      cursor: 'move',
      handle: '.fa-grip-vertical',
      items: '.smart-collect-shoppable-vitrine-linables-container__card',
      distance: 10,
      deactivate: (event, ui) =>  {
        var hash_position = {}
        $.each($(this.element).find('.smart-collect-shoppable-vitrine-linables-container__card'), function(index, element) {
          hash_position[$(element).data().id] = index + 1
        })
        $.ajax({
          url: `/back/smart_collect_shoppable_vitrine_linables/update_positions`,
          type: "POST",
          data: {positions: hash_position}
        })

      }
    })

    $.each($('.smart-collect-shoppable-vitrine-linables-container__category-card__body'), (i, element) => {
      $(element).sortable({
        axis: 'y',
        containment: 'parent',
        cursor: 'move',
        handle: '.fa-grip-vertical',
        items: '.smart-collect-shoppable-vitrine-linables-container__category__item',
        distance: 10,
        deactivate: (event, ui) =>  {
          var hash_position = {}
          $.each($(element).find('.smart-collect-shoppable-vitrine-linables-container__category__item'), function(index, item) {
            hash_position[$(item).data().id] = index + 1
          })
          $.ajax({
            url: `/back/smart_collect_shoppable_vitrine_linables/update_items_positions`,
            type: "POST",
            data: {
              positions: hash_position,
              shoppable_id: $(this.element).data().shoppableId,
              shoppable_type: $(this.element).data().shoppableType,
              item_type: $(element).parent().data().itemType == 'produits' ? 'SmartCollectProduct' : 'SmartCollectMenu'
            }
          })

        }
      })      
    })
  }

  toggleItems(e) {
    let container = $(e.currentTarget).parents('.smart-collect-shoppable-vitrine-linables-container__category-card').eq(0)
    if($(e.currentTarget).text().includes('Voir')) {
      $(e.currentTarget).text(`Cacher les ${container.data().itemType}`)
    } else {
      $(e.currentTarget).text(`Voir les ${container.data().itemType}`)
    }
    container.find('.smart-collect-shoppable-vitrine-linables-container__category-card__body').toggle()
  }
}
