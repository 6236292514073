import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--new-customer-tabs"
export default class extends Controller {
  connect() {
    if($('h1').text() == "TOPORDER MODE ECOLE") {
      $('#tab_devices').click()
    }
  }

  toggleTab(e) {
    if($(`#${$(e.currentTarget).get(0).href.split('#')[1]}`).html().length == 0 && $(e.currentTarget).data().href) {
      ajaxTurboStream($(e.currentTarget).data().href, false)
    }
  }
}
