export function animateValueCurrency(obj, end, symbol, currency) {
  let startTimestamp = null;
  let formatter = new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'})
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / 500, 1);
    let text = currency ? formatter.format(progress * end) : parseInt(progress * end)
    if(symbol) {
      text += ` ${symbol}`
    }
    obj.text(text);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}
