import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--stock--import-product-stock-movement-params"
export default class extends Controller {
  toggleCategories(e) {
    $('.import_stock_params_local_product_categories_ids input').prop('checked', $(e.currentTarget).hasClass('btn-primary'))
  }

  toggleShoppable(e) {
    $('.import_stock_params_warehouse_localshop_keys input').prop('checked', $(e.currentTarget).hasClass('btn-primary'))
    $('.import_stock_params_warehouse_localshop_keys input').valid()
  }
}
