import Utils__NestedFormController from "controllers/utils/nested_form_controller"
import { Controller } from "@hotwired/stimulus"
import { classAgregator } from 'shared/class_agregator'

// Connects to data-controller="admin--prospect-origins-form"
export default class extends classAgregator(Controller, Utils__NestedFormController) {
  static targets = ['templateProspectSubOrigin', 'contentProspectSubOrigin']
  connect() {
  }

  callbackAfterAddProspectSubOrigin(element, options) { }
  callbackBeforeRemoveProspectSubOrigin(element) {
    return new Promise((resolve, reject) => { resolve() })
  }
  callbackAfterRemoveProspectSubOrigin() {}
}
