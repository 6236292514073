import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--prospects--index"
export default class extends Controller {
  connect() {
    this.entity_type = window.location.href.includes('suspect') ? 'suspects' : 'prospects'
    $('#prospect_index').addClass('invisible')
    this.initSearch()
    this.table = $('#prospect_index').DataTable({
      'order': [],
      'scrollX': true,
      "pageLength": 100,
      "caseInsensitive": true
    })
    $('#prospect_index_wrapper .invisible').removeClass('invisible')

    this.colors = {
      'no_information': {style: 'bg-transparent' ,name: "Pas d’information (+10%)"},
      'will_sign': {style: 'bg-red', name: 'Information qui signe chez nous (+90%)'},
      'interested_by_product': {style: 'bg-orange', name: 'Très intéressé par le produit (+60%)'},
      'undecided': {style: 'bg-yellow', name: 'Incertain, en recherche active (+30%)'},
      'not_interested': {style: 'bg-lightblue', name: 'Pas intéressé dû au produit TopOrder (+2%)'},
      //'lost': {style: 'bg-lost', name: 'Perdu (0%)'}
    }

    this.checkStatuses()

    $(this.element).find('#prospect_statuses').on("change", (e) => {
      this.checkStatuses()
    })

    $(this.element).find('#prospect_statuses').on("select2:unselecting", (e) => {
      if($('#prospect_statuses').val().length == 1) {
        e.preventDefault()
      }
    })    

    

    $(document).on('click', '.prospect_square_temperature', (p) => {
      let new_temperature = $(p.currentTarget).data().temperature

      let field_temperature = this.temperature_element.parent()

      $.ajax({
        method: 'PUT',
        data: {temperature : new_temperature},
        url: '/admin/prospects/'+this.temperature_element.data().id+'/update_temperature'
      }).done(() => {
        field_temperature.removeClass('bg-red bg-orange bg-yellow bg-lightblue bg-lost bg-transparent')
        field_temperature.addClass(this.colors[new_temperature.toString()].style)
        this.temperature_element.attr('data-temperature', new_temperature);
        $('.modal').modal('hide')
      })
    })
  }

  checkStatuses() {
    $(this.element).find('.prospect_index_commercial_steps_filter').toggle(!$('#prospect_statuses').val().includes('signed'))
  }

  toggleCheckbox(e) {
    $(e.currentTarget).find('input').prop('checked', !$(e.currentTarget).find('input').prop('checked'))
  }

  toggleAll() {
    $('.data-table-rows-check input').prop('checked', true)
  }

  unToggleAll() {
    $('.data-table-rows-check input').prop('checked', false) 
  }

  applyBatchAction() {
    let selected_ids = $('#prospects_tbody :checked').map((i, e) => {return $(e).parents('tr').data().id}).get()
    if(selected_ids.length == 0) {
      return false
    }

    switch($('#prospects_batch_actions_container select').val()) {
      case 'archive':
        $.ajax({
          url: '/admin/prospects/archive_batch',
          method: 'PATCH',
          data: {prospect_ids: selected_ids}
        }).done(() => {
          this.data_filter()
          flashMessage('', "Les prospects ont été archivés")
        })
        break;
      case 'destroy':
        $.ajax({
          url: '/admin/prospects/destroy_batch',
          method: 'PATCH',
          data: {prospect_ids: selected_ids}
        }).done(() => {
          this.data_filter()
          flashMessage('', "Les prospects ont été supprimés")
        })
        break;
      case 'transfer':
        ajaxTurboStream(`/admin/prospects/new_batch_transfer?prospect_ids=${selected_ids.join('_')}`)
        break;
      case 'commercial_action':
        ajaxTurboStream(`/admin/commercial_actions/new_batch?prospect_ids=${selected_ids.join('_')}`)
        break;
      default:
    }
  }

  initSearch() {
    $( "#prospect_index_search" ).autocomplete({
      minLength: 3,
      delay: 300,
      source: ( request, response ) => {
        if(request.term.trim().length == 0) { return false; }
        let term = new URLSearchParams({term: request.term.toLowerCase(), suspect: window.location.href.includes('suspect')}).toString()
        $.get(`/admin/prospects/live_autocomplete?${term}`).done(data => {
          response(data.prospects.filter((prospect, index) => {
            return true
          }).slice(0, 15))
        })
      },
      select: function( event, ui ) {
        Turbo.visit(`/admin/prospects/${ui.item.id}/edit`)
        return false;
      }
    })
  }

  callProspect(e) {
    ajaxTurboStream(`/admin/commercial_actions/new?prospect_id=${parseInt($(e.currentTarget).parents('tr').eq(0).data().id)}&outgoing_phone_call=true`)
  }

  newCommercialAction(e) {
    ajaxTurboStream(`/admin/commercial_actions/new?prospect_id=${parseInt($(e.currentTarget).parents('tr').eq(0).data().id)}`)
  }

  editTemperature(e){
    initModal()
    $('.modal .modal-dialog .modal-body').html(this.populateTemperatures($(e.currentTarget).data().temperature))
    $('.modal-title').text("Modification de la température")
    $('#modal_rails_5').modal('show')
    $('.modal-dialog').addClass('modal-l')
    $('.modal-footer').hide()
    this.temperature_element = $(e.currentTarget)
  }

  populateTemperatures(temperature){
    let html = `<div class=" align-items" style="justify-content: space-around;" id="prospect_background">`
    $.each (this.colors, function(key, val){
      html += `
        <div class="prospect_square_temperature cursor-pointer" data-temperature="${key}">
          <div class="${val.style}"></div>
          <p>${val.name}</p>
        </div>
      `
    })
    html += `</div>`
    return html
  }

  data_filter() {
    var params = []

    if($('#prospect_origin').length && $('#prospect_origin').val() != '_' && $('#prospect_origin').val() !== null) {
      params.push(`prospect_origin_id=${$('#prospect_origin').val()}`)
    }
    if($('#prospect_status').val() != '_' && $('#prospect_status').val() !== null) {
      params.push(`temperature=${$('#prospect_status').val()}`)
    }
    if($('#prospect_commercial').val() != '_' && $('#prospect_commercial').val() !== null) {
      params.push(`toporder_employee_id=${$('#prospect_commercial').val()}`)
    }
    if($('#prospect_installation_date').data().dates.length) {
      params.push(`startDate=${$('#prospect_installation_date').data().dates.split('-')[0]}&endDate=${$('#prospect_installation_date').data().dates.split('-')[1]}`)
    }
    if($('#prospect_filter_commercial_step').val() != '_' && $('#prospect_filter_commercial_step').val() !== null) {
      params.push(`prospect_commercial_step_id=${$('#prospect_filter_commercial_step').val()}`)
    }
    if($('#prospect_filter_prospect_inbound_type').length && $('#prospect_filter_prospect_inbound_type').val() != '_') {
      params.push(`prospect_inbound_type_id=${$('#prospect_filter_prospect_inbound_type').val()}`)
    }
    if($('#prospect_business_category').length && $('#prospect_business_category').val() != '_') {
      params.push(`api_business_category_uuid=${$('#prospect_business_category').val()}`)
    }
    if($('#prospect_filter_only_without_action').prop('checked')) {
      params.push('onlyWithoutAction=true')
    }
    if($('#prospect_filter_display_archived').prop('checked')) {
      params.push('displayArchived=true')
    }

    if($('#prospect_filter_zip_code').val().length) {
      params.push(`zip_code=${$('#prospect_filter_zip_code').val()}`)
    }
    if($('#prospect_filter_kilometers').val().length) {
      params.push(`kilometers=${$('#prospect_filter_kilometers').val()}`)
    }

    if($('#prospect_filter_suspects').prop('checked')) {
      params.push("suspect=true")
    }
    params.push(`statuses=${$('#prospect_statuses').val().join('_')}`)

    let url_params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if(!url_params['sort']) {
      url_params['sort'] = 'sort_down'
      url_params['field'] = 'created_at'
    }

    params.push(`sort=${url_params['sort']}`)
    params.push(`field=${url_params['field']}`)


    ajaxTurboStream(params.length ? `/admin/${this.entity_type}?${params.join('&')}` : `/admin/${this.entity_type}`, true)
    let url = params.length ? `/admin/${this.entity_type}?${params.join('&')}` : `/admin/${this.entity_type}`
  }
}
