import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init'
// Connects to data-controller="admin--prospects--form"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    if($(this.element).data().desiredsignaturedatemandatory) {
      $('.prospect_desired_signature_date').removeClass('optional').addClass('required')
      $('#prospect_desired_signature_date').removeClass('optional').addClass('required')
    }
    $(this.element).validate({
      rules: {
        'prospect[desired_signature_date]': {
          required: $(this.element).data().desiredsignaturedatemandatory
        }
      }
    })
    
    this.element[this.identifier] = this
    setTimeout(() => {
      this.sub_reasons = $(this.element).data().origins
      this.checkSubReasons() 
      $('#prospect_prospect_origin_id').on('select2:select', () => {
        this.toggleValrhonaCommercial()
        this.checkSubReasons()
      })     
    }, 300)
    $(this.element).find('select').on('select2:select',function(){this.dispatchEvent(new Event('change',{bubbles:true}))})
    if ($('.income_fields').length){
      $('#prospect_income_tab').append($('.income_fields'));
    }else{
      if($('#prospect_edit').length) {
        $('#add_prospect_income').click()
        $('.income_installation_date').val($('#prospect_installation_date').val())
        $('#add_prospect_income').remove()
      }
    }

    this.toggleValrhonaCommercial()

    $('.datepicke').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
      var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
      $(".datepicker").css("top", top);
    });

    // API PAPPERS PROSPECT NEW
    $(document).on('click', '#search-button', (e) => {
      e.preventDefault();
      const inputValue = $(document).find('#search-input').val()
      const zipcodeValue = $(document).find('#zip-code-input').val()
      this.changeAPi(inputValue, zipcodeValue)
      this.getProspectlist(this.API)
    });
    $(document).on('click', '.prospect_item', (i) => {
      let item_id = $(i.currentTarget)[0].id.slice(-1)
      this.item_info = this.Prospectlist.resultats[item_id]
      this.printInfoProspectForm()
    });
  }
  
  updateComment(e) {
    $.ajax({
      url: `/admin/prospects/${$(this.element).data().id}/update_comment`,
      type: 'PATCH',
      data: {comment: $(e.currentTarget).val()}
    })
  }

  handleValrhonaAnswerRules() {
    $.each($('.valrhona_answer_input'), (i, e) => {
      if($(e).data().required) {
        $(e).rules('add', {required: true})
      }
    })
  }

  checkSubReasons() {
    $('#prospect_prospect_sub_origin_id option').remove()
    $('#prospect_prospect_sub_origin_id').val('')
    let selectedSub = $('#prospect_prospect_origin_id').data().selectedSub
    if(this.sub_reasons[$('#prospect_prospect_origin_id').val()]) {
      let subs = this.sub_reasons[$('#prospect_prospect_origin_id').val()]
      for(const sub in subs) {
        $('#prospect_prospect_sub_origin_id').append(`
          <option ${selectedSub && selectedSub == subs[sub].id ? 'selected' : ''} value='${subs[sub].id}'>${subs[sub].name}</option>
        `)
      }
      $('.prospect_prospect_sub_origin_id').removeClass('d-none')
      initElementSelect2($('#prospect_prospect_sub_origin_id'))
    } else {
      $('.prospect_prospect_sub_origin_id').addClass('d-none')
    }
  }

  originChanged() {
    
  }

  toggleValrhonaCommercial() {
    let is_valrhona = $(this.element).data().prospectOriginsValrhona.includes(parseInt($('#prospect_prospect_origin_id').val()))
    $('.valrhona_questions_container').toggleClass('d-none', !is_valrhona)
    $('.valrhona_answer_destroy').prop('checked', !is_valrhona)
    if(is_valrhona) {
      this.handleValrhonaAnswerRules()
      initSelect2($('.valrhona_questions_container select'))
    }
  }

  new_customer_smart_collect(e){
    if ($(e.currentTarget).prop('checked') == true){
      $('.hidden_new_customer_smart_collect').addClass('d-none')
    }else{
      $('.hidden_new_customer_smart_collect').removeClass('d-none')
    }
    this.calc()
  }

  // API PAPPERS PROSPECT NEW
  printInfoProspectForm(){
    let item = this.item_info
    $(document).find('.entity_shop_name').val(item.nom_entreprise)
    $(document).find('.entity_siren').val(item.siren)
    $(document).find('.entity_street').val(item.siege.adresse_ligne_1)
    $(document).find('.entity_zip_code').val(item.siege.code_postal)
    $(document).find('.entity_city').val(item.siege.ville)
  }

  changeAPi(input, zipcode) {
    var key = 'cbf6250360a4d8c01ea7e1a482f44116b2bf039665ff2ca0';
    this.APIbase = "https://api.pappers.fr/v2/recherche?api_token="+key
    this.API = this.APIbase+"&q="+input+"&code_postal="+zipcode
  }

  printTitle(i) {
    $(document).find(`#title${i}`).html(this.Prospectlist.resultats[i].nom_entreprise + " à " + this.Prospectlist.resultats[i].siege.ville)
  }

  getProspectlist(API) {
    let display = $(document).find("#display").html(`<p class="mb-3 mt-0">Résultat(s) :</p>`)
    fetch(API, {
        method: "GET"
    })
    .then((response) => response.json())
    .then((item) => {
      this.Prospectlist = item
      let count = this.Prospectlist.total
      if (count > 1) {
        for(let i=0;i < 10; i++){
          display.append(`<p class ="prospect_item" style ="cursor: pointer;" id="title${i}"></p>`);
          this.printTitle(i);
        };
      }else{
        display.append(`<p class ="prospect_item" style ="cursor: pointer;" id="title0">${this.Prospectlist.resultats[0].nom_entreprise + " à " + this.Prospectlist.resultats[0].siege.ville}</p>`);
      }
    })
  }

  refreshSelect() {
    $(this.element).find('select').on('select2:select',function(){this.dispatchEvent(new Event('change',{bubbles:true}))})
    this.display()
  }

  display() {
    if($('.new_commercial_action').find('.commercial_action_type_id :input').val() == 1){
      $('.new_commercial_action').find('.if_physical_rdv').removeClass('d-none')
    }else{
      $('.new_commercial_action').find('.if_physical_rdv').addClass('d-none')
    }
  }

  checkBeforeCreate() {
    if(!$('#new_prospect').valid()) {
      return false
    }
    $.ajax({
      method: 'POST',
      url: '/admin/prospects/check_existing',
      data: {
        shop_name: $('#prospect_entities_attributes_0_shop_name').val(),
        tel: $('#prospect_entities_attributes_0_tel').val(),
        mail: $('#prospect_entities_attributes_0_mail').val()
      }
    }).done(existing => {
      if(existing.length > 0) {
        let list = ''
        $.each(existing, (i, e) => {
          list += `<li>${e.shop_name}</li>`
        })        
        let content = `
          <p>Un ou plusieurs prospects similaires existent déjà.</p>
          <ul>${list}</ul>
          <p>Êtes-vous sûr de vouloir créer ce prospect ?</p>

        `
        if($('.modal #new_prospect').length) {
          $('#modal-action').attr('disabled', null) 
          $('.modal-content').addClass('closing-alert')
          $('.modalception-body').html(content)
          $('.custommodalception').slideDown(400)
          $('.modalception-footer').html(`
            <div class="btn btn-primary" onclick="cancelClosingModal()">Annuler</div>
            <button id="modal-action" type="button" class="btn btn-primary" onClick="$('#new_prospect').submit()">Créer le prospect</button>
          `)
        } else {
          initModal()
          $('.modal-header').hide()
          $('.modal-body').html(content)
          $('.modal-footer').html(`
            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Annuler</button>
            <button id="modal-action" type="button" class="btn btn-primary" onClick="$('#new_prospect').submit()">Créer le prospect</button>
          `)
          $('.modal').modal('show')          
        }

      } else {
        $('#new_prospect').submit()
      }
    })
  }
}
