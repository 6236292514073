import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--vrai-entitable-delay-configuration"
export default class extends Controller {
  connect() {
    this.toggleInputs()
    $(this.element).find('select').on("select2:select", (e) => {
      this.toggleInputs()
    })
  }

  toggleInputs() {
    $(this.element).find('.entitable_delay_type_minutes').toggle($(this.element).find('select').val() == 'minutes')
    $(this.element).find('.entitable_delay_type_days').toggle($(this.element).find('select').val() == 'days')
    if($(this.element).find('select').val() == 'days') {
      $(this.element).find('.entitable_delay_type_minutes input').val(0)
    } else {
      $(this.element).find('.entitable_delay_type_days input').val(0)
    }
  }
}
