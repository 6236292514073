import { Controller } from "@hotwired/stimulus"
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="back--pick-up-location-form"
export default class extends Controller {
  connect() {
    $(this.element).find('.pick_up_location_shopId input').on('change', (e) => {
      this.checkShopsPrestashop()
    })
    setTimeout(() => {
      this.checkShopsPrestashop()
    }, 200)
    
  }

  checkShopsPrestashop() {
    if($('.pick_up_location_externalId_container').length == 0) {
      return
    }

    if($(this.element).find('.pick_up_location_shopId input:checked').length > 1) {
      $('#pick_up_location_externalId').val('')
      $('.pick_up_location_externalId_container').hide()
      
    } else {
      $('.pick_up_location_externalId_container').show()
      initSelect2($('#pick_up_location_externalId'))
    }
  }
}
