import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--prospect-commercial-steps-index"
export default class extends Controller {
  connect() {
    $(this.element).sortable({
      axis: 'y',
      containment: $('.container-fluid'),
      cursor: 'move',
      handle: '.fa-grip-vertical',
      items: '.card',
      distance: 10,
      tolerance: 'pointer',
      deactivate: function(event, ui) {
        var array_position = []
        $.each($(ui.sender).find('.card'), function(index, element) {
          array_position.push({
            order: index,
            id: $(element).data().id
          })
        })
        $.ajax({
          url: "/admin/prospect_commercial_steps/update_positions",
          type: "POST",
          data: {positions: array_position},
        })

      }
    })
  }
}
