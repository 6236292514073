import { Controller } from "@hotwired/stimulus"
import { loadDatepicker } from 'shared/load_datepicker';
import { initSelect2 } from 'shared/select2_init';
// Connects to data-controller="back--smart-collect-shoppable-sellable-exceptional-availabilities-fields"
export default class extends Controller {
  connect() {
    this.activeChanged()
  }

  activeChanged() {
    $(this.element).find('.smart_collect_product_exceptional_availability_container_main').toggle(
      $(this.element).find('.smart_collect_entity_exceptional_availability_attrs_active').prop('checked')
    )
    loadDatepicker($(this.element).find('.turbo_datepicker:visible'))
    initSelect2($(this.element).find('select:visible'))
  }
}
