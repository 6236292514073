import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--pennylane"
export default class extends Controller {
  static targets = ['supplier', 'name', 'apikey']
  connect() {
    this.element['controller'] = this
  }

  submitForm() {
    if(!$(this.element).valid()) {
      return false
    }
    $.ajax({
      method: 'POST',
      url: '/admin/pennylane/test_api_key',
      data: {apikey: $(this.apikeyTarget).val()},
      success: () => {
        $(this.element).find('input[type=submit]').click()
      },
      error: () => {
        $('.error_config_api_module').removeClass('d-none')
        $('#modal-action').prop('disabled', false)
      }
    })
  }
}
