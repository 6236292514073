import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--stock--recipe--recipe-ingredient-field"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    this.formatter = new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR', maximumFractionDigits: 3, minimumFractionDigits: 2})
  }

  get recipeSum() {
    return $('#modal .modal').get(0).controller.recipeSum
  }

  setPrice(price) {
    this.price = price
  }

  displayPrices() {
    let quantity = $(this.element).find('.recipe_recipe_ingredients_quantity input').valF()
    let price_total = this.price * quantity
    $(this.element).find('.recipe-ingredient-fields__price').text(this.formatter.format(this.price))
    $(this.element).find('.recipe-ingredient-fields__total').text(this.formatter.format(price_total))
    if(this.recipeSum && this.recipeSum > 0) {
      $(this.element).find('.recipe-ingredient-fields__percentage').text(((price_total / this.recipeSum) * 100).toFixed(2))
    }
  }

  changeIngredientAmount(e) {
    if ($(e.currentTarget).val().length == 0 || $(e.currentTarget).valF() < 0 || $(e.currentTarget).valF() == 0) {$(e.currentTarget).val(1)}
    $('#modal .modal').get(0).controller.calcCost()
  }
}
