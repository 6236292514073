import { Controller } from "@hotwired/stimulus"
import { loadDatepicker } from 'shared/load_datepicker';
// Connects to data-controller="admin--shop-feature-devices-form"
export default class extends Controller {
  connect() {
    if($('#shop_feature_device_expiration_date').length) {
      setTimeout(() => {
        loadDatepicker($('#shop_feature_device_expiration_date'))
      }, 200) 
    }
  }

  removeExpirationDate() {
    $('#shop_feature_device_expiration_date').val('')
  }
}
