import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--income-task"
export default class extends Controller {
  task_updated(e) {
    $.ajax({
      method: 'PATCH',
      url: '/admin/income/update_taks',
      data: {id: $(e.currentTarget).data().id, checked: $(e.currentTarget).prop('checked')}
    })
    this.update_new_customer_completion()
  }

  update_new_customer_completion() {
    let percentage = ((parseFloat($('.modal-body input:checked').length) / parseFloat($('.modal-body input').length)) * 100).toFixed(0)
    let color_class;

    let span = $(`#new_customer_task_completion_${$(this.element).data().newCustomer}`)
    span.text(percentage + '%')
    if(percentage < 33) {
      color_class = 'text-danger'
    } else if (percentage > 32 && percentage < 75) {
      color_class = 'text-warning'
    } else if (percentage < 100) {
      color_class = 'text-info'
    } else {
      color_class = 'text-success'
    }
    span.removeClass('text-success text-info text-info text-warning text-danger')
    span.addClass(color_class)
  }
}
