import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--dashboard-important-message"
export default class extends Controller {
  connect() {
    if(!localStorage.getItem(`admin_message_${$(this.element).data().key}`)) {
      $(this.element).show()
    }
  }

  hideMessage() {
    localStorage.setItem(`admin_message_${$(this.element).data().key}`, 'hidden')
    $(this.element).hide()
  }
}
