import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--vpn-user-form"
export default class extends Controller {
  connect() {
    $("#new_vpn_user").validate({
      rules: {
        'vpn_user[cn]': {
          required: true,
          regex: /^[A-Za-z0-9]*$/
        }
      },
      messages: {
        'vpn_user[cn]': {
          regex: "Veuillez entrer uniquement des chiffres ou des lettres"
        }
      }
    }).resetForm()
  }
}
