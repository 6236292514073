import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--new-customers-preparation-follow"
export default class extends Controller {
  static targets = ['dateRange']
  connect() {
    let datas = $(this.element).data()
    let ranges = {
      "La semaine en cours": [moment(datas.current.split('-')[0], 'DD/MM/YYYY'), moment(datas.current.split('-')[1], 'DD/MM/YYYY')],
      "La semaine précédente": [moment(datas.prev.split('-')[0], 'DD/MM/YYYY'), moment(datas.prev.split('-')[1], 'DD/MM/YYYY')],
      "La semaine suivante": [moment(datas.next.split('-')[0], 'DD/MM/YYYY'), moment(datas.next.split('-')[1], 'DD/MM/YYYY')],
      "L'année en cours": [moment(datas.year.split('-')[0], 'DD/MM/YYYY'), moment(datas.year.split('-')[1], 'DD/MM/YYYY')],
      "De tout temps": [moment(datas.all.split('-')[0], 'DD/MM/YYYY'), moment(datas.all.split('-')[1], 'DD/MM/YYYY')],
    }
    $(this.dateRangeTarget).daterangepicker({
      parentEl: ".filter_card",
      ranges: ranges,
      startDate: $(this.dateRangeTarget).data().startDate.split('/').join('-'),
      endDate: $(this.dateRangeTarget).data().endDate.split('/').join('-')
    }, (start, end, picker) => {
      if(picker == "Personnalisé") {
        $(this.dateRangeTarget).find('span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      } else {
        $(this.dateRangeTarget).find('span').text(picker)
      }
      ajaxTurboStream(`/admin/new_customers/preparation_follow?startDate=${start.format('DD-MM-YYYY')}&endDate=${end.format('DD-MM-YYYY')}`)
    })
  }
}
