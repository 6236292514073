import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init'; 
// Connects to data-controller="back--statistics--v2--statistics-separate-shop-select"
export default class extends Controller {
  connect() {
    this.setDataTable()
    if($(this.element).find('select').length) {
      initSelect2($(this.element).find('select'))
      $(this.element).find('select').on('select2:select', (e) => {
        $('.statistics-v2-separate-shops-tab').removeClass('active')
        $(`#statistics-v2-separate-shops__${$(e.currentTarget).val()}`).addClass('active')
        this.setDataTable()
      })      
    }
  }

  selectTab(e) {
    if(!$(e.currentTarget).hasClass('active')) {
      $(this.element).find('li.active').removeClass('active')
      $('.statistics-v2-separate-shops-tab').removeClass('active')
      $(e.currentTarget).addClass('active')
      $(`#statistics-v2-separate-shops__${$(e.currentTarget).data().target}`).addClass('active')
      this.setDataTable()
    }
  }

  setDataTable() {
    if($("#statistics_v2_products_tables .dataTables_scrollHead:visible").length == 0) {
      $("#statistics_v2_products_tables table:visible").DataTable({
        "pageLength": 100000,
        'order': [],
        'scrollX': true,
        "caseInsensitive": true
      })      
    }
  }
}
