import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--external-product-variations"
export default class extends Controller {
  connect() {
    this.variations = {}
    this.externalName = $(this.element).data().externalName
    this.init_search()
    this.product_id = ''
  }

  init_variation(e) {
    this.container = $(e.currentTarget).parent()
    this.init_select()
    if($('#product_externalId').val().length == 0) {
      initModal()
      $('#modal_rails_5').modal('show')
      $('.modal-title').text(`Choix de la déclinaison ${this.externalName}`)
      $('.modal-footer').html('')
      $('.modal-footer').hide()      
      $('.modal-body').html("<p>Vous devez d'abord lier votre produit avant de lier ses déclinaisons.</p>")
    } else if(Object.keys(this.variations).length == 0 || this.product_id != $('#product_externalId').val()) {
      $('#loading_app').addClass('d-flex')
      $.get(`/back/api_modules/fetch_external_variations?external_product_id=${$('#product_externalId').val()}`, (data) => {
        this.variations = data.data
        $('#loading_app').removeClass('d-flex')
        this.modal_products()
        this.product_id = $('#product_externalId').val()
      })
    } else {
      this.modal_products()
    }
  }

  init_search() {
    $(document).on('keyup', '#search_woocommerce_product', () => {
      let value = latinize($('#search_woocommerce_product').val().toLowerCase())
      if(value.length > 0) {
        $('.woocommerce_product_modal p').filter(function() {
          $(this).toggle(latinize($(this).data().name.toLowerCase()).indexOf(value) > -1)
        });
      } else {
        $('.woocommerce_product_modal p').toggle(true)
      }
    })
  }

  init_select() {
    $(document).on('click', `#woocommerce_variation_modal_${$(this.element).find('input').get(0).id} p`, (element) => {
      $.each($(this.element).parents('.declination_fields').get(0).controller.other_ppus(true, true, true), (i, other_pv) => {
        $(other_pv).find('.product_variation_external_id_input').val($(element.currentTarget).data().id)
        $(other_pv).find('.externalId_display').text($(element.currentTarget).data().id)
        $(other_pv).find('.externalId_container').addClass('has_externalId')
      })
      $(this.element).find('input').val($(element.currentTarget).data().id)
      $(this.element).find('.externalId_display').text($(element.currentTarget).data().id)
      $(this.element).find('.externalId_container').addClass('has_externalId')
      $('.modal').modal('hide')
    })
  }

  toggle_btn(loading) {
    $(this.element).find('.btn').toggleClass('btn-loading', loading)
    $(this.element).find('.btn').prop('disabled', loading)
    $(this.element).find('.position-absolute').toggle(loading)
  }

  modal_products() {
    initModal()
    $('#modal_rails_5').modal('show')
    $('.modal-title').text(`Choix de la déclinaison ${this.externalName}`)
    $('.modal-footer').html('')
    $('.modal-footer').hide()
    if(Object.keys(this.variations).length == 0) {
      $('.modal-body').html("<p>Nous n'avons pas pu trouver de déclinaison sur votre produit.</p>")
    } else {
      $('.modal-body').html(`
        <div class="woocommerce_product_modal" id="woocommerce_variation_modal_${$(this.element).find('input').get(0).id}">
          <div class="search-sm d-block mb-2 align-top">
            <input placeholder="Rechercher une déclinaison" id="search_woocommerce_product" class='w-100' data-action="keyup->woocommerceProducts#search">
          </div>
          ${this.woocommerce_products()}
        </div>
      `)
      $('.woocommerce_product_modal').css('height', $(window).height() - 229)
    }

  }

  externalIdChanged(e) {
    this.externalId = $(e.currentTarget).val()
    if(this.externalId && this.externalId.length > 0) {
      console.log($(this.element))
      console.log($(this.element).find('.externalId_display'))
      $(this.element).find('.externalId_display').text($(e.currentTarget).val())
      $(this.element).find('.externalId_container').addClass('has_externalId')
      $.each($(this.element).parents('.declination_fields').get(0).controller.other_ppus(true, true, true), (i, other_pv) => {
        $(other_pv).find('.product_variation_external_id_input').val($(e.currentTarget).val())
        $(other_pv).find('.externalId_display').text($(e.currentTarget).val())
        $(other_pv).find('.externalId_container').addClass('has_externalId')
      })      

    } else {
      this.removeId()
    }
  }

  removeId() {
    $(this.element).find('input').val('')
    $(this.element).find('.externalId_display').text('')
    $(this.element).find('.externalId_container').removeClass('has_externalId')
    $.each($(this.element).parents('.declination_fields').get(0).controller.other_ppus(true, true, true), (i, other_pv) => {
      $(other_pv).find('.product_variation_external_id_input').val('')
      $(other_pv).find('.externalId_display').text('')
      $(other_pv).find('.externalId_container').removeClass('has_externalId')
    })
  }

  woocommerce_products() {
    let products = ""
    $.each(this.variations, (key, value) => {
      products += `
        <p class='py-2 mb-0 clickable' data-name='${latinize(key.toLowerCase())}' data-id="${value}">${key}</>
      `
    })
    return products
  }
}
