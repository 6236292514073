import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init';

// Connects to data-controller="back--shop-hour-classic-fields"
export default class extends Controller {
  connect() {
    this.toggleOpened()
    this.toggleClosing()
  }

  toggleOpened() {
    $(this.element).find('.shop_hour_closing_wrapper').toggle($(this.element).find('.shop_hour_opened_input').prop('checked'))
    $(this.element).find('.shop_hour_hours').toggleClass('active', $(this.element).find('.shop_hour_opened_input').prop('checked'))
    $(this.element).find('.toggle_closing_hours').toggle($(this.element).find('.shop_hour_opened_input').prop('checked'))
    initElementSelect2($(this.element).find('select:visible'))
  }

  toggleClosing() {
    $(this.element).find('.closing_hours_container').toggleClass('active', $(this.element).find('.shop_hour_closing_input').prop('checked'))
    initElementSelect2($(this.element).find('select:visible'))
  }
}
