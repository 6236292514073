import { Controller } from "@hotwired/stimulus"
import { initTurboModal } from 'shared/modal'
// Connects to data-controller="back--listing--listing-sort"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    this.views = {
      orders: [
        {key: 'continousSequence', text: "Par numéro de commande"},
        {key: 'clientLastName', text: 'Par nom du client'},
        {key: 'delivery_at', text: "Par date et<br>heure de livraison"},
        {key: 'is_paid', text: "Non payé / Payé"}
      ],
      sticker: [
        {key: 'continousSequence', text: "Par numéro de commande"},
        {key: 'clientLastName', text: 'Par nom du client'},
        {key: 'name', text: 'Par nom du produit'}
      ]
    }
    this.sortkeys = {
      continousSequence: "Par numéro de commande",
      clientLastName: 'Par nom du client',
      delivery_at: "Par date et<br>heure de livraison",
      is_paid: "Non payé / Payé",
      name: 'Par nom du produit'
    }
  }

  configure(e) {
    initTurboModal()
    $('#modal .modal-dialog .modal-body').html(`
      <div data-controller="back--listing--configure-sort" id="listing-configure-sort" data-view='${$(e.currentTarget).parent().data().view}'></div>
    `)
    $('#modal .modal-dialog').addClass('modal-lg')
    $('#modal .modal-footer').hide()
  }

  select(e) {
    if(!$(e.currentTarget).hasClass('selected_sort')) {
      $(e.currentTarget).parent().find('.sort_item').removeClass('selected_sort')
      $(e.currentTarget).addClass('selected_sort')
      $(e.currentTarget).parent().data('sort', $(e.currentTarget).data().sort)
      $('#listing').get(0).controller.updateSort($(e.currentTarget).parent().data().view, $(e.currentTarget).data().sort)
    }
  }

  setSort(params) {
    $.each(params, (key, sort) => {
      let html = []
      for(const key in sort) {
        html.push(`<div class='listing-sort-configure__item' data-key=${sort[key]}>${this.sortkeys[sort[key]]}</div>`)
      }
      html = html.join('<i class="fas fa-chevron-right"></i>')
      $(`.listing_sort_window .sort_orders .listing-sort__configuration-display`).html(html)
    })
  }
}
