import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--dashboard-calendar"
export default class extends Controller {
  connect() {
    this.loadCalendar()
    $(document).on("select2:select", '#dashboard-calendar__select', (e) => {
      this.loadCalendar()
    })    
  }

  loadCalendar() {
    let url = 'back/calendar/number_orders'

    if($('#dashboard-calendar__select').val() != '_') {
      url += `?shopId=${$('#dashboard-calendar__select').val()}`
    }

    $.ajax({
      url: url,
      success: function(datas){
        $('.dashboard-calendar').html('')
        window.calendar = new FullCalendar.Calendar($('.dashboard-calendar'), {
          themeSystem: 'bootstrap4',
          locale: 'fr',
          events: datas,
          header: {
            left: 'title',
            right: 'prev,next'
          },
          bootstrapFontAwesome: {
            prev: "angle-left",
            next: "angle-right",
            prevYear: "angle-left",
            nextYear: "angle-right"
          },
          eventRender: function(event, element) {
            if($(element).find('.fc-title').text().length) $(element).find('.fc-title').append('<i class="far fa-box-check ml-1"></i> ')
          }

        })
        window.calendar.render();
        $(calendar).find('.fc-day-grid-container').css('height', 'auto');        
      }
    })
  }
}
