import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--shoppable-important-message-fields"
export default class extends Controller {
  selectIcon(e) {
    if(!$(e.currentTarget).hasClass('active')) {
      $('.important_message_icon.active').removeClass('active')
      $(e.currentTarget).addClass('active')
      $('.important_message_icon_input').val($(e.currentTarget).data().icon)
    }
  }
}
