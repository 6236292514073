import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  connect() {
    this.modal = $(this.element)
    this.modal.modal({backdrop: 'static', keyboard: false})
    this.element['controller'] = this
    $('#loading_app').removeClass('d-flex')
  }

  submitForm() {
    if($(this.element).find('form').data().delegatesubmit) {
      $(this.element).find('form').get(0).controller.submitForm()
      return false
    }
    if($(this.element).find('form').valid()) {
      if($(this.element).find('form').data().model) {
        this.updatingModal(i18n.t(`updating_modal.${$(this.element).find('form').data().model}`))
      }
      $(this.element).find('input[type=submit]').click()
      if($(this.element).find('form').data().model) {
        $('#loading_app').removeClass('d-flex')
      }
    }
  }

  updatingModal(text) {
    $('.modalception-body').html(`
      <div class="loading_modal">
        ${text}
        <div class="loading_circle"></div>
      </div>
    `)
    $('.modal-body').hide()
    $('.modalception-footer').hide()
    $('.modalception-footer').removeClass('d-flex')
    $('.custommodalception').slideDown(400)

    $('.modal-header').hide()
    $('.modal-footer').hide()
  }

  disconnect() {
    $(this.element).unbind('hide.bs.modal')
    $(this.element).modal('hide')
  }
}
