import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--shop-groups-new"
export default class extends Controller {
  connect() {
    $(this.element).on('click', '.distribution_switches label', (e) => {
      if($('.distribution_switches input:checked').length == 1 && $(e.currentTarget).parent().find('input').prop('checked')) {
        e.preventDefault()
      }
    })
  }

  submit() {
    $('#loading_app').addClass('d-flex')
    $(this.element).find('.btn-primary').hide()
    setTimeout(() => {
      if(!$(this.element).valid()) {
        $('#loading_app').removeClass('d-flex')
        $(this.element).find('.btn-primary').show()
      }
    }, 500)
  }
}
