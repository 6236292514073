import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="utils--turbo-pagination"
export default class extends Controller {
  connect() {
    $(this.element).on('click', 'a', (e) => {
      e.preventDefault()
      this.urlParams = new URLSearchParams(window.location.search);
      this.url = $(this.element).data().customUrl ? $(this.element).data().customUrl : window.location.pathname
      let page = new URLSearchParams($(e.currentTarget).get(0).href.split('?')[1]).get('page')
      let update_url = $(this.element).data().updateUrl === undefined ? true : $(this.element).data().updateUrl
      this.urlParams.set('page', page)
      if(!$(this.element).data().customUrl) {
        if(this.urlParams.toString().length > 0) {this.url += `?${this.urlParams.toString()}`}
      } else {
        if(this.url.includes('?')) {
          this.url += `&page=${page}`
        } else {
          this.url += `?page=${page}`
        }
      }
      ajaxTurboStream(this.url, update_url)
    })
  }
}
