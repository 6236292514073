import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--smart-collect-menu-fields"
export default class extends Controller {
  connect() {
  }

  description_short_changed(e) {
    $('.smart_collect_product_description_short_count').text($('#menu_smart_collect_menu_description_short').val().length)
  }
  description_changed(e) {
    $('.smart_collect_product_description_count').text($('#menu_smart_collect_menu_description').val().length)
  }
}
