import Utils__NestedFormController from "controllers/utils/nested_form_controller"
import { classAgregator } from 'shared/class_agregator'
import { Controller } from "@hotwired/stimulus"
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="admin--connector-form"
export default class extends classAgregator(Controller, Utils__NestedFormController) {
  static targets = ['templateConnectorRealTime', 'contentConnectorRealTime']
  connect() {
    this.realTimeDataTypeNames = {
      '0': 'Lignes de vente',
      '1': 'Clients particuliers',
      '2': 'Clients professionnels',
      '3': 'Produits',
      '4': 'Tickets de paiement',
      '5': 'Factures'
    }

    let html = ""
    if($(this.element).data().requiresrealtime) {
      this.checkRealTime()
    }
    for(const id in this.realTimeDataTypeNames) {
      html += `<option value='${id}'>${this.realTimeDataTypeNames[id]}</option>`
    }
    $('#connector_real_time_select').html(html)

    this.loadConnectorRealTime()
    $(this.element).validate({
      rules: {
        'connector[description]': {
          required: true
        }
      }
    })
    if($('#connector_apiKey').length && $('#connector_apiKey').data().required) {
      $('#connector_apiKey').rules('add', {required: true})
      $('#connector_apiKey').removeClass('optional')
    }
    if($('#connector_apiSecret').length && $('#connector_apiSecret').data().required) {
      $('#connector_apiSecret').rules('add', {required: true})
      $('#connector_apiSecret').removeClass('optional')
    }
    $.each($('.connector_property_value'), (i, e) => {
      $(e).removeClass('optional')
      $(e).rules('add', {required: true})
      if($(e).data().regexUrl) {
        $(e).rules('add', {regex_url: true})
      }
      if($(e).data().digits) {
        $(e).rules('add', {digits: true})
      }
      if($(e).data().email) {
        $(e).rules('add', {email: true})
      }
    })
  }

  get realTimeSelect() {
    return $('#connector_real_time_select')
  }

  checkRealTime() {
    $('#modal .modal-footer .btn-primary').prop('disabled', $('.connector_real_time_fields').length == 0)
  }

  addConnectorRealTime() {
    this.addAssociation('ConnectorRealTime', {id: this.realTimeSelect.val()})
    this.realTimeSelect.find(':selected').prop('disabled', true)
    this.checkRealTime()
    this.toggleSelect()
  }

  loadConnectorRealTime() {
    $.each($('.connector_real_time_fields'), (i, element) => {
      let id = $(element).find('.connector_connector_real_times_realTimeDataType input').val()
      this.realTimeSelect.find(`option[value="${id}"]`).prop('disabled',true)
      this.decorateConnectorRealTime($(element))
    })
    this.toggleSelect()    
  }

  decorateConnectorRealTime(element) {
    element.find('.connector_real_time_data_type_text').text(
      this.realTimeDataTypeNames[element.find('.connector_connector_real_times_realTimeDataType input').val()]
    )
  }

  toggleSelect() {
    this.realTimeSelect.val(this.realTimeSelect.find('option').not(':disabled').eq(0).val())
    this.realTimeSelect.parent().parent().toggle(this.realTimeSelect.find('option').not(':disabled').length > 0)
    if(this.realTimeSelect.hasClass('select-hidden-accessible')) {
      updateSelect2(this.realTimeSelect)
    } else if(this.realTimeSelect.is(':visible')) {
      initSelect2(this.realTimeSelect)
    }
    this.realTimeSelect.trigger('change')    
  }

  callbackAfterAddConnectorRealTime(element, options) {
    element.find('.connector_connector_real_times_realTimeDataType input').val(options.id)
    element.find('.connector_real_time_data_type_text').text(this.realTimeDataTypeNames[options.id])
  }
  callbackBeforeRemoveConnectorRealTime(element) {
    return new Promise((resolve, reject) => {
      let real_time_type_id = element.find('.connector_connector_real_times_realTimeDataType input').val()
      this.realTimeSelect.find(`option[value="${real_time_type_id}"]`).prop('disabled', false)
      this.toggleSelect()
      resolve()
    })
  }
  callbackAfterRemoveConnectorRealTime() {
    this.checkRealTime()
  }
}
