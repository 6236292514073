import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--commercial-action-edit"
export default class extends Controller {
  connect() {
    this.field = $(this.element).data().field
    this.id = $(this.element).data().id
  }
  submit() {
    let data = {field: this.field}
    let body = {}
    if(this.field == 'date') {
      body.date = $(this.element).find('input').val()
      body.hour = $('#edit_commercial_action_hour').val()
      body.minutes = $('#edit_commercial_action_minutes').val()
    } else if(this.field == 'comment') {
      body.comment = $(this.element).find('textarea').val().trim()
    } else {
      body.duration = $(this.element).find('input').val()
    }
    $.ajax({
      url: `/admin/commercial_actions/${this.id}/update_field`,
      method: 'PUT',
      data: body
    }).done(() => {
      if(body.date) {
        $(`#commercial_action_card_${this.id} .commercial_action_card_date`).html(body.date)
        if(body.minutes == '0') {
          body.minutes = '00'
        }
        $('.commercial_action_card_hour_minutes').text(`${body.hour}h${body.minutes}`)
        $('.commercial_action_card_hour_minutes').toggleClass('d-none', (body.hour == '0') && (body.minutes == '00'))

      } else if(body.comment) {
        let html
        if(body.comment.length) {
          html = `<div style='white-space: pre-wrap;' class='text-13'>${body.comment}</div>`
        } else {
          html = `<p class="text-13 mb-0 text-center commercial_action_card_empty_comment" style='font-style: italic'>Ajouter un commentaire</p>`
        }
        $(`#commercial_action_card_${this.id} .commercial_action_card_${this.field}`).html(html)
      } else {
        $(`#commercial_action_card_${this.id} .commercial_action_card_duration`).html(body.duration)
      }
      
      $('.modal').modal('hide')
    })
  }
}
