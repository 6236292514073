import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="franchisor--franchise-owner-activation"
export default class extends Controller {
  connect() {
    console.log('bouh bouh')
    let existing_email = $('#franchise_shop_group_creator_email').val()
    $(this.element).validate({
      onkeyup: false,
      rules: {
        'franchise_shop_group_creator[email]': {
          required: true,
          remote: {
            param: {url: '/franchisor/franchise_owners/check_email', type: 'post', data: {existing_email: existing_email}}
          }
        },
        'franchise_shop_group_creator[password]': {
          required: true,
          minlength: 6
        },
        'franchise_shop_group_creator[password_confirmation]': {
          required: true,
          equalTo: '#franchise_shop_group_creator_password'
        },    
      },
      messages: {
        'franchise_shop_group_creator[email]': {
          remote: "Cet email est déjà utilisé"
        },
        'franchise_shop_group_creator[password_confirmation]': {
          equalTo: 'Veuillez saisir le même mot de passe'
        },
        'franchise_shop_group_creator[shop_password_confirmation]': {
          equalTo: 'Veuillez saisir le même mot de passe'
        }
      }
    })
  }
}
