import Back__Statistics__V2__StatisticsAbstractFilterController from "./statistics_abstract_filter_controller"
import { ExportXlsx } from '../../../../shared/export_xlsx';
// Connects to data-controller="back--statistics--v2--statistics-top-flop"
export default class extends Back__Statistics__V2__StatisticsAbstractFilterController {
  get autoRefresh() {
    return true
  }

  refresh(params) {
    $('#loading_app').addClass('d-flex')
    let url = `/back/statistics/v2/top_flop_products/load_stats?${new URLSearchParams(params).toString()}`
    $.ajax({url: url, dataType: 'script'}).done(() => {
      $('#loading_app').removeClass('d-flex')  
    })
  }
    
  exportXlsx() {
    new ExportXlsx('stats_top_flop_container_table', {multiple: true}, 'export-statistics-top-flop').render()
  }
}
