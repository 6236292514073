import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--manual"
export default class extends Controller {
  connect() {
    $(this.element).sortable({
      items: 'p',
      stop: function(event, ui) {
        $.ajax({
          type: 'POST',
          url: '/admin/manuals/change_order/',
          data: {ids: $('.change_order_manual p').map(function() {return $(this).data().id}).get()}
        })
      }
    });
  }
}
