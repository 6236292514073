import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init';
// Connects to data-controller="catalog--menus--menu-dish-group"
export default class extends Controller {
  connect() {
    this.dishes = $('.menu_form').data().existingDishes
    if($(this.element).data().existing) {
      this.existing = $(`#${$(this.element).data().existing}`)
      this.existingDishs = this.existing.find('.dish_group_dish_unique_id_input').val().split(' ')
      this.existingMinSelection = this.existing.find('.dish_group_min_selection_input').val()
      this.existingMaxSelection = this.existing.find('.dish_group_max_selection_input').val()
    } else {
      this.existingDishs = []
      this.existingMinSelection = 0
      this.existingMaxSelection = 0
    }

    this.renderView()  
    if($(this.element).find('select option').length < 2) {
      $(this.element).html(`
        <p>Il n'y a pas assez de plats disponible pour créer un nouveau groupe.</p>
      `)
    }  
  }

  renderView() {
    $(this.element).html(`
      <div id="modal-menu-dish-group__first-step">
        <p class='text-13'>Choissisez les plats que vous souhaitez inclure dans ce groupe.</p>
        <p id="modal-menu-dish-group__first-step__error" class="text-danger d-none"></p>
        <select class='optional w-100' multiple>
          ${this.populateDishes()}
        </select>
        <hr>
        <div class='d-flex justify-content-end'>
          <p class='mb-0 btn btn-primary' data-action="click->catalog--menus--menu-dish-group#nextStep">Suivant</p>
        </div>
      </div>
      <div id="modal-menu-dish-group__second-step" style='display: none;'>
        <p id="modal-menu-dish-group__second-step__error" class="text-danger d-none"></p>
        <div class="form-group">
          <label class='text-13 font-semibold' for="modal-menu-dish-group__min-selection">Nombre de plat minimum</label>
          <input type="number" value="${this.existingMinSelection}" class="form-control float" id="modal-menu-dish-group__min-selection">
        </div>
        <div class="form-group">
          <label class='text-13 font-semibold' for="modal-menu-dish-group__max-selection">Nombre de plat maximum</label>
          <input type="number" value="${this.existingMaxSelection}" class="form-control float" id="modal-menu-dish-group__max-selection">
        </div>
        <div class='d-flex justify-content-between'>
          <p class='mb-0 btn btn-outline-primary' data-action="click->catalog--menus--menu-dish-group#firstStep">Précédent</p>
          <p class='mb-0 btn btn-primary' data-action="click->catalog--menus--menu-dish-group#submit">Envoyer</p>
        </div>
      </div>
    `)

    initSelect2($(this.element).find('select'))
  }

  firstStep() {
    $('#modal-menu-dish-group__second-step').hide()
    $('#modal-menu-dish-group__first-step').show()
  }

  submit() {
    this.minSelection = parseInt($('#modal-menu-dish-group__min-selection').val())
    this.maxSelection = parseInt($('#modal-menu-dish-group__max-selection').val())
    this.dishIds = $(this.element).find('select').val()
    if(isNaN(this.minSelection) || isNaN(this.maxSelection)) {
      $('#modal-menu-dish-group__second-step__error').text("Vous devez renseigner une valeur pour les deux champs.")
      $('#modal-menu-dish-group__second-step__error').removeClass('d-none')
    } else if(this.minSelection > this.dishIds.length || this.maxSelection > this.dishIds.length){
      $('#modal-menu-dish-group__second-step__error').text("Le minimum et le maximum ne peuvent pas être supérieurs au nombre de plats sélectionnés.")
      $('#modal-menu-dish-group__second-step__error').removeClass('d-none')
    } else if(this.minSelection > this.maxSelection) {
      $('#modal-menu-dish-group__second-step__error').text("Le nombre de plats minimum ne peut être supérieur au nombre de plats maximum.")
      $('#modal-menu-dish-group__second-step__error').removeClass('d-none')
    } else {
      $('#modal-menu-dish-group__second-step__error').addClass('d-none')
      console.log('good to go')
      this.handleDishGroup()
    }
  }

  handleDishGroup() {
    let dish_group
    if(this.existing) {
      dish_group = this.existing
    } else {
      $('.edit_menu_add_dish_group_link').click()
      dish_group = $('.dish_group_field').eq(-1)
    }
    
    
    dish_group.attr('id', `dish_group_field_${new Date().getTime()}`)
    dish_group.find('.dish_group_dish_unique_id_input').val(this.dishIds.join(' '))
    dish_group.find('.dish_group_min_selection_input').val(this.minSelection)
    dish_group.find('.dish_group_max_selection_input').val(this.maxSelection)
    dish_group.find('.dish_group_min_selection').text(this.minSelection)
    dish_group.find('.dish_group_max_selection').text(this.maxSelection)
    let dish_names = []
    $.each(this.dishIds, (i, id) => {dish_names.push($('.menu_form').data().existingDishes[id])})
    dish_group.find('.dish_group_dish_list').text(dish_names.join(', '))
    $('.modal').modal('hide')
  }

  nextStep() {
    if($(this.element).find('select').val().length < 2) {
      $('#modal-menu-dish-group__first-step__error').text("Vous devez sélectionner au moins deux plats.")
      $('#modal-menu-dish-group__first-step__error').removeClass('d-none')
    } else {
      $('#modal-menu-dish-group__first-step__error').addClass('d-none')
      $('#modal-menu-dish-group__first-step').hide()
      $('#modal-menu-dish-group__second-step').show()
    }
  }

  populateDishes() {
    this.usedDishs = []
    $.each($('.dish_group_field'), (i, field) => {
      if(!this.existing || $(field).get(0).id != $(this.element).data().existing) {
        this.usedDishs = this.usedDishs.concat($(field).find('.dish_group_dish_unique_id_input').val().split(' '))
      }
    })
    let html = []
    $.each(this.dishes, (id, name) => {
      if(!this.usedDishs.includes(id)) {
        html.push(`<option value="${id}" ${this.existingDishs.includes(id) ? 'selected' : ''}>${name}</option>`)  
      }
      
    })
    return html.join('')
  }
}
