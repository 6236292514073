import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--toporder-connector--refresh-stat"
export default class extends Controller {
  toggled() {
    if(!$('#toporder_connector_toggle_button').prop('checked')) {
      $('#toporder_connector_body').val('')
    }
    $('.toporder_connector_body').toggleClass('d-none', !$('#toporder_connector_toggle_button').prop('checked'))
  }
}
