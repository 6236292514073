import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catalog--product-categories--products"
export default class extends Controller {
  connect() {
    this.product_category_container = $(this.element).parents('.sub-card-container').eq(0)
    this.product_category_container.find('> .card').addClass("opened")
    this.product_category_container.find("> .sub-card").show(200)
    let product_category_id = this.product_category_container.data().id
    $(this.element).sortable({
      axis: 'y',
      containment: $(this.element),
      cursor: 'move',
      handle: '.grab_product_order',
      items: '.card',
      distance: 10,
      deactivate: function(event, ui) {
        var array_position = []
        $.each($(ui.sender).find('.card'), (index, element) => {
          array_position.push({
            order: index,
            productId: $(element).data().productId,
            productCategoryId: product_category_id,
          })
        })
        $.ajax({
          url: `/${$('body').data().context}/products/update_positions`,
          type: "POST",
          data: {positions: array_position},
          success: function() {
          }
        })

      }
    })
  }
}
