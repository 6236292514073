import { Controller } from "@hotwired/stimulus"
import { put, destroy } from "@rails/request.js"
import { flashMessage } from 'shared/notify';

// Connects to data-controller="admin--web-interaction-row"
export default class extends Controller {
  connect() {
    this.id = $(this.element).data().id
  }
  editValue(e) {
    let cell = $(e.currentTarget)
    cell.hide()
    cell.after(`
      <label class="has-float-label input-group value_row_quantity_container">
        <input class="form-control numeric float optional" type="number" step="any" value="">
        <div class="input-group-append"  data-action="click->admin--web-interaction-row#cancelEdit">
          <span class="input-group-text clickable"><i class="fas fa-times"></i></span>
        </div>
        <div class="input-group-append clickable" data-action="click->admin--web-interaction-row#validateValue">
          <span class="input-group-text"><i class="fas fa-check"></i></span>
        </div>
      </label>
    `)
    cell.next().find('input').focus()
  }

  cancelEdit(e) {
    $(e.currentTarget).parents('td').find('span').show()
    $(e.currentTarget).parents('td').find('label').remove()
  }

  validateValue(e) {
    let value = parseFloat($(e.currentTarget).parent().find('input').val().replace(',','.'))
    let previous_value= parseFloat($(e.currentTarget).parents('td').find('span').text().trim().replace(',','.'))
    let field = $(e.currentTarget).parents('td').find('> span').data().field
    if(!isNaN(value) && previous_value != value) {
      $(e.currentTarget).parents('td').find('span').eq(0).text(value)
      put(`/admin/web_interactions/${this.id}/update_field`, {body: {field: field, value: value}})
      flashMessage('', `La performance web a bien été modifiée`, 'success')
    }
    this.cancelEdit(e)
  }
}
