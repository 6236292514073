import Back__TagsSelectController from "./tags_select_controller"
// Connects to data-controller="back--tags-select-product-price-unit"
export default class extends Back__TagsSelectController {
  others() {
    let productPriceEntityController = $(this.element).parents('.nested-fields').get(0).controller
    return productPriceEntityController.other_ppus(!$('.product_form').data().differentPricesShops, false, false);
  }

  otherTagSelectController() {
    return this.others().map((i, e) => e.controller.uniqueId).get().map((uniqueId) => {
      return $(`#select_tag_product_price_unit_${uniqueId}`).parent().get(0).controller
    })
  }

  propagateAppendVal(id) {
    let others = this.otherTagSelectController()
    for (const other of others) { other.appendVal(id) }
  }

  propagateRemoveVal(id) {
    let others = this.otherTagSelectController()
    for (const other of others) { other.removeVal(id) }
  }

  linkedEntityIds() {
    let unique_ids = this.others().map((i, e) => e.controller.uniqueId).get()
    unique_ids.push(this.entityId)
    return unique_ids;
  }
}
