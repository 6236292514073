import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--customer-equipment"
export default class extends Controller {
 connect() {
    $('.datepicke').datepicker({language: 'fr', autoclose: true})
    this.element[this.identifier] = this
    this.select = $(this.element).find('select.customer_equipment_equipment_stock_id_select')
    this.select_equipment_id = $(this.element).find('.customer_equipment_equipment_id_select select')
    setTimeout(() => {
      $(this.element).find('select').on('select2:select',function(){this.dispatchEvent(new Event('change',{bubbles:true}))})
      $(this.element).find('select.customer_equipment_equipment_stock_id_select').on('select2:unselect', () =>{
        $(this.element).find('.new_customer_entities_incomes_customer_equipments_equipment_stock_id input').val('')
      })
      this.current_value = this.select.val()
      this.populate_equipment_stock_id_choices()

      $(this.element).find('.incomes_customer_equipments_equipment_stock_id_container').toggle(
        $(this.element).parents('.income_fields').data().signature.length > 0
      )
      $(this.element).parents(".income_fields").get(0).controller.calc()

    }, 100)

  }

  populate_equipment_stock_id_choices() {
    let equipment_id = parseInt($(this.element).find('.customer_equipment_equipment_id_select').find('.select').val())
    let fiscal_vignette_equipments = gon.fiscal_vignette_equipments
    if(fiscal_vignette_equipments.includes(equipment_id)){
      $(this.element).find('.fiscal_vignette_date').removeClass('d-none')
      $(this.element).find('.fiscal_vignette_date input').datepicker({orientation: 'bottom', autoclose: true})
    }else{
      $(this.element).find('.fiscal_vignette_date').addClass('d-none')
      $(this.element).find('.fiscal_vignette_date input').val('')
    }
    if(gon.equipment_serial_number.includes(parseInt(this.select_equipment_id.val()))) {
      if(this.select.length) {
        let val = this.select.val()
        this.select.find('option').remove()
        this.select.append(new Option("Non assigné", '', false, false))
        $.each(gon.equipment_stock[this.select_equipment_id.val()], (index, element) => {
          this.select.append(new Option(element[0], element[1].id, false, element[1].id == this.select.data().val))
        })

        this.select.trigger('change')
      }

      $(this.element).find('.new_customer_entities_incomes_customer_equipments_equipment_stock_id_select').show()  
      $(this.element).find('.new_customer_entities_incomes_customer_equipments_assign_equipment').hide()
      $(this.element).find('.new_customer_entities_incomes_customer_equipments_unassign_equipment').hide()
      initElementSelect2($(this.element).find('.new_customer_entities_incomes_customer_equipments_equipment_stock_id_select select'))
    } else {
      $(this.element).find('.new_customer_entities_incomes_customer_equipments_equipment_stock_id_select').hide()  
      $(this.element).find('.new_customer_entities_incomes_customer_equipments_equipment_stock_id input').val($(this.element).data().equipmentStockId)
      let val = $(this.element).find('.new_customer_entities_incomes_customer_equipments_equipment_stock_id input').val()
      $(this.element).find('.new_customer_entities_incomes_customer_equipments_assign_equipment').toggle(val.length == 0)
      $(this.element).find('.new_customer_entities_incomes_customer_equipments_unassign_equipment').toggle(val.length != 0)
    }

    this.equipment_stock_id_changed() 


    $(this.element).parents(".income_fields").get(0).controller.calc()
    this.select.addClass('finish_select')

  }

  equipment_stock_id_changed() {
    // let element = $(this.element).find('.customer_equipment_equipment_stock_id_select')
    // let element_value = $(this.element).find('.customer_equipment_equipment_stock_id_select').val()
    // let prev = $(this.element).find('.new_customer_entities_incomes_customer_equipments_equipment_stock_id').data().prev

    // if(prev != undefined){
    //   $.each(element.parents('.customer_equipments_container').find('.customer_equipment_fields'), function(index, other) {
    //     $(other).find(`option[value="${prev}"]`).prop('disabled', false)
    //     $(other).find(`option[value="${element_value}"]`).prop('disabled', true)
    //     updateSelect2($(other).find('select'))
    //   })
    // }
    // element.parent().parent().data('prev', element_value)
    if($(this.element).find('select.customer_equipment_equipment_stock_id_select:visible').length) {
      $(this.element).find('.new_customer_entities_incomes_customer_equipments_equipment_stock_id input').val(this.select.val())
    }
    
    this.price = (this.select && this.select.val()) ? gon.all_equipment_stocks[this.select.val()] : gon.equipment_price[this.select_equipment_id.val()]
    $(this.element).find('.income_customer_equipment_cost').val(this.price)
    $(this.element).find('.customer_equipment_cost_display').text(`${this.price} € HT`)
  }
}
