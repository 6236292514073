import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--sponsorship"
export default class extends Controller {
  static targets = ['fullname', 'email', 'emailList']
  connect() {
    this.addingEmails = []
    /**
    $(this.fullnameTarget).val('Jordan Lamour')
    $(this.emailTarget).val('jordan.lamour@gmail.com')
    this.addEmail()
    $(this.fullnameTarget).val('Emma Beauclair')
    $(this.emailTarget).val('emma.beauclair@gmail.com')
    this.addEmail()
    **/
    $.get('/back/sponsorship/fetch_infos').done((data) => {
      this.serverDatas = data
      this.populateTable(data)
    })
  }

  addEmail() {
    this.errors = false
    $(this.fullnameTarget).parent().removeClass('sponsorship-error')
    $(this.emailTarget).parent().removeClass('sponsorship-error')
    if($(this.fullnameTarget).val().length == 0 || $(this.fullnameTarget).val().trim().length == 0) {
      this.addError($(this.fullnameTarget), 'Vous devez renseigner un nom')
    }
    if($(this.emailTarget).val().length == 0 || $(this.emailTarget).val().trim().length == 0) {
      this.addError($(this.emailTarget), 'Vous devez renseigner un email')
    } else if (!$(this.emailTarget).val().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      this.addError($(this.emailTarget), "L'email n'est pas valide")
    } else if (this.addingEmails.map(o => o.email).includes($(this.emailTarget).val().trim())) {
      this.addError($(this.emailTarget), "Cet email est déjà utilisé")
    } else if (this.serverDatas.map(o => o.email).includes($(this.emailTarget).val().trim())) {
      this.addError($(this.emailTarget), "Une demande existe déjà pour cette personne")
    }
    if(this.errors) { return false }

    let addingEmail = {
      email: $(this.emailTarget).val().trim(),
      fullname: $(this.fullnameTarget).val().trim()
    }

    $(this.fullnameTarget).val('')
    $(this.emailTarget).val('')
    this.addingEmails.push(addingEmail)
    $(this.emailListTarget).append(`
      <p data-email="${addingEmail.email}">
        <span>${addingEmail.email} | ${addingEmail.fullname}</span>
        <i data-action="click->back--sponsorship#removeEmail" class="fas fa-times"></i>
      </p>
    `)
  }

  addError(target, message) {
    this.errors = true
    target.parent().addClass('sponsorship-error')
    target.next().text(message)
  }

  removeEmail(e) {
    this.addingEmails.splice(this.addingEmails.findIndex(o => o.email == $(e.currentTarget).parent().data().email))
    $(e.currentTarget).parent().remove()
  }

  populateTable(data) {
    $('#shop_group_sponsorship_followup_table').toggle(data.length > 0)
    $('#shop_group_sponsorship__followup_no_demand').toggle(data.length == 0)
    if(data.length > 0) {
      let html
      for (const demand of data) {
        html += `
          <tr>
            <td>${demand.fullname}</td>
            <td>${this.demandStatus(demand.status)}
          </tr>
        `
      }
      $('#shop_group_sponsorship_followup_table tbody').html(html)
    }

  }

  demandStatus(status) {
    return {
      mail_sent: "Mail envoyé<span class='bg-info'><i class='fas fa-paper-plane'></i></span>",
      prospect_bound: "Prospect Toporder<span class='bg-info'><i class='fas fa-user-plus'></i></span>",
      demo_confirmed: "Démo programmée<span class='bg-warning'><i class='fas fa-ellipsis-h'></i></span>",
      client_signed: "Devis signé<span class='bg-warning'><i class='fas fa-file-signature'></i></span>", 
      client_installed: "Client installé<span class='bg-success'><i class='fas fa-check'></i></span>",
      not_eligible: "Filleul non éligible<span class='bg-danger'><i class='fas fa-ban'></i></span>"
    }[status]
  }

  submit() {
    this.addEmail()
    $(this.fullnameTarget).parent().removeClass('sponsorship-error')
    $(this.emailTarget).parent().removeClass('sponsorship-error')
    if(this.addingEmails.length == 0) { return false }
    $('#loading_app').addClass('d-flex')
    $.ajax({
      url: '/back/sponsorship/send_invitations',
      method: 'POST',
      data: {body: this.addingEmails, customText: $('#sponsorship_custom_message').val().trim()}
    }).done((result) => {
      $('#loading_app').removeClass('d-flex')
      $('#sponsorship_custom_message').val('')
      $(this.emailListTarget).html('')
      this.addingEmails = []
      this.populateTable(result)
    })
  }
}
