import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init'
// Connects to data-controller="back--back-api-modules"
export default class extends Controller {
  connect() {
    initSelect2($('.back_api_modules_select_shop'))
    $(this.element).on('select2:select', '.back_api_modules_select_shop', (e) => {
      $(this.element).find('.tab-pane').removeClass('active')
      $(`#tab_module_shop_${$(e.currentTarget).val()}`).addClass('active')
    })
  }
}
