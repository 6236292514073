import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init';
// Connects to data-controller="admin--customer-invoices-new"
export default class extends Controller {
  connect() {
    $('.customer_invoice_income_container').hide()
    $(this.element).on('select2:select', '#customer_invoice_new_customer_id', () => {
      $('#customer_invoice_income_id option').remove()
      $.each($(this.element).data().incomes[$('#customer_invoice_new_customer_id').val()], (index, element) => {
        $('#customer_invoice_income_id').append(`<option value=${element.id}>Revenu #${index + 1} | ${element.installation_date}</option>`)
      })
      initSelect2($('#customer_invoice_income_id'))
      $('.customer_invoice_income_container').toggle($('#customer_invoice_income_id option').length > 0)
    })
  }
}
