import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="back--employees-index"
export default class extends Controller {
  connect() {
    $(this.element).find('select').on('select2:select select2:unselect', (e) => {
      console.log($(e.currentTarget).val())
      if($(e.currentTarget).val().length) {
        ajaxTurboStream(`/back/employees?shopIds=${$(e.currentTarget).val().join('_')}`, true)
      } else {
        ajaxTurboStream(`/back/employees`, true)
      }
    })
  }
}
