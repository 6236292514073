import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream'
// Connects to data-controller="back--smart-collect-shoppable-hot-sellable-table"
export default class extends Controller {
  new(e) {
    let params = {
      shoppable_type: $(this.element).data().shoppableType,
      shoppable_id: $(this.element).data().shoppableId,
      order: $(e.currentTarget).parent().data().order
    }
    if($(e.currentTarget).parent().data().sellableId) {
      params.sellable_id = $(e.currentTarget).parent().data().sellableId
      params.sellable_type = $(e.currentTarget).parent().data().sellableType
    }
    let query = new URLSearchParams(params).toString();
    ajaxTurboStream(`/back/smart_collect_shoppable_hot_sellables/new?${query}`)
  }

  delete(e) {
    let params = {
      shoppable_type: $(this.element).data().shoppableType,
      shoppable_id: $(this.element).data().shoppableId,
      order: $(e.currentTarget).parent().data().order
    }  
    let query = new URLSearchParams(params).toString();
    ajaxTurboStream(`/back/smart_collect_shoppable_hot_sellables/delete?${query}`)  
  }
}
