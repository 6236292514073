import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--automator-result"
export default class extends Controller {
  filter() {
    let url
    if($('#select_automator_result').val() == 'tous') {
      url = '/admin/automator_results'
    } else {
      url = `/admin/automator_results?BusinessCategory=${$('#select_automator_result').val()}`
    }

    ajaxTurboStream(url, true)
  }
}
