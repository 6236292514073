import Utils__NestedFormController from "controllers/utils/nested_form_controller"
import { Controller } from "@hotwired/stimulus"
import { classAgregator } from 'shared/class_agregator'
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="back--stock--mixed-products-form"
export default class extends classAgregator(Controller, Utils__NestedFormController)  {
  static targets = ['templateMixedProduct', 'contentMixedProduct']
  connect() {
    setTimeout(() => {
      $.ajax({
        url: `/back/mixed_products/fetch_form_datas`,
        method: 'get',
        dataType: "json",
        success: (response) => {
          console.log(response)
          this.products = response
          this.productsSelect.html(this.populateSource(this.products))
          this.loadMixedProducts()
        }
      })
    }, 500)    
  }

  get productsSelect() {
    return $('.mixed-product-form_product_stocks select')
  }

  addMixedProduct() {
    this.addAssociation('MixedProduct', {id: this.productsSelect.val()})
    this.productsSelect.find(':selected').prop('disabled', true)
    this.toggleSelect(this.productsSelect)
  }

  loadMixedProducts() {
    $.each($('.mixed-product-fields'), (i, element) => {
      let id = $(element).find('.product_stock_mixed_products_product_stock_id input').val()
      this.productsSelect.find(`option[value="${id}"]`).prop('disabled',true)
      this.decorateMixedProduct($(element), id)
    })
    this.toggleSelect(this.productsSelect)
  }


  callbackAfterAddMixedProduct(element, options) {
    element.find('.recipe_recipe_ingredients_quantity input').val(1)
    this.decorateMixedProduct(element, options.id)
  }

  decorateMixedProduct(element, id) {
    element.find('.mixed-product-fields__name').text(this.products[id].name)
    element.find('.input-group-text').text(this.products[id].referent_unit)
    element.find('.product_stock_mixed_products_product_stock_id input').val(id)
  }

  toggleSelect(select) {
    if(select.length == 0) { return }
    select.val(select.find('option').not(':disabled').eq(0).val())
    select.parent().parent().toggle(select.find('option').not(':disabled').length > 0)
    if(select.hasClass('select2-hidden-accessible')) {
      updateSelect2(select)
    } else {
      initSelect2(select)
    }

    select.trigger('change')
  }

  callbackBeforeRemoveMixedProduct(element) {
    return new Promise((resolve, reject) => {
      let id = element.find('.product_stock_mixed_products_product_stock_id input').val()
      this.productsSelect.find(`option[value="${id}"]`).prop('disabled', false)
      this.toggleSelect(this.productsSelect)
      resolve()
    })
  }

  callbackAfterRemoveMixedProduct() {}

  populateSource(source) {
    let html = ''
    for(const id in source) { html += `<option value='${id}'>${source[id].name}</option>` }
    return html
  }  
}
