import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash-notification"
export default class extends Controller {
  connect() {
    $.notify({
      message: $(this.element).data().message,
    }, {
      type: $(this.element).data().type,
      autoHide: false,
      clickToHide: true,
      z_index: 2000,
      autoHideDelay: 100000,
      placement: {
        from: 'top',
        align: 'left'
      }
    })
  }
}
