import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--export-stock"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      $('.stock_export_date_container').html(`
        <div class="form-label-group string optional">
          <label class="has-float-label">
            <input class="form-control string optional form-control-line datepicke" type="text" value="${$(this.element).data().date}" data-action="back--export-stock#dateChanged">
            <span class="float-span">Date de l'export</span>
          </label>
        </div>  
      `)
      $(this.element).find('input').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
        var top = parseInt($('main').css('paddingTop')) + parseInt($('.datepicker').css("top")) - 40 + 22;
        $('.datepicker').css("top", top);
      }).on('change', (e) => {
        let date = $(this.element).find('input').val().split('/').join('-')
        $('.export_stock_link').eq(0).attr('href', `/back/export/stock?date=${date}&mode=${$(this.element).data().mode}`)
      })   
    }, 500)

    $(this.element).find('a').on('click', () => {
      $('.modal-body').html('Votre fichier est en cours de préparation. Cela peut prendre un peu de temps. Vous pourrez fermer cette fenêtre à la fin du téléchargement')
      $('.modal-footer').html(`
        <p class='btn btn-outline-primary' onClick="location.reload()">J'ai reçu mon fichier</p>
      `)      
    })

  }
}
