import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init'; 
// Connects to data-controller="admin--ai-event-date-configuration-fields"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    $(this.element).find('.ai_event_ai_event_date_configurations_startDate input').rules('add',
      {required: true}
    )
    this.recurrenceText = {
      RECURRING_WEEKLY: {
        label: "toutes les",
        inputGroup: "semaines"
      },
      RECURRING_MONTHLY: {
        label: "tous les",
        inputGroup: "mois"
      },
      RECURRING_YEARLY: {
        label: "tous les",
        inputGroup: "ans"
      }
    }

    this.loadDatepicker()

    this.durationText = {HOUR: 'heures', DAY: 'jours', MONTH: 'mois'}
    this.aiEventDateTypeChanged()
    this.aiEventDurationTypeChanged()

    $(this.element).find('.ai_event_ai_event_date_configurations_recurrenceInterval .float-span').hide()
    $(this.element).find('.ai_event_ai_event_date_configurations_aiEventDateType select').on('select2:select',(e) => {
      this.aiEventDateTypeChanged()
    })
    $(this.element).find('.ai_event_ai_event_date_configurations_aiDurationType select').on('select2:select',(e) => {
      this.aiEventDurationTypeChanged()
    })
  }

  loadDatepicker() {
    setTimeout(() => {
      $(this.element).find('.turbo_datepicker:visible').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
        var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
        $(".datepicker").css("top", top);
      }).on('changeDate', () => {
        $(this.element).parents('form').valid()
      })
    }, 200)
  }

  addException() {
    $(this.element).find('.add_ai_event_exception_link a').click()
    setTimeout(() => {
      this.checkEventExceptions()
    }, 500)
  }

  checkEventExceptions() {
    $(this.element).find('.remove_ai_event_exception_button').toggle(true)
  }


  aiEventDurationTypeChanged() {
    let aiEventDurationType = $(this.element).find('.ai_event_ai_event_date_configurations_aiDurationType select').val()
    $(this.element).find('.ai_event_ai_event_date_configurations_duration .input-group-text').text(this.durationText[aiEventDurationType])
  }

  removeConfiguration() {
    $(this.element).addClass('deleted')
    $(this.element).find('.remove_ai_event_date_configuration_link').click()
    $(this.element).parents('form').get(0).controller.checkEventDateConfigurations()
  }

  aiEventDateTypeChanged() {
    let aiEventDateType = $(this.element).find('.ai_event_ai_event_date_configurations_aiEventDateType select').val()
    if(aiEventDateType == 'FIXED') {
      $(this.element).find('.ai_event_ai_event_date_configurations_startDate label').text('Date')
      $(this.element).find('.ai_event_date_configuration_recurrence_container').hide()
      $(this.element).find('.ai_event_ai_event_date_configurations_recurrenceInterval').hide()
    } else {
      $(this.element).find('.ai_event_date_configuration_recurrence_container').show()
      $(this.element).find('.ai_event_ai_event_date_configurations_recurrenceInterval').show()
      $(this.element).find('.ai_event_ai_event_date_configurations_startDate label').text('Date de début de la récurrence')
      initSelect2($(this.element).find('.ai_event_date_configuration_recurrence_container select:visible'))
      $(this.element).find('.ai_event_ai_event_date_configurations_recurrenceInterval label').text(`Se répète ${this.recurrenceText[aiEventDateType].label} :`)
      $(this.element).find('.ai_event_ai_event_date_configurations_recurrenceInterval .input-group-text').text(this.recurrenceText[aiEventDateType].inputGroup)
      this.loadDatepicker()
    }
  }
}
