import Utils__NestedFormController from "../utils/nested_form_controller"

// Connects to data-controller="admin--income-task-scenario"
export default class extends Utils__NestedFormController {
  static targets = ["contentIncomeTaskLink", "templateIncomeTaskLink"];

  connect() {
    this.reload_income_task_scenario_order()
  }

  callbackAfterAddIncomeTaskLink(element) {
    this.set_income_task_link_orders()
    this.reload_income_task_scenario_order()
  }

  callbackBeforeRemoveIncomeTaskLink(element) {
    return new Promise((resolve, reject) => { resolve() })
  }

  callbackAfterRemoveIncomeTaskLink() {
    this.set_income_task_link_orders()
    this.reload_income_task_scenario_order()
  }

  set_income_task_link_orders() {
    $.each($('.income_task_link_field:visible'), function(index, element) {
      $(element).find('.income_task_link_order').val(index)
    })
  }

  reload_income_task_scenario_order() {
    $('.income_task_links_container').sortable({
      axis: 'y',
      containment: 'parent',
      cursor: 'move',
      handle: '.grab_income_task_link',
      items: '.income_task_link_field',
      distance: 10,
      deactivate: (event, ui) => {
        this.set_income_task_link_orders()
      }
    })
  }
}
