import { Controller } from "@hotwired/stimulus"
import { put, destroy } from "@rails/request.js"
import { flashMessage } from 'shared/notify';

// Connects to data-controller="admin--goal-row"
export default class extends Controller {
  connect() {
    this.id = $(this.element).data().id
  }

  editValue(e) {
    let cell = $(e.currentTarget)
    cell.hide()
    cell.after(`
      <label class="has-float-label input-group value_row_quantity_container">
        <input class="form-control numeric float optional" type="number" step="any" value="">
        <div class="input-group-append"  data-action="click->admin--goal-row#cancelEdit">
          <span class="input-group-text clickable"><i class="fas fa-times"></i></span>
        </div>
        <div class="input-group-append clickable"  data-field='${$(e.currentTarget).data().field}' data-action="click->admin--goal-row#validateValue">
          <span class="input-group-text"><i class="fas fa-check"></i></span>
        </div>
      </label>
    `)
    cell.next().find('input').focus()
  }

  cancelEdit(e) {
    $(e.currentTarget).parents('td').find('span').show()
    $(e.currentTarget).parents('td').find('label').remove()
  }

  validateValue(e) {
    let value = parseFloat($(e.currentTarget).parent().find('input').val().replace(',','.'))
    let previous_value= parseFloat($(e.currentTarget).parents('td').find('span').text().trim().replace(',','.'))
    if(!isNaN(value) && previous_value != value) {
      $(e.currentTarget).parents('td').find('span').eq(0).text(value)
      put(`/admin/goals/${this.id}/update_value`, {body: {field: $(e.currentTarget).data().field, value: value}})
      flashMessage('', `L'objectif commercial a bien été modifié`, 'success')
    }
    this.cancelEdit(e)
  }
}