import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="back--calendar-hour-orders"
export default class extends Controller {
  connect() {
    this.params = new URLSearchParams(window.location.search);
    $(document).on('select2:select', $('#calendar-hour-index__select'), () => {
      if($('#calendar-hour-index__select').val() == '_') {
        this.params.delete('shopId')
      } else {
        this.params.set('shopId', $('#calendar-hour-index__select').val())
      }
      this.reload()
    })
  }

  changePage(e) {
    this.params.set('startDate',$(e.currentTarget).data().date)
    this.reload()
  }

  reload() {
    let url = window.location.href.split("?")[0]
    if(this.params.toString().length) {
      url += `?${this.params.toString()}`
    }
    ajaxTurboStream(url, true)
  }
}
