import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
require('daterangepicker');

// Connects to data-controller="back--order-payments-index"
export default class extends Controller {
  static targets = ['dateRange', 'payment', 'shop']
  connect() {
    $(this.dateRangeTarget).daterangepicker({
      parentEl: ".filter_card",
      maxDate: moment().add(30, 'days'),
      ranges: {
         'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
         "Aujourd'hui" : moment(),
         'Les 7 derniers jours': [moment().subtract(6, 'days'), moment()],
         'Les 30 derniers jours': [moment().subtract(29, 'days'), moment()],
      },
      startDate: $(this.dateRangeTarget).data().startDate.split('/').join('-'),
      endDate: $(this.dateRangeTarget).data().endDate.split('/').join('-')
    }, (start, end, picker) => {
      if(picker == "Personnalisé") {
        $(this.dateRangeTarget).find('span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      } else {
        $(this.dateRangeTarget).find('span').text(picker)
      }
      $(this.dateRangeTarget).data('startDate', start.format('DD-MM-YYYY'))
      $(this.dateRangeTarget).data('endDate', end.format('DD-MM-YYYY'))
    })
  }

  filter() {
    let params = [`startDate=${$(this.dateRangeTarget).data('startDate')}`, `endDate=${$(this.dateRangeTarget).data('endDate')}`]
    let url = window.location.pathname

    if($(this.paymentTarget).val().length && $(this.paymentTarget).val() != 'Tous') {
      params.push(`name=${$(this.paymentTarget).val()}`)
    }
    if($(this.shopTarget).val().length && $(this.shopTarget).val() != "Toutes") {
      params.push(`shop_id=${$(this.shopTarget).val()}`)
    }

    ajaxTurboStream(`${window.location.pathname}?${params.join('&')}`, true)
  }
}
