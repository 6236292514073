import Back__Statistics__V2__StatisticsAbstractFilterController from "./statistics_abstract_filter_controller"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="back--statistics--v2--statistics-employees"
export default class extends Back__Statistics__V2__StatisticsAbstractFilterController {
  get autoRefresh() {
    return true
  }
  
  refresh(params) {
    ajaxTurboStream(`/back/statistics/v2/employees/reload_table?${new URLSearchParams(params).toString()}`)
  }
}
