import Utils__NestedFormController from "controllers/utils/nested_form_controller"
import { Controller } from "@hotwired/stimulus"
import { classAgregator } from 'shared/class_agregator'
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="admin--valrhona-questions-form"
export default class extends classAgregator(Controller, Utils__NestedFormController) {
  static targets = ['templateValrhonaQuestionOption', 'contentValrhonaQuestionOption']
  connect() {
    $(this.element).find('select').on('select2:select',() => {
      this.checkQuestionType()
    })
    this.checkQuestionType()
  }

  checkQuestionType() {
    $('.valrhona_question_options_container').toggle($('#valrhona_question_question_type').val() == 'select')
  }

  callbackAfterAddValrhonaQuestionOption(element, options) { }
  callbackBeforeRemoveValrhonaQuestionOption(element) {
    return new Promise((resolve, reject) => { resolve() })
  }
  callbackAfterRemoveValrhonaQuestionOption() {}
}
