import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="admin--commercial-actions-card"
export default class extends Controller {
  connect() {
    this.id = $(this.element).data().id
  }

  archive_action(e) {
    ajaxTurboStream(`/admin/commercial_actions/${this.id}/archive_action`)
  }

  cancel_action(e) {
    ajaxTurboStream(`/admin/commercial_actions/${this.id}/cancel_action`)
  }

  reactive_action(e) {
    ajaxTurboStream(`/admin/commercial_actions/${this.id}/reactive_action`)
  }

  accomplish_action(e) {
    ajaxTurboStream(`/admin/commercial_actions/${this.id}/accomplished_action`)
  }

  editEmployee(e) {
    ajaxTurboStream(`/admin/commercial_actions/${this.id}/edit_employee`)
  }

  editDuration(e) {
    initModal()
    this.prepareModal("Modification de la durée")
    let duration = $(this.element).find('.commercial_action_card_duration').text()
    $('.modal .modal-dialog .modal-body').html(`
      <div data-controller="admin--commercial-action-edit" data-field="duration" data-id="${this.id}">
        <div class="input-group" >
          <input type="text" class="form-control" value="${duration}">
          <div class="input-group-append">
            <span class="input-group-text">minutes</span>
          </div>
        </div> 
        <p class='mb-0 mt-2 btn btn-primary' data-action="click->admin--commercial-action-edit#submit">Modifier</p>
      </div>
    `)    
  }

  editComment(e){
    initModal()
    this.prepareModal("Modification du commentaire")
    let comment = $(this.element).find('.commercial_action_card_empty_comment').length ? '' : $(this.element).find('.commercial_action_card_comment div').html()
    $('.modal .modal-dialog .modal-body').html(`
      <div data-controller="admin--commercial-action-edit" data-field="comment" data-id="${this.id}">
        <textarea class="form-control w-100" rows=5>${comment}</textarea>
        <p class='mb-0 mt-2 btn btn-primary' data-action="click->admin--commercial-action-edit#submit">Modifier</p>
      </div>
    `)
  }

  editDate(e){
    initModal()
    this.prepareModal("Modification de la date")
    $('.modal .modal-dialog .modal-body').html(`
      <div data-controller="admin--commercial-action-edit" data-field="date" data-id="${this.id}">
        <input class="form-control" value="${$(this.element).find('.commercial_action_card_date').text()}" onfocus='blur();'>
        <fieldset>
          <legend>Heure</legend>
          <div class="d-flex align-items-center">
            <select class="form-control is-valid select optional select_prevent_remove" id="edit_commercial_action_hour" style='width: 60px'>
              ${this.populateHour($(e.currentTarget))}
            </select>
            <span class="mx-1">h</span>
            <select class="form-control is-valid select optional select_prevent_remove" id="edit_commercial_action_minutes" style='width: 60px'>
              ${this.populateMinutes($(e.currentTarget))}
            </select>
            <span class="mx-1">minutes</span>
          </div>
          
          <p class="text-toporder d-none mb-0 font-semibold" id="shop_fiscal_open_hour_error">L'heure doit être un entier compris entre 0 et 23.</p>
        </fieldset>   
        <p class='mb-0 mt-2 btn btn-primary' data-action="click->admin--commercial-action-edit#submit">Modifier</p>
      </div>
    `)

    $('.modal-body input').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
      var top = parseInt($('main').css('paddingTop')) + parseInt($('.datepicker').css("top"));
      $('.datepicker').css("top", top);
    })
  }

  populateHour(element) {
    let html = ""
    let hour = parseInt(element.find('.commercial_action_card_hour_minutes').data().hour)
    $.each([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], (i, h) => {
      html += `
        <option value="${h}" ${h == hour ? "selected" : ""}>${h}</option>
      `
    })
    return html
  }

  populateMinutes(element) {
    let html = ""
    let hour = parseInt(element.find('.commercial_action_card_hour_minutes').data().minutes)
    $.each([0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55], (i, h) => {
      html += `
        <option value="${h}" ${h == hour ? "selected" : ""}>${h}</option>
      `
    })
    return html
  }

  prepareModal(title) {
    $('.modal-title').text(title)
    $('#modal_rails_5').modal('show')
    $('.modal-dialog').addClass('modal-l')
    $('.modal-footer').hide()
  }
}
