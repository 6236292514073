import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init';
import { loadDatepicker } from 'shared/load_datepicker';
// Connects to data-controller="admin--commercial-actions-itinerary-update-comment"
export default class extends Controller {
  connect() {
    console.log('bouh')
    let element = $(this.element)
    setTimeout(() => {
      element.removeClass('d-none')
      initSelect2(element.find('select'))
      loadDatepicker(element.find('.turbo_datepicker'))
    }, 500)
  }
}
