import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--shop-feature-device"
export default class extends Controller {
  connect() {
    this.localShopId = $(this.element).parents('.tab-pane').eq(0).data().localShopId
    this.localFeatureId = $(this.element).data().localFeatureId
  }

  params() {
    return `localShopId=${this.localShopId}&localFeatureId=${this.localFeatureId}`
  }

  remove_feature(e) {
    ajaxTurboStream(`/admin/shop_feature_devices/ask_remove_feature?shop_feature_uuid=${$(e.currentTarget).data().shopFeatureId}`)
  }

  active_feature(e) {
    ajaxTurboStream(`/admin/shop_feature_devices/ask_active_feature?${this.params()}`)
  }

  new_slot() {
    ajaxTurboStream(`/admin/shop_feature_devices/new_slot?${this.params()}`)
  }

  get_slot_list() {
    ajaxTurboStream(`/admin/shop_feature_devices/slot_list?${this.params()}`)
  }

  edit_slot(e) {
    ajaxTurboStream(`/admin/shop_feature_devices/${$(e.currentTarget).parent().data().id}/edit?${this.params()}`)
  }

  remove_slot(e) {
    ajaxTurboStream(`/admin/shop_feature_devices/${$(e.currentTarget).data().id}/delete`)
  }
}
