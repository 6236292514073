import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--z-tickets-index"
export default class extends Controller {
  connect() {
    $(this.element).find('.z_ticket_select_shop').on('select2:select', (e) => {
      if($(e.currentTarget).val() && $(e.currentTarget).val() != '_') {
        Turbo.visit(`/back/z_tickets?shop_id=${$(e.currentTarget).val()}`)
      }
    })
  }
}
