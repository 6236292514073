import { Controller } from "@hotwired/stimulus"
import { flashMessage } from 'shared/notify'
// Connects to data-controller="admin--admin-settings"
export default class extends Controller {
  fieldChange(e) {
    let value
    if($(e.currentTarget).data().field == 'important_message_active') {
      value = $(e.currentTarget).prop('checked')
    } else {
      value = $(e.currentTarget).val()
    }
    $.ajax({
      url: '/admin/admin_settings',
      method: 'PATCH',
      data: {field: $(e.currentTarget).data().field, value: value}
    }).done(() => {
      flashMessage('La modification a bien été sauvegardée', '', 'success')
    })
  }

  generateNewKey() {
    flashMessage('Une nouvelle version a bien été générée', '', 'success')
    $.get('/admin/admin_settings/generate_new_key')
  }
}
