import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from '../../shared/select2_init';
// Connects to data-controller="utils--nested-form"
export default class extends Controller {
  clickAddAssociation(event) {
    this.addAssociation($(event.currentTarget).data().model)
  }

  addAssociation(model, options, custom_target = undefined) {
    let target = custom_target ? custom_target : this[`content${model}Target`]
    target.insertAdjacentHTML("beforeEnd", this[`template${model}Target`].innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    ));
    initSelect2($(target).find('.nested-fields').eq(-1).find('select:visible'))
    this[`callbackAfterAdd${model}`]($(target).find('.nested-fields').eq(-1), options)
  }

  clickRemoveAssociation(event) {
    this.removeAssociation($(event.currentTarget).data().model)
  }

  removeAssociation(model, options) {
    let wrapper = event.target.closest(".nested-fields");
    this[`callbackBeforeRemove${model}`]($(wrapper), options).then(() => {
      if (wrapper.dataset.newRecord == "true" || !wrapper.querySelector("input[name*='_destroy']")) {
        wrapper.remove();
      } else {
        $(wrapper).addClass('deleted')
        wrapper.querySelector("input[name*='_destroy']").value = 1;
        wrapper.style.display = "none";
      }
      this[`callbackAfterRemove${model}`](options)
    })
  }
}
