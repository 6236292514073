import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="admin--prospects--prospect-after-ajax-create"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      ajaxTurboStream(`/admin/commercial_actions/new?prospect_id=${parseInt($(this.element).data().id)}`)
    }, 500)
    
  }
}
