import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
import { flashMessage } from 'shared/notify'
// Connects to data-controller="admin--commercial-actions-itinerary"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    this.geocoder = new google.maps.Geocoder()
    this.coords = {
      startCoords: $(this.element).data().startCoords,
      endCoords: $(this.element).data().endCoords,
      homeCoords: $(this.element).data().homeCoords
    }
    $('#commercial_action_itinerary_date').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
      var top = parseInt($('main').css('paddingTop')) + parseInt($("#commercial_action_itinerary_date").css("top")) - 40;
      $("#commercial_action_itinerary_date").css("top", top);
    }).on('changeDate', (event) => {
      this.reloadItinerary()
    })
    this.reloadItinerary()
    $(this.element).find('#itinerary_commercial').on('select2:select', () => {
      this.reloadItinerary()
    })
  }

  inputFocus(e) {
    $(e.currentTarget).val('')
    $(e.currentTarget).parents('fieldset').find('.text-toporder').addClass('d-none')
  }

  clickApp(e) {
    e.stopPropagation()
  }

  inputBlur(e) {
    this.currentTarget = $(e.currentTarget)
    if(this.currentTarget.val().trim().length == 0) {
      this.currentTarget.val(this.coords[`${location_type}Coords`].address)
    } else {
      this.geocoder.geocode({address: this.currentTarget.val()}, (results, status) => {
        this.handleGeocodeResponse(results, status)
      })
    }
  }

  handleGeocodeResponse(results, status) {
    let location_type = this.currentTarget.parents('.itinerary_location_input_container').data().location
    if(status == 'ZERO_RESULTS') {
      this.currentTarget.parents('fieldset').find('.text-toporder').removeClass('d-none')
      this.currentTarget.parents('fieldset').find('input').val(this.coords[`${location_type}Coords`].address)
    } else {
      this.coords[`${location_type}Coords`].address = results[0].formatted_address
      this.coords[`${location_type}Coords`].lat = results[0].geometry.location.lat()
      this.coords[`${location_type}Coords`].lng = results[0].geometry.location.lng()
      this.currentTarget.parents('fieldset').find('input').val(this.coords[`${location_type}Coords`].address)
      this.reloadItinerary()
    }    
    this.currentTarget = undefined
  }

  selectHome(e) {
    let location_type = $(e.currentTarget).parents('.itinerary_location_input_container').data().location
    this.coords[`${location_type}Coords`] = this.coords.homeCoords
    $(e.currentTarget).parents('.itinerary_location_input_container').find('input').val(this.coords.homeCoords.address)
    this.reloadItinerary()
  }

  getCurrentPosition(e) {
    this.currentTarget = $(e.currentTarget)
    navigator.geolocation.getCurrentPosition(
      (position) => { this.handleLocation(position) },
      (err) => { this.errorLocation(err) }      
    );
  }

  handleLocation(position) {
    this.geocoder.geocode({location: {lat: position.coords.latitude, lng: position.coords.longitude}}, (results, status) => {
      this.handleGeocodeResponse(results, status)
    })
  }

  errorLocation(err) {
    if(err.code == 1) {
      flashMessage('', "Veuillez accepter la géolocalisation", 'warning')
    }
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  reloadItinerary() {
    let body = {
      start_lat: this.coords.startCoords.lat,
      start_lng: this.coords.startCoords.lng,
      start_address: btoa(this.coords.startCoords.address),
      end_lat: this.coords.endCoords.lat,
      end_lng: this.coords.endCoords.lng,
      end_address: btoa(this.coords.endCoords.address),
      date: $('#commercial_action_itinerary_date').val().split('/').join('-'),
      begin_hour: $('#commercial_action_itinerary_begin_hour').val(),
      begin_minute: $('#commercial_action_itinerary_begin_minute').val(),   
      loop: $('#commercial_action_itinerary_loop').prop('checked') ? 'true' : 'false'  
    }
    if($('#itinerary_commercial').length) {
      body.toporder_employee_id = $('#itinerary_commercial').val()
    }

    console.log(body)
    window.history.replaceState(null, null, `${window.location.href.split('?')[0]}?${new URLSearchParams(body).toString()}`)
    ajaxPostTurboStream(
      '/admin/commercial_actions/itinerary/reload_itinerary',
      body
    )
  }
}
