import { get, destroy, post, patch } from "@rails/request.js"

export function ajaxTurboStream(url, update_url, callback = () => {}) {
  $('#loading_app').addClass('d-flex')
  get(url.toString(), { responseKind: "turbo-stream" }).then(() => {
    $('#loading_app').removeClass('d-flex')
    $('.toporder_table_reloading').removeClass('toporder_table_reloading')
    callback()
  })
  if(update_url) {
    history.pushState({}, null, url.toString())
    Turbo.navigator.history.replace(url.toString())    
  }
}

export function ajaxPostTurboStream(url, body, callback = () => {}) {
  $('#loading_app').addClass('d-flex')
  post(url.toString(), { body: body, responseKind: "turbo-stream" }).then(() => {
    $('#loading_app').removeClass('d-flex')
    callback()
  })
}

export function ajaxPatchTurboStream(url, body, callback = () => {}) {
  $('#loading_app').addClass('d-flex')
  patch(url.toString(), { body: body, responseKind: "turbo-stream" }).then(() => {
    $('#loading_app').removeClass('d-flex')
    callback()
  })
}

export function ajaxDeleteTurboStream(url) {
  $('#loading_app').addClass('d-flex')
  destroy(url.toString(), { responseKind: "turbo-stream" }).then(() => {
    $('#loading_app').removeClass('d-flex')
  })
}
