import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--listing--basket-item-lines"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    this.shopIds = $('.listing_filter_shops :checked').map((i, e) => {return $(e).val()}).get()
    this.reload()
    this.interval_orders = setInterval(() => {
      if($(this.element).hasClass('updating_basket_item_line')) {
        return false;
      }
      this.reload()
    }, 10000)
  }

  disconnect() {
    clearInterval(this.interval_orders)
  }

  reload() {
    $.ajax({
      method: 'POST',
      data: {shopIds: this.shopIds},
      url: '/back/listing_basket_item_lines/reload'
    }).done(data => {
      $('#listing_basket_item_lines_view').html('')
      for (const basket_item_line of data) {
        let seq = basket_item_line.continousSequence
        if(basket_item_line.dailyOrderNumber && basket_item_line.dailyOrderNumber != 0) {
          seq += ` | #${basket_item_line.dailyOrderNumber}`
        }
        $('#listing_basket_item_lines_view').append(`
          <div class='col-12 col-md-4 col-xl-3'>
            <div data-id="${basket_item_line.id}" style='background: ${basket_item_line.color};' data-action="click->back--listing--basket-item-lines#delivered">
              <p class='basket_item_line_name'>${basket_item_line.name}</p>
              <div>${this.populateSubInformations(basket_item_line.sub_informations)}</div>
              <div>
                <span class='bg-white p-2 text-toporder d-inline-block'>
                  #${seq} | ${basket_item_line.number}
                </span>
              </div>
            </div>
          </div>
        `)
      }
    })
  }

  populateSubInformations(sub_informations) {
    let html = ''
    for(const information of sub_informations) {
      html += `
        <span class='bg-white p-2 text-black d-inline-block mr-2 mb-2'>${information}</span>
      `
    }
    return html
  }

  delivered(e) {
    let id = $(e.currentTarget).data().id
    $(e.currentTarget).parent().remove()
    $.ajax({
      method: 'PATCH',
      url: `/back/listing_basket_item_lines/${id}`,
      data: {status: 'delivered'}
    })
  }

  disconnect() {
    clearInterval(this.interval_orders)
  }
}
