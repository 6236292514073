import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tomorrow-weather"
export default class extends Controller {
  connect() {
    let i = 0;
    var timer = setInterval(function() {
      if($('.tomorrow iframe').length && $('.tomorrow iframe').contents().find('#root').html().length || i === 5) {
        clearInterval(timer);
        $.ajax({
          method: 'POST',
          url: '/back/dashboard_save_tomorrow',
          data: {html: $('.tomorrow iframe').contents().find('#root').html()}
        })
      }
    }, 500);
  }
}
