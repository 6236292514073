import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream, ajaxDeleteTurboStream } from 'shared/ajax_turbo_stream';
import { flashMessage } from 'shared/notify';
require ('spectrum-colorpicker');
// Connects to data-controller="catalog--product-categories--product-category"
export default class extends Controller {
  connect() {
    this.product_category = $(this.element)
    this.parent_category = $(this.element).parents('.sub-card-container')
    this.element['controller'] = this
    this.initSortable()
    setTimeout(() => {
      this.loaded = true
    })
    if(this.product_category.hasClass('sortable_moving')) {
      this.product_category.removeClass('sortable_moving')
    } else {
      if(this.product_category.parents('.sub-card-container').length) {
        this.product_category.parents('.sub-card-container').get(0).controller.reload()
      } else {
        $('#product_categories_container').get(0).controller.reload()
      }    
    }

    this.product_category.find('.product_category_color').spectrum({
      type: "component",
      hideAfterPaletteSelect: true,
      showButtons: false,
      allowEmpty: false,
      preferredFormat: "hex",
      color: this.product_category.find('.product_category_color').css('backgroundColor'),
      change: (color) => {
        let hex = color.toHexString()
        this.product_category.find('.product_category_color').css('backgroundColor', hex)
        $.ajax({
          method: 'PUT',
          url: `/${$('body').data().context}/product_categories/${this.product_category.data().id}/update_color`,
          data: {color: hex}
        }).done((data) => {
          flashMessage('', i18n.t('pages.product_categories.update_color'), 'success')
        })
      }
    });
  }

  reload() {
    if(this.loaded) { 
      this.initSortable();
      if(this.product_category.find('.sub-card-container').length) {
        this.product_category.find('> .card').addClass("opened")
        this.product_category.find('> .card .toggle_carret').removeClass('invisible')
        this.product_category.find("> .sub-card").show(200)
        this.product_category.find('.remove_category_button .simple-icon-trash').eq(0).addClass('invisible')
      } else {
        this.product_category.find('> .card').removeClass("opened")
        this.product_category.find('> .card .toggle_carret').addClass('invisible')
        this.product_category.find("> .sub-card").hide()
        this.product_category.find('.remove_category_button .simple-icon-trash').eq(0).removeClass('invisible')
      }
    }
  }

  destroy() {
    ajaxDeleteTurboStream(`/${$('body').data().context}/product_categories/${$(this.element).data().id}`)
  }

  disconnect() {
    if(this.product_category.hasClass('sortable_moving')) {
      return;
    }
    if(this.parent_category.length) {
      this.parent_category.get(0).controller.reload()  
    }
  }

  initSortable() {
    if(this.product_category.find('.sub-card-container').length < 2) {
      return
    }
    $(this.element).find('.sub-card-categories').sortable({
      axis: 'y',
      containment: 'parent',
      cursor: 'move',
      handle: '> .card > .d-flex > .card-body > .grab_product_category_order',
      items: '> .sub-card-container',
      distance: 10,
      tolerance: 'pointer',
      deactivate: function(event, ui) {
        $(ui.item).addClass('sortable_moving')
        var array_position = []
        $.each($(ui.sender).find('> .sub-card-container'), function(index, element) {
          console.log($(element))
          array_position.push({
            productCategoryId: $(element).data().id,
            order: index
          })
        })
        $.ajax({
          url: `/${$('body').data().context}/product_categories/update_positions`,
          type: "POST",
          data: {positions: array_position}
        })
      }
    })
  }

  toggleProducts(e) {
    if(this.product_category.find('> .sub-card > .sub-card-products').length) {
      if(this.product_category.find("> .sub-card > .sub-card-products").hasClass('d-none')) {
        this.product_category.find("> .sub-card > .sub-card-products").removeClass('d-none')
        $(e.currentTarget).text(i18n.t('pages.product_categories.hide_products'))
      } else {
        this.product_category.find("> .sub-card > .sub-card-products").addClass('d-none')
        $(e.currentTarget).text(i18n.t('pages.product_categories.show_products'))
      }
    } else {
      $(e.currentTarget).text(i18n.t('pages.product_categories.hide_products'))
      let productCategoryId = $(e.currentTarget).parents('.sub-card-container').eq(0).data().id
      ajaxTurboStream(`/${$('body').data().context}/product_categories/${productCategoryId}/show_products`, false)
    }    
  }
}
