import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init';
import { initTurboModal } from 'shared/modal'
// Connects to data-controller="admin--new-customer-calc"
export default class extends Controller {
  connect() {
    $('.datepicke').datepicker({language: 'fr', autoclose: true})
    this.element[this.identifier] = this
    if($('#new_customer_prospect_sub_origin_id').length) {
      this.toggleValrhonaCommercial()

      setTimeout(() => {
        this.sub_reasons = $('.new_client_form').data().origins
        this.checkSubReasons() 
        $('#new_customer_prospect_origin_id').on('select2:select', () => {
          this.checkSubReasons()
          this.toggleValrhonaCommercial()
        })     
      }, 300)      
    }

    $('#new_customer_edit form').validate({ignore: []})
    $(document).on('submit', '#new_customer_edit form', (e) => {
      if(gon.lock_employee_email != 'jordan.lamour@toporder.fr') {
        let has_pricings = true
        if ($('.income_fields').not('.deleting_field').length == 0){
          has_pricings = false
        }
        $('.income_fields').not('.deleting_field').each((index, fields) => {
            if($(fields).find('.income_id_option').val().length == 0){
              if ($(fields).find('.new_customer_pricing_fields').not('.deleting_field').length == 0){
                has_pricings = false
              }
            }
        })
        let equipment_stock_ids = $('select.customer_equipment_equipment_stock_id_select').map((i, e) => {return $(e).val()}).get().filter((e) => {return e.length > 1})
        let duplicate_equipment_stock_ids = equipment_stock_ids.length != [...new Set(equipment_stock_ids)].length
        if(!has_pricings || duplicate_equipment_stock_ids)  {
          e.preventDefault();
          if(!has_pricings) { flashMessage('', 'Vous devez renseigner au moins une offre par revenu', 'danger') }
          if(duplicate_equipment_stock_ids) { flashMessage('', 'Deux de vos équipements ont la même référence !', 'danger') }
        }
      }
    })
    $(this.element).find('form').validate()
    this.interval = setInterval(() => {
      let number_select = $('.customer_equipment_equipment_stock_id_select').length
      let number_finish = $('.customer_equipment_equipment_stock_id_select.finish_select').length
      if(number_select == number_finish) {
        clearInterval(this.interval)
        this.disable_select()
      }
    }, 2000)
  }

  showProspectComment() {
    initTurboModal()
    $('#modal .modal-footer').hide()
    $('#modal .modal-title').text('Commentaire du prospect')
    $('#modal .modal-body').html(`
      <div style='white-space: pre-wrap;'>
        ${$('#new_customer_prospect_comment_content').html()}
      </div>
    `)
  }

  originChanged() {
    //this.toggleValrhonaCommercial()
  }

  toggleValrhonaCommercial() {
    let is_valrhona = $('.new_client_form').data().prospectOriginsValrhona.includes(parseInt($('#new_customer_prospect_origin_id').val()))
    $('.valrhona_questions_container').toggleClass('d-none', !is_valrhona)
    $('.valrhona_answer_destroy').prop('checked', !is_valrhona)
    if(is_valrhona) {
      initSelect2($('.valrhona_questions_container select'))
    }
  }

  checkSubReasons() {
    $('#new_customer_prospect_sub_origin_id option').remove()
    $('#new_customer_prospect_sub_origin_id').val('')
    let selectedSub = $('#new_customer_prospect_origin_id').data().selectedSub
    if(this.sub_reasons[$('#new_customer_prospect_origin_id').val()]) {
      let subs = this.sub_reasons[$('#new_customer_prospect_origin_id').val()]
      for(const sub in subs) {
        $('#new_customer_prospect_sub_origin_id').append(`
          <option ${selectedSub && selectedSub == subs[sub].id ? 'selected' : ''} value='${subs[sub].id}'>${subs[sub].name}</option>
        `)
      }
      $('.new_customer_prospect_sub_origin_id').removeClass('d-none')
      initElementSelect2($('#new_customer_prospect_sub_origin_id'))
    } else {
      $('.new_customer_prospect_sub_origin_id').addClass('d-none')
    }
  }

  disable_select() {
    window.select_customer_equipments = []
    if($('.new_client_form').length) {
      // Gather unit ids
      $.each($('.new_customer_entities_incomes_customer_equipments_equipment_stock_id'), function(index, element) {
        select_customer_equipments.push($(element).find('select').val())
        $(element).data('prev', $(element).find('select').val())
      })
      // Parse selects and hide others' ids
      $.each($('.new_customer_entities_incomes_customer_equipments_equipment_stock_id'), function(index, element) {
        $.each(select_customer_equipments.filter(id => id != $(element).find('select').val()), function(index, customer_equipment_id) {
          $(element).find(`option[value="${customer_equipment_id}"]`).prop('disabled', true)
        })
      })
    }
  }

  data_filter(e) {
    var params = []
    if($('#smart_collect_client').val() != '_' && $('#smart_collect_client').val() !== null) {
      params.push(`smart_collect=${$('#smart_collect_client').val()}`)
    }
    if($('#new_customer_api_business_category_uuid').val() != '_' && $('#new_customer_api_business_category_uuid').val() !== null) {
      params.push(`api_business_category_uuid=${$('#new_customer_api_business_category_uuid').val()}`)
    }
    let url = params.length ? `/admin/new_customers?${params.join('&')}` : `/admin/new_customers`
    window.location = url
  }
}
