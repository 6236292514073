import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--fiscal-archives-index"
export default class extends Controller {
  connect() {
    $(this.element).on('select2:select', '.fiscal_archive_select_shop', (e) => {
      $(this.element).find('.tab-pane').removeClass('active')
      $(`#fiscal_archive_${$(e.currentTarget).val()}`).addClass('active')
    })
  }
}
