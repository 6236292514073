import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--ai-event-form"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    $(this.element).validate()
    this.checkEventDateConfigurations()
    this.impactTypeChanged()
    $(this.element).on('click', '.ai_event_aiEventImpactType label', () => {
      this.impactTypeChanged()
    })
  }

  checkEventDateConfigurations() {
    $('.remove_ai_event_date_configuration_button').toggle($('.ai_event_date_configuration_fields').not('.deleted').length > 1)
  }

  addEventDateConfiguration() {
    $('.add_ai_event_date_configuration_link a').click()
    setTimeout(() => {
      this.checkEventDateConfigurations()
    }, 500)
  }

  impactTypeChanged() {
    setTimeout(() => {
      $('.ai_event_aiEventImpactType input ~ label').css('backgroundColor', 'inherit')
      let label = $('.ai_event_aiEventImpactType input:checked ~ label')
      label.css('backgroundColor', label.data().color)
    }, 200)  
  }
}
