import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--smart-collect-products-picture-cropper"
export default class extends Controller {
  static targets = ["errorDisplay"]
  connect() {
  }

  pictureChanged(e) {
    if (['image/jpeg', 'image/png', 'image/gif', 'image/jpg'].indexOf(e.target.files[0].type) == -1) {
      $('#form_picture').val('')
      $(this.errorDisplayTarget).text(`
        Le format d'image ${e.target.files[0].type.split('/')[1]} n'est pas pris en compte
      `)
      $(this.errorDisplayTarget).removeClass('d-none')
      $('.main_picture_to_crop').hide();
      return;
    }

    $(this.errorDisplayTarget).addClass('d-none')
    $('.smart_collect_product_container').addClass('editing')

    $('.smart_collect_product_cropping_container').html('<img src="" class="smart_collect_product_cropping" />')

    var reader = new FileReader();
    reader.onload = function(e) {

      $('.smart_collect_product_cropping').attr('src', e.target.result);
      // container.addClass('editing_picture')

      window.smart_collect_product = new Croppie($('.smart_collect_product_cropping').get(0), {
        enableExif: true,
        viewport: { width: 450, height: 260 },
        enforceBoundary: false
      })
    }
    reader.readAsDataURL(e.target.files[0]);
  }

  validPicture() {
    var result = smart_collect_product.result({type: 'base64',size: {width: 840, height: 485}, format: 'jpeg'})
    result.then(function(result){
      $('#product_smart_collect_product_attributes_picture_64').val(result)
      $('.smart_collect_product_container').removeClass('editing')
      $('.default_smart_collect_product img').attr('src', result)
      //$('.default_smart_collect_product p').text('Modifier')
      $('.smart-collect-product-pictures').removeClass('d-none')
    })
  }

  objectFitChanged(e) {
    $('.smart-collect-product-pictures__index').css('objectFit', $(e.currentTarget).val()) 
  }
}
