import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--tags-select"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    this.select = $(this.element).find('select')
    this.entityType = $(this.element).data().entity
    this.entityId = $(this.element).data().id

    $(this.element).find('select').on('select2:selecting',(e) => {
      this.select.prop('disabled', true)
      let data = e.params.args.data
      if(data.id == data.text) {
        e.preventDefault();
        this.createTag(data)
      } else {
        this.linkTag(data.id)
      }
    })

    $(this.element).find('select').on('select2:unselect',(e) => {
      this.select.prop('disabled', true)
      this.propagateRemoveVal(e.params.data.id)
      $.ajax({
        method: 'DELETE',
        url: '/back/tags/unlink',
        data: {id: e.params.data.id, entity_type: this.entityType, entity_ids: this.linkedEntityIds()}
      }).done(() => { this.select.prop('disabled', false) })
    })

  }

  createTag(data) {
    this.removeOption(data.id)
    $.ajax({
      method: 'POST',
      url: '/back/tags/create',
      data: {name: data.text, entity_type: this.entityType, entity_ids: this.linkedEntityIds()}
    }).done(res => {
      this.propagateCreateTag(res.tag)
      this.appendVal(res.tag.id)
      this.propagateAppendVal(res.tag.id)
    })
  }

  propagateCreateTag(tag) {
    $.each($('.tags_select_component'), (i, component) => {
      $(component).find('select').append(`<option value="${tag.id}" data-color="${tag.color}">${tag.label}</option>`)
    })
  }

  linkTag(tag_id) {
    $.ajax({
      method: 'POST',
      url: '/back/tags/link',
      data: {id: tag_id, entity_type: this.entityType, entity_ids: this.linkedEntityIds()}
    }).done(res => {
      this.propagateAppendVal(tag_id)
      this.select.prop('disabled', false)
    })
  }

  removeTag(uuid) {
    this.removeOption(uuid)
    if(this.select.find('option').length == 0 && this.select.hasClass('select2-hidden-accessible')) {
      this.select.select2('close');
    }
    this.select.trigger('update')
  }

  removeOption(val) {
    this.select.find('option').filter((i, o) => $(o).val() == val).remove()
  }

  appendVal(id) {
    let val = this.select.val()
    val.push(id)
    this.select.val(val)
    this.select.trigger('change')
    this.select.prop('disabled', false)
  }

  removeVal(id) {
    let val = this.select.val()
    val.splice(val.indexOf(id), 1)
    this.select.val(val)
    this.select.trigger('change')
    this.select.prop('disabled', false)
  }
}
