import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="first-configuration-form"
export default class extends Controller {
  connect() {
    window.horizontal_scrollbar = new PerfectScrollbar("#form_slider_header")
    this.navTab = $('#form_slider_header')
    $(this.element).validate({
      rules: {
        
        'freemium_configuration[shop_group_name]': {required: true},
        'freemium_configuration[shop_name]': {required: true},
        'freemium_configuration[address]': {required: true},
        'freemium_configuration[postCode]': {required: true, digits: true},
        'freemium_configuration[city]': {required: true},
        'freemium_configuration[alias]': {required: true},
        'freemium_configuration[phoneNumber]': {required: true},
        'freemium_configuration[siret]': {required: true},
        'freemium_configuration[legalForm]': {required: true},
        'freemium_configuration[capital]': {required: true, digits: true},
        'freemium_configuration[nafCode]': {required: true},
        'freemium_configuration[vatNumber]': {required: true},
        'freemium_configuration[rcs]': {required: true},
        'freemium_configuration[latePenaltyRates]': {required: true},
        'freemium_configuration[indemnityMention]': {required: true},
        'freemium_configuration[startDateFiscalYear]': {required: true},
        'freemium_configuration[accepts_term_of_use]': {required: true},
        'freemium_configuration[endDateFiscalYear]': {
          required: true,
          higherdate: '#freemium_configuration_startDateFiscalYear'
        },
        'freemium_configuration[email]': {
          required: true,
          remote: {
            param: {url: '/configuration/check_email', type: 'post'}
          },
        },
        'freemium_configuration[digits]': {
          exactlength: 4,
          number: true,
          required: true
        },
        'freemium_configuration[phoneNumber]': {
          required: true,
          regex: /^[\d+ ]*$/
        },
        'freemium_configuration[password]': {
          required: true,
          minlength: 6
        },
        'freemium_configuration[password_confirmation]': {
          required: true,
          equalTo: '#freemium_configuration_password'
        },
        'freemium_configuration[shop_password]': {
          required: true,
          notEqual: '#freemium_configuration_password',
          minlength: 6
        },
        'freemium_configuration[shop_password_confirmation]': {
          required: true,
          equalTo: '#freemium_configuration_shop_password'
        },
      },
      messages: {
        'freemium_configuration[password_confirmation]': {
          equalTo: 'Veuillez saisir le même mot de passe'
        },
        'freemium_configuration[email]': {
          remote: "Cette adresse email est déjà utlisée"
        },
        'freemium_configuration[shop_password_confirmation]': {
          equalTo: 'Veuillez saisir le même mot de passe'
        },
        'freemium_configuration[phoneNumber]': {
          regex: "Veuillez renseigner un numéro de téléphone valide"
        },
        'freemium_configuration[accepts_term_of_use]': {
          required: "Vous devez accepter les conditions d'utilisation"
        }
      }
    }).resetForm()
  }

  nextSlide() {
    if($(this.element).find('.configuration-slide.active input:visible').valid()) {
      let index = $('.configuration-slide.active').index()
      $('.configuration-slide.active').removeClass('active')
      $('.configuration-slide').eq(index + 1).addClass('active')
      $('.card-header .active').removeClass('active')
      $('.card-header .nav-item').eq(index + 1).addClass('active')
      this.navTab.scrollLeft(this.navTab.find('.active').offset().left - this.navTab.offset().left - 500)
    }
  }

  previousSlide() {
    let index = $('.configuration-slide.active').index()
    $('.configuration-slide.active').removeClass('active')
    $('.configuration-slide').eq(index - 1).addClass('active')
    $('.card-header .active').removeClass('active')
    $('.card-header .nav-item').eq(index - 1).addClass('active')    
  }

  submit(e) {
    if($(this.element).valid()) {
      $(e.currentTarget).prop('disabled', true)
      $('.btn.btn-primary').hide()
      $(this.element).submit()      
    }

  }
}
