import { Controller } from "@hotwired/stimulus"
import latinize from '../../shared/latinize';
import { initSelect2 } from 'shared/select2_init';
// Connects to data-controller="back--shop-feature-devices-list"
export default class extends Controller {
  connect() {
    initSelect2($('#modules-v2__main-nav select'))
    $(this.element).on('select2:select', '#modules-v2__main-nav select', (e) => {
      $(this.element).find('.tab-pane').removeClass('active')
      $(`#tab_module_shop_${$(e.currentTarget).val()}`).addClass('active')
    })

    this.first_visible = undefined
    $(document).on('scroll', () => {
      if($('.modules-v2__categories-nav__item').length > 0) {
        $.each($('.modules-v2__category-section:visible'), (index, element) => {
          if(($(element).offset().top - 100) > $(document).scrollTop()) {
            this.first_visible = $(element)
            return false
          }
        })
        $('.modules-v2__categories-nav__item:visible').removeClass('active')
        $(`#modules-v2__categories-nav__item-${$('.back_pricing_tab_pane:visible').data().localShopId}-${this.first_visible.data().category}`).addClass('active')        
      }
      
    })
  }
  
  searchModule(e) {
    let value = latinize($(e.currentTarget).val()).toLowerCase()
    if(value.length > 0) {
      $('.modules-v2__feature').each((i, feature) => {
        
        let feature_name = latinize($(feature).find('.modules-v2__feature__left-side__title-container').text().trim().replace(/(\r\n|\n|\r)/gm, "").toLowerCase())
        //console.log(feature_name)
        if(feature_name.includes('fidélité')) {
          
          console.log(value)
          console.log(feature_name)
        }
        $(feature).toggle(feature_name.indexOf(value) > -1)
      })
    } else {
      $('.modules-v2__feature').show()
      $('.modules-v2__feature').removeClass('module-v2__no-border')
      $('.modules-v2__category-section').show()
    }
    $('.modules-v2__category-section').each((i, category) => {
      $(category).toggle($(category).find('.modules-v2__feature:visible').length > 0)
      $(category).find('.modules-v2__feature:visible').removeClass('module-v2__no-border')
      $(category).find('.modules-v2__feature:visible').eq(-1).addClass('module-v2__no-border')
      
    })
  }

  goToCategory(e) {
    let element = $(`#modules-v2__category-section-${$('.back_pricing_tab_pane:visible').data().localShopId}-${$(e.currentTarget).data().category}`)
    let top = element.get(0).offsetTop + 32
    if($('#tab_back_api_modules').length) {
      top += $('#tab_back_api_modules').offset().top - $('#modules-v2__main-nav').innerHeight()
    }
    // + $('nav.navbar').get(0).offsetHeight + parseInt($('main').css('paddingTop'))
    window.scrollTo(0, top)
  }
}
