import { Controller } from "@hotwired/stimulus"
import { ExportXlsx } from '../shared/export_xlsx';
// Connects to data-controller="generic-xlsx-export"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
  }

  exportXlsx() {
    new ExportXlsx($(this.element).get(0).id, {}, $(this.element).data().fileName).render()
  }
}
