import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="back--toporder-ia-page-title"
export default class extends Controller {
  connect() {
    $(this.element).find('#toporder_ia_page_title_shop').on('select2:select',(e) => {
      this.reloadLinks()
      reloadDatas()
      if($('#toporder_ia_dashboard').length) {
        $('#toporder_ia_dashboard').get(0).controller.reloadCards()
        $('#toporder_ia_dashboard').get(0).controller.reloadEvents()
      }
    })
    $(this.element).find('#toporder_ia_page_title_start_date').on('select2:select',(e) => {
      this.reloadLinks()
      reloadDatas()
    })
    this.reloadLinks()
    
  }

  reloadLinks() {
    let params = [
      `startDate=${$('#toporder_ia_page_title_start_date').val().split('/').join('-')}`,
    ]
    if($('#toporder_ia_page_title_shop').length) {
      params.push(`shopId=${$('#toporder_ia_page_title_shop').val()}`)
    }

    if($('#toporder_table_prediction').length) {
      ajaxTurboStream(`/back/toporder_ia/predictions/load_table?${params.join('&')}&hidden=${$('#toporder_table_prediction_container').data().hidden}&mode=${$('#toporder_table_prediction_container').data().mode}`)
    }

    $.each($('#menu_toporder_ia a'), (i, a) => {
      $(a).attr('href', `${$(a).attr('href').split('?')[0]}?${params.join('&')}`)
    })    
  }
}
