import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init';
import { loadDatepicker } from 'shared/load_datepicker';

// Connects to data-controller="back--shop-hour-exceptional-fields"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.toggleClosing()
      this.hourTypeChanged()
      $(this.element).find('.turbo_datepicker').rules('add', { required: true})
      $(this.element).find('.shop_hour_hour_type_select').on('select2:select', () => {this.hourTypeChanged()})
    }, 1000)
  }

  removeShopHour() {
    $(this.element).find('.remove_exceptional_shop_hour').click()
  }

  toggleClosing() {
    $(this.element).find('.closing_hours_container').toggleClass('active', $(this.element).find('.shop_hour_closing_input').prop('checked'))
    initElementSelect2($(this.element).find('select:visible'))
  }  

  hourTypeChanged() {
    $(this.element).find('.shop_hour_hours').removeClass('active')
    $(this.element).find('.shop_hour_end_date_field').hide()
    $(this.element).find('.shop_hour_reason').hide()
    $(this.element).find('.configure_hours').hide()
    $(this.element).find('.shop_hour_closing_wrapper').hide()
    $(this.element).find('.shop_hour_begin_date .float-span').text('Date')
    $(this.element).find('.shop_hour_begin_date').show()

    let hourType = $(this.element).find('.shop_hour_hour_type_select').val()
    switch(hourType) {
      case 'exceptional_opening':
        $(this.element).find('.shop_hour_hours').addClass('active')
        $(this.element).find('.shop_hour_closing_wrapper').show()
        $(this.element).find('.configure_hours').show()
        $(this.element).find('.shop_hour_opened_checkbox').prop('checked', true)
        break;
      case 'exceptional_closing':
        $(this.element).find('.shop_hour_opened_checkbox').prop('checked', false)
        break;
      case 'exceptional_hour_change':
        $(this.element).find('.shop_hour_hours').addClass('active')
        $(this.element).find('.shop_hour_closing_wrapper').show()
        $(this.element).find('.configure_hours').show()
        $(this.element).find('.shop_hour_opened_checkbox').prop('checked', true)
        break;
      case 'long_closing':
        $(this.element).find('.shop_hour_end_date_field').show()
        $(this.element).find('.shop_hour_reason').show()
        $(this.element).find('.shop_hour_begin_date .float-span').text('Du')
        $(this.element).find('.shop_hour_opened_checkbox').prop('checked', false)
        break;
    }

    initElementSelect2($(this.element).find('select:visible'))
    setTimeout(() => {
      loadDatepicker($(this.element).find('.turbo_datepicker'))
    }, 500)
  }
}
