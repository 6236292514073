import { Controller } from "@hotwired/stimulus"
import { get, post } from "@rails/request.js"

// Connects to data-controller="admin--toporder-connector"
export default class extends Controller {

  push(e) {
    console.log('push')
    if($(e.currentTarget).data().key == '4') {
      if($('#toporder_connector_code').val().length == 0) {
        $('.issue_toporder_connector em').text("Un code est nécessaire pour lancer cette action")
      } else if ($('#toporder_connector_code').val() != '1992') {
        $('.issue_toporder_connector em').text("Le code fourni n'est pas correct")
      } else {
        $('.issue_toporder_connector em').text("")
        this.sendRequest(e)
      }
    } else {
      this.sendRequest(e)
    }

  }

  sendRequest(e) {
    $('#loading_app').addClass('d-flex')
    let data = $(e.currentTarget).data()
    let url = `/admin/toporder_connector/${data.route}_push?type=${data.key}&shop_id=${data.shop}`
    get(url, { responseKind: "turbo-stream" })    
  }
}
