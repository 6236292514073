import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--commercial-action-update-desired-signature-date"
export default class extends Controller {
  connect() {
    $('#commercial_action_desired_signature_date').removeClass('optional')
    $('#commercial_action_desired_signature_date').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
      var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
      $(".datepicker").css("top", top);
    });
  }
}
