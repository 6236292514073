import Franchisor__Statistics__StatisticsAbstractFilterController from "./statistics_abstract_filter_controller"
import { ExportXlsx } from '../../../shared/export_xlsx';
// Connects to data-controller="franchisor--statistics--statistics-products"
export default class extends Franchisor__Statistics__StatisticsAbstractFilterController {
  refresh(params) {
    $('#loading_app').addClass('d-flex')
    let url = `/franchisor/statistics/v2/products?${new URLSearchParams(params).toString()}`
    $.ajax({url: url, dataType: 'script'}).done(() => {
      $('#loading_app').removeClass('d-flex')  
    })
    
  }

  exportXlsx() {
    new ExportXlsx('stats_product_container_table_main', {}, 'export-statistics-products').render()
  }  

  showProduct(e) {
    initChartVar()
    let params = this.prepareParams()
    let data = $(e.currentTarget).data()
    params.measureSymbol = data.measureSymbol
    params.productUniqueId = data.productUniqueId
    params.productVariationUniqueId = data.productVariationUniqueId

    initModal()
    $('.modal-dialog').addClass('modal-xl')
    $('.modal-title').text(`
      Répartition des ventes de ${$(e.currentTarget).find('td').eq(0).text()} - ${$('#statistics-v2-filters__rangedatepicker-1 span').text().toLowerCase()}
    `)
    $('.modal-body').html(`
      <canvas class='stats_container' height=400 data-url="v2/products/affluence?${new URLSearchParams(params).toString()}" data-filter='.filter_card'></canvas>  
    `)
    
    $('.modal-footer').hide()
    $('#modal_rails_5').modal('show')
    var myInterval = setInterval(function(){
      if($('canvas.stats_container:visible').length) {
        loadCanvasStats($(".modal canvas"))
        clearInterval(myInterval);
        $('#loading_app').removeClass('d-flex')
      }
    }, 50);
  }  
}
