import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream, ajaxPatchTurboStream } from 'shared/ajax_turbo_stream'
// Connects to data-controller="back--group-selections-edit-contacts"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    $(this.element).find('#group-selection__filter-contact-type').on('select2:select', () => { this.reloadContacts() })
    setTimeout(() => {
      $(this.element).find('.product_index_search_container').removeClass('d-none')
    }, 1000)
    this.contacts = $(this.element).data().contactIds
    this.reloadContacts()
    this.displayContactsCount()
  }

  reloadContacts() {
    let url = '/back/group_selections/reload_contacts'
    let params = this.prepareParams()
    if(params.length) {
      url += `?${params}`
    }
    $('#group-selection__select-all').prop('checked', false)
    ajaxTurboStream(url, false)
  }

  submitForm() {
    ajaxPatchTurboStream(
      `/back/group_selections/${$(this.element).data().id}/patch_contacts`,
      {contacts: this.contacts}
    )
    
  }

  searchKeyup(event) {
    var timer = 0;
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        this.reloadContacts()
      }
    }, 500);
  }

  toggleCheckbox(e) {
    let contactId = $(e.currentTarget).data().contactId
    let checked = $(e.currentTarget).prop('checked')
    if(checked && !this.contacts.includes(contactId)) {
      this.contacts.push(contactId)
    } else if(this.contacts.includes(contactId)) {
      this.contacts.splice(this.contacts.indexOf(contactId), 1)
    }
    $(e.currentTarget).parents('label').eq(0).toggleClass('group-selection-contact__checked', checked)
    this.displayContactsCount()
  }

  toggleContacts() {
    $.each($('#group-selection__contacts-list input'), (i, contact) => {
      if(this.contacts.includes($(contact).data().contactId)) {
        $(contact).prop('checked', true)
      } else {
        $(contact).prop('checked', false)
      }
      $(contact).parents('label').eq(0).toggleClass('group-selection-contact__checked', $(contact).prop('checked'))
    })
  }

  prepareParams() {
    let params = {}
    if($('#group-selection__filter-contact-type').val() != '_') {
      params.OnlyContactType = $('#group-selection__filter-contact-type').val()
    }
    if($('#group-selection__filter-search').val().length > 0) {
      params.Keywords = $('#group-selection__filter-search').val()
    }
    params = new URLSearchParams(params).toString();
    return params
  }

  toggleAll() {
    if($('#group-selection__select-all').prop('checked')) {
      let url = '/back/group_selections/fetch_contact_ids'
      let params = this.prepareParams()
      if(params.length) {
        url += `?${params}`
      }
      $('#loading_app').addClass('d-flex')
      $.get(url).done((data) => {
        $.each(data.ids, (i, id) => {
          if(!this.contacts.includes(id)) {
            this.contacts.push(id)
          }
        })
        $('#loading_app').removeClass('d-flex')
        this.toggleContacts()
        this.displayContactsCount()
      })
    } else {
      this.contacts = []
      this.toggleContacts()
      this.displayContactsCount()
    }
  }

  displayContactsCount() {
    let words = [0, 1].includes(this.contacts.length) ? 'client sélectionné' : 'clients sélectionnés'
    $('#group-selection__contacts-count').text(`${this.contacts.length} ${words}`)
  }
}
