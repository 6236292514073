import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--equipment-stock-movement"
export default class extends Controller {
  changePage(e) {
    const url = new URL(window.location.href.split("?")[0])
    url.searchParams.append('startDate', $(e.currentTarget).data().date)
    ajaxTurboStream(url, true)
  }
}
