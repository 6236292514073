import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--my-pos-make-requests-form"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    $(this.element).find('.datepicke').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
      var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
      $(".datepicker").css("top", top);
    });
    $(this.element).on('submit', () => {
      $('.modal-content').removeClass('closing-alert')
      $('.custommodalception').hide()
    })

    $(this.element).validate({
      rules: {
        'my_pos_make_request[comment]': {
          maxlength: 100
        }
      }
    })

    $(this.element).find('#my_pos_make_request_comment').on('keyup', (e) => {
      let commentLength = $('#my_pos_make_request_comment').val().length
      $('#my-pos-make-request__comment-count').text(`${commentLength} / 100`)
      $('#my-pos-make-request__comment-count').toggleClass('text-toporder', commentLength > 100)
    })
  }

  submitForm() {
    if($(this.element).valid()) {
      $('.modal-content').addClass('closing-alert')
      $('.custommodalception .modalception-footer').html(`
        <div class="btn btn-outline-primary" onClick="cancelClosingModal()">Annuler</div>
        <button onClick="$('#new_my_pos_make_request').find('input[type=submit]').click()" class='btn btn-primary'>Confirmer</button>
      `)
      $('.modalception-body').html(`
        <p>Confirmez-vous la création d'un prélèvement pour ce revenu ?</p>
      `)
      $('.custommodalception').slideDown(400)      
    }

  }
}
