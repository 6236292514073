import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--smart-collect-vitrine-thumbnail-form"
export default class extends Controller {
  connect() {
    $(this.element).validate({
      rules: {
        'smart_collect_vitrine_thumbnail[name]': {
          required: true
        },
        'smart_collect_vitrine_thumbnail[content]': {
          required: true
        },
        'smart_collect_vitrine_thumbnail[picture]': {
          required:true,
          extension: "png|jpg|jpeg"
        }
      },
      messages: { 
        'smart_collect_vitrine_thumbnail[picture]': {
          extension: "L'image doit être au format suivant : png, jpg ou jpeg"
        }
      }
    })
  }
}
