import { Controller } from "@hotwired/stimulus"
import { updateSelect2 } from 'shared/select2_init'; 
// Connects to data-controller="back--promotion-update-shop-form"
export default class extends Controller {
  connect() {
  }

  selectAllShops() {
    $('#promotion_update_shop_shop_ids').val($(this.element).data().shops)
    updateSelect2($('#promotion_update_shop_shop_ids'))
  }
}
