import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--income-prepare-delivery"
export default class extends Controller {
  connect() {
    this.valrhonaCustomerChanged()
  }

  valrhonaCustomerChanged() {
    if($(this.element).find('.prepare_valrhona_free_input').prop('checked') || $(this.element).find('.prepare_income_locam_change').prop('checked')) {
      $(this.element).find('.prepare_annual_invoice_container').addClass('d-none')
      $(this.element).find('.prepare_annual_invoice_container input').prop('checked', false)  
    } else {
      $(this.element).find('.prepare_annual_invoice_container').removeClass('d-none')    
    }    
  }
}
