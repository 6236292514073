export function initModal() {
  $('.modal-content').removeClass('closing-alert')
  $('.modal-declinations').remove()
  $('.modal-body').removeClass('p-0 declination_modal_main no-modal-scroll d-block')
  $('.modal-body').show()
  $('.modal-dialog').removeClass('modal-lg modal-xl full-height')
  $('.modal-header').show()
  $('.modal-footer').show()
  $('.modal-body').css('overflowY', 'scroll')
  $('.modal-header').removeClass('sw-main sw-theme-default')
  $('.modal-header').html(`
    <h5 class="modal-title"></h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
  `)
  $('.modal-footer').html(`
    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Annuler</button>
    <button id="modal-action" type="button" class="btn btn-primary" onClick="submitModalForm(this)">Modifier</button>
  `)

  $('.custommodalception, .modalception').hide()


  $('.custommodalception .modalception-footer').html(`
    <div class="btn btn-outline-primary" onClick="cancelClosingModal()">Annuler</div>
    <div class="btn btn-primary" onClick="confirmClosingModal()">Continuer</div>
  `)  
}

export function initTurboModal() {
  $('#modal').html(`
    <div class="modal fade" data-controller="turbo-modal" style="padding-right: 15px; display: block;" aria-modal="true">
      <div class="modal-dialog modal-dialog-centered ">
        <turbo-frame class="modal-content" id="modal_content">
          <div class="modal-header">
            <h5 class="modal-title">Modification du type</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body" data-controller="modal-content">

          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Annuler</button>
              <button id="modal-action" type="button" class="btn btn-primary" data-action="turbo-modal#submitForm">Modifier</button>
          </div>
          <div id="loading_modal" class="">
            <div class="loading_circle"></div>
          </div>
        </turbo-frame>  
      </div>
      <div class="modalception">
        <p>Si vous fermez cette fenêtre, toutes les modifications seront perdues</p>
        <p class="mb-3">Êtes-vous sûr de vouloir continuer ?</p>
        <div class="d-flex justify-content-between">
          <div class="btn btn-primary" onclick="cancelClosingModal()">Annuler</div>
          <div class="btn btn-primary" onclick="confirmClosingModal()">Continuer</div>
        </div>
      </div>

      <div class="custommodalception">
        <div class="modalception-body">

        </div>
        <div class="d-flex justify-content-between modalception-footer"></div>
      </div>
    </div>
  `)
}


export function updatingTurboModal(message) {
  $('#modal').html(`
    <div class="modal fade" data-controller="turbo-modal" style="padding-right: 15px;" aria-modal="true">
      <div class='modal-dialog modal-dialog-centered'>
        <div class='modal-content'>
          <div class='modal-body'>
            <div class="loading_modal">
              ${message}
              <div class="loading_circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  `)
}