import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="unit-form"
export default class extends Controller {
  connect() {
    $(this.element).validate({
      rules: {
        'unit[name]': {
          required: true,
          uniqsymbol: $(this.element).data().names
        },
        'unit[symbol]': {
          required: true,
          rangelength: [1, 4],
          uniqsymbol: $(this.element).data().codes

        }
      },
      messages: {
        'unit[name]': {
          uniqsymbol: "Ce nom est déjà utilisé"
        }
      }
    })
  }
}
