import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catalog--import-catalogs-new"
export default class extends Controller {
  connect() {
    $(this.element).find('input').on('change', (e) => {
      if($(e.currentTarget).data().key == 'group_attribut') {
        e.preventDefault()
        $(e.currentTarget).prop('checked', !$(e.currentTarget).prop('checked'))
      }
      if($(e.currentTarget).data().key == 'attribut') {
        $('#import_catalog_column_group_attribut').prop('checked', $(e.currentTarget).prop('checked'))
      }
      if($(e.currentTarget).data().key == 'externalId_product_variation') {
        e.preventDefault()
        $(e.currentTarget).prop('checked', !$(e.currentTarget).prop('checked'))
      }
      if($(e.currentTarget).data().key == 'externalId_product') {
        $('#import_catalog_column_externalId_product_variation').prop('checked', $(e.currentTarget).prop('checked'))
      }
      if($(e.currentTarget).data().key == 'groupSelection_list') {
        e.preventDefault()
        $(e.currentTarget).prop('checked', !$(e.currentTarget).prop('checked'))
      }
      if($(e.currentTarget).data().key == 'groupSelection_prices') {
        $('#import_catalog_column_groupSelection_list').prop('checked', $(e.currentTarget).prop('checked'))
      }
    })
  }
}
