import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--tags-trash"
export default class extends Controller {
  click(e) {
    $('#loading_app').addClass('d-flex')
    let id = $(e.currentTarget).parent().get(0).id
    let uuid = id.substr(id.length - 36)
    this.propagateDelete(uuid)
    let currentTarget = $(e.currentTarget)

    $.ajax({method: 'DELETE', url: `/back/tags/delete_tag/${uuid}`}).done(() => {
      $('#loading_app').removeClass('d-flex')
      currentTarget.parent().remove()
    })
  }

  mouseenter(e) {
    $(e.currentTarget).parent().removeClass('select2-results__option--highlighted select2-results__option')
  }

  mouseleave(e) {
    $(e.currentTarget).parent().addClass('select2-results__option--highlighted select2-results__option')
  }

  propagateDelete(uuid) {
    $.each($('.tags_select_component'), (i, select) => {
      select.controller.removeTag(uuid)
    })
  }
}
