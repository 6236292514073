import { Controller } from "@hotwired/stimulus"
import { staticMapUrl } from 'static-google-map';
import { styles } from '../shared/map_style';
// Connects to data-controller="arbo-shop-map"
export default class extends Controller {
  connect() {

    $.get('/franchisor/load_map_dashboard').done((data) => {

      const map = new google.maps.Map(document.getElementById("arbo-shop-map"), {
        zoom: 5,
        center: {lat: 46.2276, lng: 2.2137},
        disableDefaultUI: true,
        styles: styles
      });
      $.each(data, (index, hash) => {
        new google.maps.Marker({
          position: {lat: hash.lat, lng: hash.lng},
          map: map,
          icon: {
            url: '/pin_marker.svg',
            scaledSize: new google.maps.Size(20, 20)
          }
        })
      })
      return;
      const url = staticMapUrl({
        key: 'AIzaSyCnjBaQKdDvc4IbWKAAngOMxrYU2o_nQpw',
        size: `${$(this.element).width()}x300`,
        format: 'png',
        maptype: 'roadmap',
        center: 'France',
        zoom: 5,
        markers: markers,
      })
      $('#arbo-shop-map').attr('src', `${url}&scale=2&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels%7Cvisibility:off&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative%7Celement:geometry%7Cvisibility:off&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:poi%7Cvisibility:off&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Cvisibility:off&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit%7Cvisibility:off&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e`)
    })
  }
}
