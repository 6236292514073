import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="admin--notification"
export default class extends Controller {
  connect() {
    this.loaded = false
  }

  toggle() {
    if ($('#notificationButton .count:visible').length) {
      $('#notificationButton .count').hide()
      $.ajax({
        url: '/admin/notifications/notifications_seen'
      })
    }
    if(!this.loaded) {
      ajaxTurboStream('/admin/notifications/load_notifications')
    }

  }
}
