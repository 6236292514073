import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accounting--local-shop-accounting-codes-setting"
export default class extends Controller {
  connect() {
  }

  updateField(e) {
    updatingModal("Votre réglage est en cours de mise à jour.")
    $.ajax({
      method: 'PUT',
      url: '/accounting/local_shop_accounting_codes',
      data: {
        shop_id: $(e.currentTarget).parents('div').eq(0).data().shopId,
        field: $(e.currentTarget).data().field,
        key: $(e.currentTarget).data().key ? $(e.currentTarget).data().key : '',
        value: $(e.currentTarget).attr('type') == 'checkbox' ? $(e.currentTarget).prop('checked') : $(e.currentTarget).val()
      }
    }).done(() => {
      setTimeout(() => {
        $('.modal').unbind('hide.bs.modal')
        $('.modal').modal('hide')
        flashMessage('', `Le réglage a bien été mis à jour`, 'success')
        $('#loading_app').removeClass('d-flex')
      }, 1000)
    })
  }

  selectShop(e) {
    $('.local_shop_accounting_code_shop_container').removeClass('active')
    $(`#local_shop_accounting_code_${$(e.currentTarget).data().shopId}`).addClass('active')
    $(e.currentTarget).parents('.d-flex').eq(0).find('.name').text($(e.currentTarget).text())
  }
}