import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream'
// Connects to data-controller="back--toporder-ia-predictions-products"
export default class extends Controller {
  connect() {
  }

  showAll() {
    $(this.element).data('hidden', false)
    $(this.element).find('tr.d-none').removeClass('d-none')
    $('#toporder_ia_predictions_show_products').remove()
  }

  selectMode(e) {
    if(!$(e.currentTarget).hasClass('active')) {
      $('#toporder_table_prediction_select_mode .active').removeClass('active')
      $(e.currentTarget).addClass('active')
      $('#toporder_table_prediction_container').data('mode', $(e.currentTarget).data().mode)
      this.reloadProducts()
    }
  }

  reloadProducts() {
    let params = [
      `startDate=${$('#toporder_ia_page_title_start_date').val().split('/').join('-')}`,
    ]
    if($('#toporder_ia_page_title_shop').length) {
      params.push(`shopId=${$('#toporder_ia_page_title_shop').val()}`)
    }

    if($('#toporder_table_prediction').length) {
      ajaxTurboStream(`/back/toporder_ia/predictions/load_table?${params.join('&')}&hidden=${$('#toporder_table_prediction_container').data().hidden}&mode=${$('#toporder_table_prediction_container').data().mode}`)
    }    
  }
}
