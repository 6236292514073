import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--catalog-favorites-index"
export default class extends Controller {
  connect() {
    $(this.element).on('select2:select', '.catalog_favorite_select_shop', (e) => {
      $(this.element).find('.tab-pane').removeClass('active')
      $(`#tab_favorite_shop_${$(e.currentTarget).val()}`).addClass('active')
    })
  }
}
