import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--scale-product-metadata"
export default class extends Controller {

  connect() {
    this.element['controller'] = this
    setTimeout(() => {
      if($(this.element).data().attributes.scaleDeviceType) {
        $('.scale-product-metadatas-actions').addClass('existing-precia-metadata')
        this.buildForm($(this.element).data().attributes.scaleDeviceType)
      }
    }, 500) 

  }

  buildForm(scale_device_type) {
    this.scale_device_type = scale_device_type
    $(this.element).append(`
      <input class='d-none' name="product[scale_product_metadatas_attributes][${scale_device_type}][scale_device_type]" value="${scale_device_type}" />
    `)
    switch (scale_device_type) {
      case 4:
        this.buildPrecia()
        break;
    }
    //product[product_price_units_attributes][0][price]
  }

  buildPrecia() {
    let labels = [
      'Valeur énergétique',
      'Protéines',
      'Glucides',
      'Sucres',
      'Lipides',
      'Acides gras saturés',
      'Fibres alimentaires',
      'Sel',
      'Calcium',
      'Fer',
      'Vitamines A',
      'Vitamines C',
      'Magnésium',
      'Potassium'
    ]
    $('#scale-product-metadata__title').text('Informations pour Precia')
    Array.from({ length: 6 }, (_, i) => {
      this.buildInput(`[text${i+1}]`, `Text ${i + 1}`, $(this.element).data().attributes[`field${i + 1}`], 24)
    })
    this.buildInput('[mainText]', "Texte principal", $(this.element).data().attributes['field7'], 250)
    Array.from({ length: 14 }, (_, i) => {
      this.buildInput(`[nutritional${i+1}]`, labels[i], $(this.element).data().attributes[`field${i + 8}`], 30)
    });
  }

  buildInput(name, label, value, maxlength) {
    $(this.element).append(`
      <div class='d-flex mb-5 align-items-center scale-product-metadata__input-container'>
        <div class="form-label-group string form-group-valid mb-0" data-max-length="${maxlength}">
          <label class="has-float-label">
            <input class="form-control string form-control-line" type="text" 
              name="product[scale_product_metadatas_attributes][${this.scale_device_type}]${name}"
              value="${value ? value : ''}"
              data-action="keyup->back--scale-product-metadata#updateCount"
              data-max-length="${maxlength}"
            >
            <span class="float-span">${label}</span>
          </label>
        </div>
        <p class='mb-0 ml-2 scale-product-metadata__chars-count'>${value ? value.length : 0} / ${maxlength}</p>
      </div>
    `)
    $(this.element).find('input').eq(-1).rules('add', {maxlength: maxlength})
  }

  updateCount(e) {
    let parent = $(e.currentTarget).parents('.scale-product-metadata__input-container')
    let maxlength = $(e.currentTarget).data().maxLength
    parent.find('.scale-product-metadata__chars-count').text(`${$(e.currentTarget).val().length} / ${maxlength}`)
    parent.find('.scale-product-metadata__chars-count').toggleClass('text-toporder', $(e.currentTarget).val().length > maxlength)
  }
}
