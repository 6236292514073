import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--smart-collect-product-group-attributes-order"
export default class extends Controller {
  connect() {
    $(this.element).sortable({
      axis: 'y',
      containment: 'parent',
      cursor: 'move',
      handle: '.smart-collect-product-group-attributes-order-container__grab',
      items: '.smart-collect-product-group-attributes-order-container__line',
      distance: 10,
      tolerance: 'pointer',
      deactivate: function(event, ui) {
        var array_position = []
        $.each($(ui.sender).find('input.smart_collect_entity_order'), function(index, element) {
          $(element).val(index)
        })
      }
    })
  }
}
