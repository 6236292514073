import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="admin--equipment-stocks--index-filter"
export default class extends Controller {
  static targets = ['status', 'equipment_ids', 'month', 'provider']
  filter() {
    ajaxTurboStream(this.buildUrl(new URL(window.location.href.split("?")[0])), true)
  }

  download() {
    window.location = this.buildUrl(new URL(window.location.href.split("?")[0] + '/download'))
  }

  buildUrl(url) {
    if($(this.statusTarget).val() != '_' && $(this.statusTarget).val() !== null) {
      url.searchParams.append("status", $(this.statusTarget).val())
    }
    if($(this.equipment_idsTarget).val().length > 0) {
      url.searchParams.append("equipment_ids", $(this.equipment_idsTarget).val())
    }

    if($(this.monthTarget).val() != '_' && $(this.monthTarget).val() !== null) {
      url.searchParams.append("month", $(this.monthTarget).val())
    }

    if($(this.providerTarget).val() != '_' && $(this.providerTarget).val() !== null) {
      url.searchParams.append("provider", $(this.providerTarget).val())
    }
    return url.toString()
  }
}
