import { Controller } from "@hotwired/stimulus"
import { put, destroy } from "@rails/request.js"
import { flashMessage } from 'shared/notify';

// Connects to data-controller="admin--equipment-stocks--index-row"
export default class extends Controller {
  connect() {
    this.id = $(this.element).data().id
    this.formatter = Intl.NumberFormat('fr', {style: 'currency', currency: 'EUR'})
  }

  editDate(e) {
    let cell = $(e.currentTarget)
    let current_date = cell.text()
    cell.after(`
      <input class='form-control'
        value="${cell.text()}" data-action="change->admin--equipment-stocks--index-row#updateDate"
      />
    `)
    cell.hide()
    cell.next().datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
      var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
      $(".datepicker").css("top", top);
    }).on('hide', (i) => {
      setTimeout(() => {
        if($(i.currentTarget).val() != current_date) {
          cell.text($(i.currentTarget).val())
          put(`/admin/equipment_stocks/${this.id}/update_field`, {body: {field: 'date', value: $(i.currentTarget).val()}})
          flashMessage('', `La date a bien été modifiée !`, 'success')
        }
        cell.next().remove()
        cell.show()
      }, 200)
    })
    $(e.currentTarget).next().focus()
  }

  editPrice(e) {
    let cell = $(e.currentTarget)
    cell.hide()
    cell.after(`
      <label class="has-float-label input-group">
        <input class="form-control numeric optional" type="number" step="any" value="">
        <div class="input-group-append" data-action="click->admin--equipment-stocks--index-row#cancelEdit">
          <span class="input-group-text clickable"><i class="fas fa-times"></i></span>
        </div>
        <div class="input-group-append" data-action="click->admin--equipment-stocks--index-row#validatePrice">
          <span class="input-group-text clickable"><i class="fas fa-check"></i></span>
        </div>
      </label>
    `)
    cell.next().find('input').focus()
  }

  cancelEdit(e) {
    $(e.currentTarget).parents('td').find('span').show()
    $(e.currentTarget).parents('td').find('label').remove()
  }

  validatePrice(e) {
    let price = parseFloat($(e.currentTarget).parent().find('input').val().replace(',','.'))
    let previous_price = parseFloat($(e.currentTarget).parents('td').find('span').text().trim().replace(',','.'))
    if(!isNaN(price) && previous_price != price) {
      $(e.currentTarget).parents('td').find('span').eq(0).text(this.formatter.format(price))
      put(`/admin/equipment_stocks/${this.id}/update_field`, {body: {field: 'price', value: price}})
      flashMessage('', `Le prix d'achat bien été modifié !`, 'success')
    }
    this.cancelEdit(e)
  }

  editSerialNumber(e) {
    let cell = $(e.currentTarget)
    cell.hide()
    cell.after(`
      <label class="has-float-label input-group">
        <input class="form-control optional" value="">
        <div class="input-group-append"  data-action="click->admin--equipment-stocks--index-row#cancelEdit">
          <span class="input-group-text clickable"><i class="fas fa-times"></i></span>
        </div>
        <div class="input-group-append" data-action="click->admin--equipment-stocks--index-row#validateSerialNumber">
          <span class="input-group-text clickable"><i class="fas fa-check"></i></span>
        </div>
      </label>
    `)
    cell.next().find('input').focus()
  }

  validateSerialNumber(e) {
    let input_val = $(e.currentTarget).parents('td').find('input').val().trim()
    if(input_val.length > 0 && $(e.currentTarget).parents('td').find('> span').text() != input_val) {
      $(e.currentTarget).parents('td').find('> span').text(input_val)
      put(`/admin/equipment_stocks/${this.id}/update_field`, {body: {field: 'serial_number', value: input_val}})
      flashMessage('', `Le numéro de série a bien été modifié !`, 'success')
    }
    this.cancelEdit(e)
  }

  toggleBroken(e) {
    e.stopPropagation()
    let broken = $(e.currentTarget).prop('checked')
    put(`/admin/equipment_stocks/${this.id}/update_field`, {body: {field: 'broken', value: broken}})
  }

  destroy(e) {
    destroy(`/admin/equipment_stocks/${this.id}`)
    $(this.element).addClass('d-none')
  }
}
