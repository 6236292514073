import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--export--stock-broken"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      $('.stock_export_date_container').html(`
        <div class="form-label-group string optional">
          <label class="has-float-label">
            <input class="form-control string optional form-control-line datepicke" type="text" value="${$(this.element).data().date}" id="export_remove_stock_movement_begin">
            <span class="float-span">Du</span>
          </label>
        </div> 
        <div class="form-label-group string optional">
          <label class="has-float-label">
            <input class="form-control string optional form-control-line datepicke" type="text" value="${$(this.element).data().date}" id="export_remove_stock_movement_end">
            <span class="float-span">au</span>
          </label>
        </div>   
      `)
      $(this.element).find('input').datepicker({
        orientation: 'bottom',
        autoclose: true,
      }).on('show', (e) => {
        $('.datepicker-dropdown').css({
          left: '50%',
          top: '50%',
          transform: 'translateX(-50%) translateY(-50%)'
        })
      }).on('change', (e) => {
        let date = $(this.element).find('input').val().split('/').join('-')
        let begin_date = $('#export_remove_stock_movement_begin').val().split('/').join('-')
        let end_date = $('#export_remove_stock_movement_end').val().split('/').join('-')
        $('.export_stock_link').eq(0).attr('href', `/back/export/stock_broken?begin_date=${begin_date}&end_date=${end_date}`)
      })      
    }, 500)

    $(this.element).find('a').on('click', () => {
      $('.modal-body').html('Votre fichier est en cours de préparation. Cela peut prendre un peu de temps. Vous pourrez fermer cette fenêtre à la fin du téléchargement')
      $('.modal-footer').html(`
        <p class='btn btn-outline-primary' onClick="location.reload()">J'ai reçu mon fichier</p>
      `)      
    })

  }
}