import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accounting--z-tickets-index"
export default class extends Controller {
  connect() {
    $(this.element).find('.z_ticket_select_shop').on('select2:select', (e) => {
      if($(e.currentTarget).val() && $(e.currentTarget).val() != '_') {
        Turbo.visit(`/accounting/shop_group/${$(this.element).data().shopGroupId}?shop_id=${$(e.currentTarget).val()}`)
      }
    })    
  }
}
