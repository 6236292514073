import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--onboarding-modal"
export default class extends Controller {
  connect() {


    $(this.element).html(`
      <div id="onboarding_welcome">
        <p id="onboarding_title">
          Découvrez les fondamentaux de votre caisse en 2 minutes.️ 
        </p>

        <p id="onboarding_comment">
          Voyons ensemble les bases d’utilisation de votre caisse avec des vidéos de 30 secondes.
        </p>
        <img src="/onboarding.png">

        <div class="onboarding_welcome_next_step">
          <div class='btn btn-primary' data-action="click->back--onboarding-modal#nextStep">C'est parti !</div>
        </div>
      </div>
    `)
  }

  nextStep() {
    let width = $('.modal-body').width() * 0.80
    let height = width * 0.5625
    this.currentSlide = 0
    this.videoIds = [
      `<iframe width="${width}" height="${height}" src="https://www.youtube.com/embed/oavMtUWDBTM?start=15&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      `<iframe width="${width}" height="${height}" src="https://www.youtube.com/embed/dQw4w9WgXcQ?controls=0&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
    ]
    $(this.element).html(`
      <div id="onboarding_main">
        <div id="onboarding_carousel">
          <div class='onboarding_carousel_control' data-action="click->back--onboarding-modal#prevSlide">
            <i class="fas fa-chevron-left"></i>
          </div>
          <div id="onboarding_carousel_content"></div>
          <div class='onboarding_carousel_control' data-action="click->back--onboarding-modal#nextSlide">
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
        <div class="onboarding_welcome_next_step">
          <div class='btn btn-primary' data-action="click->back--onboarding-modal#closeOnboarding">Je suis prêt</div>
        </div>
      </div>
    `)
    this.showSlide()
  }

  nextSlide() {
    if(this.currentSlide < (this.videoIds.length - 1)) {
      this.currentSlide += 1
    } else {
      this.currentSlide = 0
    }
    console.log(this.currentSlide)
    this.showSlide()
  }

  prevSlide() {
    if(this.currentSlide == 0) {
      this.currentSlide = this.videoIds.length - 1
    } else {
      this.currentSlide -= 1
    }
    this.showSlide()
  }

  showSlide() {
    $('#onboarding_carousel_content').html(this.videoIds[this.currentSlide])
  }

  closeOnboarding() {
    $('.modal-body').html('')
    $('.modal').modal('hide')
  }
}
