import Utils__NestedFormController from "controllers/utils/nested_form_controller"
import { Controller } from "@hotwired/stimulus"
import { classAgregator } from 'shared/class_agregator'

// Connects to data-controller="admin--commercial-action-types-form"
export default class extends classAgregator(Controller, Utils__NestedFormController) {
  static targets = ['templateCommercialActionTypeResult', 'contentCommercialActionTypeResult', 'templateCommercialActionSubType', 'contentCommercialActionSubType']
  connect() {
    this.checkResults()
    this.initSortableResult()
    this.initSortableSub()
    this.checkItinerable()
    this.checkTimeable()
  }

  callbackAfterAddCommercialActionTypeResult(element, options) { 
    this.checkResults()
    this.updateOrderResult()
  }
  callbackBeforeRemoveCommercialActionTypeResult(element) {
    return new Promise((resolve, reject) => { resolve() })
  }
  callbackAfterRemoveCommercialActionTypeResult() {
    this.checkResults()
  }

  checkResults() {
    $('.commercial_action_type_accomplish_prospect_commercial_step_id').parents('fieldset').eq(0).toggle($('.commercial_action_type_result_nested_field:visible').length == 0)
  }

  checkItinerable() {
    $('.commercial_action_type_commercial_action_sub_types_itinerable').toggle($('#commercial_action_type_itinerable').prop('checked'))
  }

  checkTimeable() {
    if($('#commercial_action_type_timeable').prop('checked')) {
      $('.commercial_action_type_duration').toggle($('.commercial_action_sub_type_fields:visible').length == 0)
      $('.commercial_action_type_commercial_action_sub_types_duration').show()
    } else {
      $('.commercial_action_type_duration').hide()
      $('.commercial_action_type_commercial_action_sub_types_duration').hide()
    }
  }

  callbackAfterAddCommercialActionSubType(element, options) { 
    this.updateOrderSub()
    this.checkTimeable()
  }
  callbackBeforeRemoveCommercialActionSubType(element) {
    return new Promise((resolve, reject) => { resolve() })
  }
  callbackAfterRemoveCommercialActionSubType() {
    this.checkTimeable()
  }

  updateOrderResult() {
    $.each($('.commercial_action_type_result_nested_field').not('.deleted'), function(index, element) {
      $(element).find('.commercial_action_type_commercial_action_type_results_order input').val(index + 1)
    })
  }

  updateOrderSub() {
    $.each($('.commercial_action_sub_type_fields').not('.deleted'), function(index, element) {
      $(element).find('.commercial_action_type_commercial_action_sub_types_order input').val(index + 1)
    })
  }

  initSortableResult() {
    $('.commercial_action_type_form_commercial_action_type_result_container').sortable({
      axis: 'y',
      containment: 'parent',
      cursor: 'move',
      handle: '.fa-grip-vertical',
      items: '.commercial_action_type_result_nested_field',
      tolerance: 'pointer',
      deactivate: (event, ui) => {
        this.updateOrderResult()
      }
    })
  }

  initSortableSub() {
    $('.commercial_action_type_form_commercial_action_sub_type_container').sortable({
      axis: 'y',
      containment: 'parent',
      cursor: 'move',
      handle: '.fa-grip-vertical',
      items: '.commercial_action_sub_type_fields',
      tolerance: 'pointer',
      deactivate: (event, ui) => {
        this.updateOrderSub()
      }
    })
  }
}
