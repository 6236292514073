import { Controller } from "@hotwired/stimulus"
import { initModal } from 'shared/modal';
// Connects to data-controller="back--onboarding"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      initModal()
      $('.modal-dialog').addClass('modal-xl')
      $('.modal-footer').hide()
      $('.modal-body').addClass('p-0')
      $('.modal-header').hide()
      $('.modal-body').css('overflowY', 'hidden')

      $('.modal').modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })

      setTimeout(() => {
        $('.modal-body').html('<div data-controller="back--onboarding-modal" id="onboarding_modal"></div>')
      }, 500)


    }, 1000)

  }
}
