import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="back--statistics--employees"
export default class extends Controller {
  static targets = ['dateRange', 'shop']
  connect() {
    this.initDateRangePicker()
    $(this.element).find('select').on('select2:select', () => this.reload())
  }

  initDateRangePicker() {
    let datas = $(this.element).data()
    this.startDate = moment(datas.current.split('-')[0], 'DD/MM/YYYY').format('DD-MM-YYYY')
    this.endDate = moment(datas.current.split('-')[1], 'DD/MM/YYYY').format('DD-MM-YYYY')
    let ranges = {
      "Les 7 derniers jours": [moment(datas.current.split('-')[0], 'DD/MM/YYYY'), moment(datas.current.split('-')[1], 'DD/MM/YYYY')],
      "L'année en cours": [moment(datas.year.split('-')[0], 'DD/MM/YYYY'), moment(datas.year.split('-')[1], 'DD/MM/YYYY')],
      "De tout temps": [moment(datas.all.split('-')[0], 'DD/MM/YYYY'), moment(datas.all.split('-')[1], 'DD/MM/YYYY')],
    }
    $(this.dateRangeTarget).daterangepicker({
      parentEl: ".filter_card",
      ranges: ranges,
      startDate: this.startDate,
      endDate: this.endDate
    }, (start, end, picker) => {
      if(picker == "Personnalisé") {
        $(this.dateRangeTarget).find('span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      } else {
        $(this.dateRangeTarget).find('span').text(picker)
      }
      this.startDate = start.format('DD-MM-YYYY')
      this.endDate = end.format('DD-MM-YYYY')
      this.reload()
    })    
  }

  reload() {
    var params = [
      `startDate=${this.startDate}`,
      `endDate=${this.endDate}`
    ]
    if(this.hasShopTarget && $(this.shopTarget).val() != 'all') {
      params.push(`shop=${$(this.shopTarget).val()}`)
    }

    ajaxTurboStream(`/back/statistics/v${window.location.pathname.includes('v2') ? '2' : '1'}/employees/reload_table?${params.join('&')}`)
  }
}
