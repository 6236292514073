import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--stock-broken-types-form"
export default class extends Controller {
  connect() {
    console.log($(this.element).data().names)
    $(this.element).validate({
      rules: {
        'stock_broken_type[name]': {
          required: true,
          uniqsymbol: $(this.element).data().names
        },
      },
      messages: {
        'stock_broken_type[name]': {
          uniqsymbol: "Ce nom est déjà utilisé"
        }
      }
    })
  }
}
