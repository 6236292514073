import { Controller } from "@hotwired/stimulus"
import { MarkerClusterer } from "@googlemaps/markerclusterer";
// Connects to data-controller="admin--prospects--map"
export default class extends Controller {
  connect() {
    const lat = gon.lat
    const lng = gon.lng
    const map = new google.maps.Map(document.getElementById("map_prospect"), {
      zoom: 7,
      center: {lat: lat, lng: lng},
    });
    var prev_window = false
    var markers =  gon.prospects.map((prospect, i) => {
      var contentString = `
        <div id="content">
          <a href="/admin/prospects/${prospect.id}/edit"><h3>${prospect.project_name}</h3></h3></a>
          <p class='mb-0'>${prospect.address}</p>
          <a href="https://www.google.com/maps/search/?api=1&query=${prospect.lat}%2C${prospect.lng}">Voir sur Google Maps</a>
        </div>
      `
      const marker = new google.maps.Marker({
        position: {lat: prospect.lat, lng: prospect.lng},
        map,
      })
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 200
      });
      marker.addListener('click', function () {
        if(prev_window) {
          prev_window.close();
        }
        prev_window = infowindow
        infowindow.open(marker.get('map'), marker);
      });
      return marker
    });

    new MarkerClusterer({ markers, map });
  }

  filter() {
    var params = []

    if($('#prospect_origin').length && $('#prospect_origin').val() != '_' && $('#prospect_origin').val() !== null) {
      params.push(`prospect_origin_id=${$('#prospect_origin').val()}`)
    }
    if($('#prospect_commercial').val() != '_' && $('#prospect_commercial').val() !== null) {
      params.push(`toporder_employee_id=${$('#prospect_commercial').val()}`)
    }
    if($('#prospect_filter_commercial_step').val() != '_' && $('#prospect_filter_commercial_step').val() !== null) {
      params.push(`prospect_commercial_step_id=${$('#prospect_filter_commercial_step').val()}`)
    }
    if($('#prospect_business_category').length && $('#prospect_business_category').val() != '_') {
      params.push(`api_business_category_uuid=${$('#prospect_business_category').val()}`)
    }
    if($('#prospect_filter_only_without_action').prop('checked')) {
      params.push('onlyWithoutAction=true')
    }    
    if($('#prospect_filter_zip_code').val().length) {
      params.push(`zip_code=${$('#prospect_filter_zip_code').val()}`)
    }    
    //console.log(`${window.location.href.split('?')[0]}${params.length ? '?' + params : ''}`)
    Turbo.visit(`${window.location.href.split('?')[0]}${params.length ? '?' + params.join('&') : ''}`)
  }  
}
