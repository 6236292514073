import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--equipment-stocks--ressupply"
export default class extends Controller {
  connect() {
    $('.equipment_stock_purchase_order_form').validate()
    this.update_serial_numbers()
    this.addSerialNumberRule()
  }

  update_serial_numbers() {
    if($('#ressupply_equipment_no_serial_number').prop('checked')) { return false;}
    let quantity = $('#equipment_stock_purchase_order_quantity').val().length ? parseInt($('#equipment_stock_purchase_order_quantity').val()) : 0
    let delta = quantity - $('.ressupply_equipment_serial_numbers_after').length
    if(delta > 0) {
      for(var i=0;i < delta;i++){
        $("#serial_numbers_container").append(`
          <div class="form-label-group form-group-valid mb-3 ressupply_equipment_serial_numbers_after w-100">
            <label class="has-float-label input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">${$('.ressupply_equipment_serial_numbers_after').length + 1}</span>
              </div>
              <input class="form-control is-valid optional" type="text" 
                data-action="change->admin--equipment-stocks--ressupply#serialNumberChanged"
                name="equipment_stock_purchase_order[serial_numbers][${$('.ressupply_equipment_serial_numbers_after').length + 1}][]"
              >

            </label>
          </div>
        `)
      }
    } else {
      for(var i=0; i < Math.abs(delta); i++) {
        $('.ressupply_equipment_serial_numbers_after').eq(-1).remove()
      }
    }
    $('#serial_numbers_container > label').toggleClass('d-none', $('.ressupply_equipment_serial_numbers_after').length == 0)
    this.addSerialNumberRule()
  }

  addSerialNumberRule() {
    $.each($('.ressupply_equipment_serial_numbers_after input'), (i, e) => {
      console.log(i)
      $(e).rules('add', {
        equipment_stock_purchase_orders_duplicate_serial_number: true
      })
    })    
  }

  remove_serials_numbers(e){
    if ($(e.currentTarget).prop('checked')){ $('.ressupply_equipment_serial_numbers_after').remove() }
    this.update_serial_numbers()
  }

  serialNumberChanged() {
    $(this.element).valid()
  }
}
