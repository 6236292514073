import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="franchisor--franchise-owner-form"
export default class extends Controller {
  connect() {
    let existing_email
    if($('#franchise_owner_contact_email').val().length > 0) {
      existing_email = $('#franchise_owner_contact_email').val()
    }
    $(this.element).validate({
      onkeyup: false,
      rules: {
        'franchise_owner[contact_email]': {
          required: true,
          remote: {
            param: {url: '/franchisor/franchise_owners/check_email', type: 'post', data: {existing_email: existing_email}}
          }
        }
      },
      messages: {
        'franchise_owner[contact_email]': {
          remote: "Cet email est déjà utilisé"
        }
      }
    })
  }
}
