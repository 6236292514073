import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--term-of-use-form"
export default class extends Controller {
  connect() {
    $.validator.addMethod("uniqversion", function(value, element, params) {
      console.log('bouh')
     return !params.includes(value)
    }, $.validator.format("La version est déjà utilisée."));
    let url = $(this.element).data().id ? `/admin/term_of_uses/fetch_existing_versions?id=${$(this.element).data().id}` : `/admin/term_of_uses/fetch_existing_versions`
    $.get(url).done(data => {
      console.log(data)

      $(this.element).validate({
        rules: {
          'term_of_use[versionNumber]': {
            required: true,
            uniqversion: data
          }          
        }

      })
    })
  }
}
