import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--cron-tasks-status-index"
export default class extends Controller {
  launchTask(e) {
    $.get(`/cron_task/execute?master_key=${$(this.element).data().masterKey}&cron_key=${$(e.currentTarget).data().key}`).done(() => {
      location.reload()
    })
  }
}
