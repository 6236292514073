import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream'
// Connects to data-controller="back--ia-prediction-products"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    if($('#ia_prediction_products_select_shop').length) {
      this.toggleSelect()
      $(this.element).find('#ia_prediction_products_select_shop').on('select2:select', () => {
        this.toggleSelect()
      })
    }
    this.reloadFilters()
    $(this.element).find('#ia_prediction_products_filter_type').on('select2:select', () => {
      this.reloadFilters()
    })
  }

  reloadFilters() {
    let prediction_type = $('#ia_prediction_products_filter_type').val()
    switch (prediction_type) {
      case 'week':
        $('#ia_prediction_products_filter_date input').val(moment().format('DD/MM/YYYY'))
        $('#ia_prediction_products_filter_date').removeClass('d-none')
        break;
      case 'tomorrow':
        $('#ia_prediction_products_filter_date').addClass('d-none')
        $('#ia_prediction_products_filter_date input').val('')
        break;
      case 'date':
        $('#ia_prediction_products_filter_date').removeClass('d-none')
        $('#ia_prediction_products_filter_date input').val(moment().format('DD/MM/YYYY'))
        break;
    }
    
  }

  toggleSelect() {
    $(this.element).find('.tab-pane.active').removeClass('active')
    if($('#ia_prediction_products_select_shop').val() == '_') {
      $(this.element).find('#tab_ia_prediction_products_').addClass('active')
    } else {
      $(this.element).find(`#tab_ia_prediction_products_${$('#ia_prediction_products_select_shop').val()}`).addClass('active')
    }
    this.reload()
  }

  reload() {
    let shopId
    if($('.turbo_nav').length) {
      shopId = $('.turbo_nav .active').eq(0).data().shopId
    } else if($('#ia_prediction_products_select_shop').val() != '_') {
      shopId = $('#ia_prediction_products_select_shop').val()
    }
    let body = {
      shopId: shopId,
      predictionType: $('#ia_prediction_products_filter_type').val(),
      date: $('#ia_prediction_products_filter_date input').val()
    }

    ajaxPostTurboStream('/back/statistics/ia_prediction_products/reload', body)
  }
}
