import { Controller } from "@hotwired/stimulus"
import { animateValueCurrency } from '../shared/animate_value_currency.js';
// Connects to data-controller="animated-card-value"
export default class extends Controller {
  connect() {
    animateValueCurrency(
      $(this.element).find('span'),
      parseFloat($(this.element).data().value),
      $(this.element).data().symbol,
      $(this.element).data().currency
    )
  }
}
