import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--listing--basket-item-lines-modal"
export default class extends Controller {
  cancel() {
    $('#listing_basket_item_lines_view').removeClass('updating_basket_item_line')
    $('#modal_rails_5').modal('hide')
  }

  confirm(e) {
    $(`#listing_basket_item_line_${$(e.currentTarget).data().id}`).get(0).controller.confirmAction($(e.currentTarget).data().type)
  }
}
