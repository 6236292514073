import 'select2';

export function initSelect2(elements) {
  $(elements).hide()
  setTimeout(() => {
    $(elements).show()
    let placeholder = {
      'menuCategory': "Sélectionnez la ou les catégories de ce menu",
      'productId': "Sélectionnez le ou les produits de cette sélection d'articles",
      'productCategory': 'Sélectionnez la ou les catégories de ce produit'
    }
    if($(elements).length == 0) { return; }
    $.each($(elements), function(index, element) {
      if($(element).hasClass("select2-hidden-accessible")) { return; }
      if ($(element).prop('multiple') == true) {
        initElementSelect2Multiple($(element), placeholder)
      } else {
        initElementSelect2Single($(element), placeholder)
      }
      if ($(element).parents('.form-label-group').length && $(element).parents('.form-label-group').data().load) {
        fetchElementSelect2Data($(element), $(element).parents('.form-label-group').data())
      }
    })
  }, 1000)

}

function initElementSelect2Multiple(element, placeholder) {
  element.select2({
    placeholder: placeholder[element.get(0).id.split('_')[element.get(0).id.split('_').length - 1]],
    tags: element.data().tags ? true : false,
    templateResult: element.data().tags ? formatResultTag : formatResult,
    language: {
      noResults: () => { return "Aucun résultat trouvé"; }
    },
  })
}

function initElementSelect2Single(element, placeholder) {
  element.select2({
    theme: "bootstrap",
    dir: "bottom",
    maximumSelectionSize: 6,
    placeholder: '',
    allowClear: true,
    containerCssClass: ":all:",
    language: {
      noResults: function() {
        return "Aucun résultat trouvé";
      }
    },
  });
}

function fetchElementSelect2Data(element, parent) {
  element.parents('.form-label-group').hide()
  element.empty().trigger("change");
  $.ajax({
    url: parent.load,
    data: {model: parent.model, selected: parent.selected},
    type: 'POST',
    success: function(data) {
      if(parent.model == 'productCategory') {
        window.category_colors = {}
      }
      $.each(data['data'], function(index, object) {
        element.append(new Option(object.text, object.id, false, object.selected))
        if(parent.model == 'productCategory') {
          window.category_colors[object.id] = object.color
        }
      })
      element.parents('.form-label-group').show()
      if($(`#${element.get(0).id}_loading`).length) {
        $(`#${element.get(0).id}_loading`).hide()
      }
    }
  })
}

function formatResult(node) {
  return $('<span style="padding-left:' + (20 * node.level) + 'px;">' + node.text + '</span>');
};

function formatResultTag(node) {
  if($(node.element).data()) {
    return $(`
      <span>${node.text}</span>
      <i class="fas fa-circle select2-custom_color_tag d-none" style='color: ${$(node.element).data().color};'></i>
      <i class="fas fa-trash select2-custom_remove_tag"
        data-controller="back--tags-trash"
        data-action="click->back--tags-trash#click mouseenter->back--tags-trash#mouseenter mouseleave->back--tags-trash#mouseleave"
      ></i>
    `)
  } else {
    return $(`
      <span>${node.text}</span>
    `)
  }

}

export function updateSelect2(element) {
  if(element.data().select2) {
    try {
      element.select2('destroy')
      initElementSelect2(element);
    } catch (e) {
    }
  } else {
    initElementSelect2(element);
  }
}
