import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catalog--product-categories--index"
export default class extends Controller {  
  connect() {
    this.element['controller'] = this
    this.initSortable()
    setTimeout(() => {
      this.loaded = true
    })
  }

  reload() {
    if(this.loaded) { 
      this.initSortable();
    }
  }

  initSortable() {
    $(this.element).sortable({
      axis: 'y',
      containment: 'parent',
      cursor: 'move',
      handle: '> .card > .d-flex > .card-body > .grab_product_category_order',
      items: '> .sub-card-container',
      distance: 10,
      tolerance: 'pointer',
      deactivate: function(event, ui) {
        $(ui.item).addClass('sortable_moving')
        var array_position = []
        $.each($(ui.sender).find('> .sub-card-container'), function(index, element) {
          array_position.push({
            productCategoryId: $(element).data().id,
            order: index
          })
        })
        $.ajax({
          url: `/${$('body').data().context}/product_categories/update_positions`,
          type: "POST",
          data: {positions: array_position}
        })
      }
    })
  }
}
