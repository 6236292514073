import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--payments-form"
export default class extends Controller {
  connect() {
    this.checkAllowExcess()
  }

  checkAllowExcess() {
    if($('#payment_availableForCreditNote').prop('checked') || $('#payment_givingChange').prop('checked')) {
      $('#payment_allowExcess').prop('disabled', true)
      $('#payment_allowExcess').prop('checked', false)
    } else {
      $('#payment_allowExcess').prop('disabled', false)
    }
  }
}
