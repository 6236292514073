import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--discounts-form"
export default class extends Controller {
  connect() {
    this.checkRestrictedUsage()
  }

  checkRestrictedUsage() {
    if($('#discount_restrictedUsage').prop('checked')) {
      $('.discount_employeeId').show()
    } else {
      $('.discount_employeeId input').prop('checked', false)  
      $('.discount_employeeId').hide()
    }
  }
}
