import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--export-v2"
export default class extends Controller {
  export() {
    let url = `${$(this.element).data().endpoint}/xlsx/${$('#export_v2_select_type').val()}/`
    if($('#export_v2_select_shop').val() != '_') {
      url += `shop/${$('#export_v2_select_shop').val()}`
    } else {
      url += `shopgroup/${$(this.element).data().shopgroup}`
    }
    window.location = url
  }
}
