import { Controller } from "@hotwired/stimulus"
import { animateValueCurrency } from '../../../../shared/animate_value_currency.js';
// Connects to data-controller="back--statistics--v2--statistics-general-cards"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    this.reloadData()
  }

  reloadData() {
    let filter = $('.filter_card').data()
    $('.cards_stats_main').addClass('loading_stats_card')
    $.ajax({
      url: `/${window.location.pathname.split('/')[1]}/statistics/v2/dashboard/cards_stats`,
      data: {dates: filter.dates, shops: filter.shops, ca: filter.ca, group_orders: filter.grouporders, business: filter.business, meta: filter.meta, type: filter.type, sourceOrderType: filter.sourceOrderType, tags: filter.tags, contact_type: filter.contactType},
      success: (datas) => {
        $('.cards_stats_main').removeClass('loading_stats_card')
        console.log(datas)
        this.datas = datas
        this.displayDatas()
      }
    })
  }

  displayDatas() {
    let displayedDatas = this.datas[$('.filter_sum_or_average.active').attr('id') ]
    animateValueCurrency($('#total_ca_summary'), displayedDatas["turnover"], '', true)
    animateValueCurrency($('#total_ca_summary_prev'), displayedDatas["turnover_before"], '', true)
    animateValueCurrency($('#total_clients_summary'), parseInt(displayedDatas["number_of_customer"]), '', false)
    animateValueCurrency($('#total_clients_summary_prev'), parseInt(displayedDatas["number_of_customer_before"]), '', false)
    animateValueCurrency($('#total_basket_summary'), displayedDatas["average_basket"], '', true)
    animateValueCurrency($('#total_basket_summary_prev'), displayedDatas["average_basket_before"], '', true)
    animateValueCurrency($('#total_margin_summary'), displayedDatas["margin"], '', true)
    animateValueCurrency($('#total_margin_summary_prev'), displayedDatas["margin_before"], '', true)          
    console.log('bouh', displayedDatas)
    $('.stats_cards_stats_info_v2').text(`
      Statistiques générées ${this.datas.messageGroupOrder} en ${this.datas.computeDuration / 1000} secondes ${this.datas.useCacheQuery ? "avec" : "sans"} cache
    `)
  }
}
