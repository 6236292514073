import Back__Statistics__V2__StatisticsAbstractFilterController from "./statistics_abstract_filter_controller"

// Connects to data-controller="back--statistics--v2--statistics-product-categories"
export default class extends Back__Statistics__V2__StatisticsAbstractFilterController {
  refresh(params) {
    console.log("reloadDatas")
    if($('.stats_page_item_v2.active').length) {
      this.loadCategory($('.stats_page_item_v2.active'))
    }
  }

  selectCategory(e) {
    if(!$(e.currentTarget).hasClass('active')) {
      $('#loading_app').addClass('d-flex')
      $('.stats_page_row .list .stats_page_item_v2.active').removeClass('active')
      $(e.currentTarget).addClass('active')
      this.loadCategory($(e.currentTarget)) 
    }
  }

  loadCategory(element) {
    $.ajax({
      url: `${element.data().url}?${new URLSearchParams(this.prepareParams()).toString()}`
    })
  }
}
