import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--commercial-actions-itinerary-edit-commercial-action"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
  }

  submitForm() {
    
    let body = {
      duration: $('#commercial_action_duration').val(),
      hour: $('#commercial_action_hour').val(),
      minutes: $('#commercial_action_minutes').val()
    }
    $('#loading_app').addClass('d-flex')
    $.ajax({
      url: `/admin/commercial_actions/${$(this.element).data().id}/update_field`,
      method: 'PUT',
      data: body
    }).done(() => {
      $('#loading_app').removeClass('d-flex')
      $('#modal .modal').modal('hide')
      $('#commercial_action_itinerary_controller').get(0).controller.reloadItinerary()
    })
  }
}
