import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--stock--recipe--recipe-step-field"
export default class extends Controller {
  connect() {
    this.color = ['#28a745', '#ffc107', '#17a2b8']
    this.labels = ['Temps de préparation', 'Temps de cuisson', 'Temps de repos']
    this.step_type = $(this.element).find('select').val()
    $(this.element).find('select').on('select2:select', function() {this.dispatchEvent(new Event('change', {bubbles: true}))})
    this.set_ux()
  }
  type_changed(e) {
    $(this.element).css('borderLeftColor', this.color[$(e.currentTarget).val()])
    this.set_ux()
  }

  set_ux() {
    this.step_type = parseInt($(this.element).find('select').val())
    $(this.element).css('borderLeftColor', this.color[this.step_type])
    $(this.element).find('.recipe_recipe_steps_duration .float-span').text(this.labels[this.step_type])
    $(this.element).find('.recipe_recipe_steps_work_duration').toggle(this.step_type == 1)
  }
}
