import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--statistics--v2--statistics-products-table"
export default class extends Controller {
  connect() {
    $(this.element).find('table').DataTable({
      "pageLength": 100000,
      'order': [],
      'scrollX': true,
      "caseInsensitive": true
    })
  }
}
