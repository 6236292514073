import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--top-order-pay-fee-fields"
export default class extends Controller {
  connect() {
  }

  fixedFeesChanged(e) {
    $(this.element).find('.toporder_pay_configuration_top_order_pay_fees_feesAmount .input-group-text').text($(e.currentTarget).prop('checked') ? "€" : "%")
  }
}
