import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--stock--product-stock--ingredient-traceability"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      $('.traceability_choose_date input').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
        var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top"));
        $(".datepicker").css("top", 300);
      }).on('changeDate', function(e) {
        Turbo.visit(`${window.location.href}?date=${$('.traceability_choose_date input').val()}`)
      })
      $('.traceability_choose_date input').datepicker('show')
      $('.traceability_choose_date input').data('datepicker').hide = function () {};       
    })
   
  }
}
