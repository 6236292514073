import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--prospect-conversion-rates"
export default class extends Controller {
  upsert(e) {
    $.ajax({
      method: "PUT",
      url: '/admin/prospect_conversion_rates',
      data: {type: $(e.currentTarget).data().type, step: $(e.currentTarget).data().step, rate: $(e.currentTarget).val()}
    })
  }
}
