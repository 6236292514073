import { Controller } from "@hotwired/stimulus"
//import { loadTinyMce } from 'shared/load_tinymce';
// Connects to data-controller="admin--contract-templates"
export default class extends Controller {
/*  connect() {
    this.tinymce = loadTinyMce()
  }

  addWord(e){
    var insert_value = '{{' + $(e.currentTarget).data().label + '}}'
    this.tinymce.activeEditor.execCommand('mceInsertContent', false, insert_value); //important
  }*/
}
