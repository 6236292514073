import { Controller } from "@hotwired/stimulus"
import { initModal } from 'shared/modal';

// Connects to data-controller="back--stock--ingredient--ingredient-stock-movement-index"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    $(this.element).on('click', 'td', (e) => {
      if($(e.currentTarget).data().date) {
        this.click_cell(e)  
      }
    })
  }

  click_cell(e) {
    var cell = $(e.currentTarget)

    this.active_cell = cell
    initModal()
    let data = this.active_cell.data()
    $.get(`/back/ingredient_stock_movements/new?date=${data.date}&ingredient_id=${data.ingredientId}&stock_id=${data.stockId}`, () => {
      if((this.active_cell.parent().index() + 1) < $('.dataTables_scrollBody tbody:visible tr').length) {
        $('.modal-footer').append(`<button id="modal-action" type="button" class="btn btn-primary" onClick="$('.tab-content:visible').get(0).ingredientstock.next(true)">Suivant</button>`)
      }
    })
  }

  next(next_cell) {
    this.working_cell = this.active_cell
    $('#loading_app').addClass('d-flex')
    this.send($('form').serializeHash())
    if(next_cell) {
      let row = this.active_cell.parent().index()
      let col = this.active_cell.index()
      $('tbody:visible tr').eq(row + 1).find('td').eq(col).click()
    } else {
      $('.modal').modal('hide')
    }

  }

  build_cell(movement) {
    let html = `
      <p class="stock_suply ${movement.quantity == 0 && movement.supply_mode == 0 ? 'd-none' : ''}">
        <i class="fas ${movement.supply_mode == 0 ? 'fa-sign-in-alt' : 'fa-calculator'} text-success"></i>
        <span>${movement.quantity}</span>
      </p>
      <p class="stock_broken ${!movement.broken || movement.broken == 0 ? 'd-none' : ''}">
        <i class="fas fa-trash text-danger"></i>
        <span>${movement.broken}</span>
      </p>
    `
    $(`#${movement.cell_id}`).html(html)
    $(`#${movement.cell_id}`).data('stockId', movement.id)

  }

  send(form) {
    if(form['ingredient_stock_movement[quantity]'].length == 0) {
      return false;
    }

    $.ajax({
      type: 'POST',
      url: '/back/ingredient_stock_movements',
      data: {
        quantity: form['ingredient_stock_movement[quantity]'],
        ingredient_id: this.working_cell.data().ingredientId,
        date: this.working_cell.data().date,
        price: form['ingredient_stock_movement[price]'],
        price_line: form['ingredient_stock_movement[price_line]'],
        reference: form['ingredient_stock_movement[reference]'],
        supplier_id: form['ingredient_stock_movement[supplier_id]'],
        supply_mode: form['ingredient_stock_movement[supply_mode]'],
        stock_id: this.working_cell.data().stockId,
        suppliable_type: this.working_cell.parents('table').data().suppliableType,
        suppliable_id: this.working_cell.parents('table').data().suppliableId
      }
    }).done((data) => {
      $('#loading_app').removeClass('d-flex')
      this.build_cell(data.movement)
    })
  }
}
