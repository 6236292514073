import { Controller } from "@hotwired/stimulus"
import Utils__NestedFormController from "controllers/utils/nested_form_controller"
import { classAgregator } from 'shared/class_agregator'

// Connects to data-controller="back--supplier-purchase-order"
export default class extends classAgregator(Controller, Utils__NestedFormController) {
  static targets = [
    'IngredientSelect',
    'ProductStockSelect',
    'stockableContainer',
    'templateSupplierPurchaseOrderLine'
  ]

  connect() {
    this.element['controller'] = this
    $(this.element).validate()

    if($(this.element).data().purchasabletype == 'Warehouse') {
      this.datepicker = $('#supplier_purchase_order_date').datepicker({
        autoclose: true,
        startDate: "<%= Date.today.to_s %>"
      })
    } else {
      this.datepicker = $('#supplier_purchase_order_date').datepicker({
        autoclose: true,
      })      
    }
    this.datepicker.on("show", function(e) {
      var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
      $(".datepicker").css("top", top);
    });

    $.ajax({
      url: `/back/supplier_purchase_orders/fetch_form_datas?purchasable_id=${$('.supplier_purchase_order_form').data().purchasableid}&purchasable_type=${$('.supplier_purchase_order_form').data().purchasabletype}`,
      method: 'get',
      dataType: "json",
      success: (response) => {
        this.stockable_data = response.data
        this.populate()
        this.loadStockable()
        this.checkCategories()
        this.balanceLines()
        this.calc_total()
      }
    })
  }

  populate() {
    for(const stockable of ['Ingredient', 'ProductStock']) {
      if(Object.keys(this.stockable_data[stockable]).length) {
        $(this.stockableContainerTarget).append(`
          <div class="d-flex mb-3 supplier_purchase_orders_stockable_container">
            <div class="form-label-group select optional supplier_purchase_order_product_stock_id">
              <select class="form-control select optional select_prevent_remove" data-back--supplier-purchase-order-target='${stockable}Select'>
                ${this.populateStockable(stockable)}
              </select>
            </div>
            <p class="btn btn-primary" data-stockable="${stockable}" data-action="click->back--supplier-purchase-order#addStockable">
              Ajouter ${stockable == 'Ingredient' ? 'cet ingrédient' : 'ce produit'}
            </p>
          </div>
        `)
      }
    }
    initElementSelect2($(this.stockableContainerTarget).find('select'))
  }

  balanceLines() {
    $.each($('#supplier-purchase-order-line-balancing tr'), (index, line) => {
      let stockable_type = $(line).find('.supplier_purchase_order_line_stockable_type').val()
      let stockable_id = $(line).find('.supplier_purchase_order_line_stockable_id').val()
      let stockable_data = this.stockable_data[stockable_type][stockable_id]

      let target_id = "#supplier-purchase-order-category-body--"
      if(stockable_data.favorite) {
        target_id += 'favs'
      } else {
        target_id += stockable_data.category_key
      }
      $(line).detach().appendTo(target_id)
    })
    this.checkCategories()
  }

  populateStockable(stockable_key) {
    let html = ''
    let to_populate = []
    for(const stockable in this.stockable_data[stockable_key]) {
      if(stockable_key == 'Ingredient' || this.stockable_data[stockable_key][stockable].not_archived) {
        to_populate.push({stockable: stockable, name: this.stockable_data[stockable_key][stockable].name})
      }
      
    }
    to_populate.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    }).forEach((element) => {
      html += `<option value='${element.stockable}'>${element.name}</option>`
    })
    return html
  }

  addStockable(e) {
    let element = $(e.currentTarget)
    element.hide()
    let stockable = $(e.currentTarget).data().stockable
    let select = $(this[`${stockable}SelectTarget`])
    let stockable_data = this.stockable_data[stockable][select.val()]

    let params = new URLSearchParams({
      suppliable_id: $('#supplier_purchase_order_suppliable_id').val(),
      suppliable_type: $('#supplier_purchase_order_suppliable_type').val(),
      purchasable_type: $('#supplier_purchase_order_purchasable_type').val(),
      purchasable_id: $('#supplier_purchase_order_purchasable_id').val(),
      stockable_type: stockable,
      stockable_id: select.val()
    }).toString()

    $.ajax({
      url: `/back/supplier_purchase_orders/stockable_informations?${params}`,
      method: 'get',
      dataType: "json",

    }).done(data => {
      let target_id = "#supplier-purchase-order-category-body--"
      if(stockable_data.favorite) {
        target_id += 'favs'
      } else {
        target_id += stockable_data.category_key
      }

      let fields = {
        stockable_id: select.val(),
        stockable_type: stockable,
        name: select.find(':selected').text(),
        price_ht: data.price,
        unit_symbol: $(this.element).data().units[stockable_data.referent_unit],
        remaining_permanent: data.remaining_permanent,
        previous_buying_ht: data.price,
        select: select,
        element: element
      }
      if(data.remaining_permanent_warehouse) {
        fields.remaining_permanent_warehouse = data.remaining_permanent_warehouse
      }  
      this.addAssociation(
        'SupplierPurchaseOrderLine',
        fields,
        $(target_id).get(0)
      )
    })

  }

  checkCategories() {
    $.each($('.supplier-purchase-order-category-container'), (i, e) => {
      $(e).toggle($(e).find('.supplier_purchase_order_line_fields').not('.deleted').length > 0)
    })
  }

  callbackAfterAddSupplierPurchaseOrderLine(element, options) { 
    element.find('.supplier_purchase_order_line_stockable_id').val(options.stockable_id)
    element.find('.supplier_purchase_order_line_stockable_type').val(options.stockable_type)
    element.find('.supplier_purchase_order_line_name').text(options.name)
    element.find('.supplier_purchase_order_supplier_purchase_order_lines_unit_price_ht input').val(options.price_ht)
    element.find('.supplier_purchase_order_line_unit_symbol').text(options.unit_symbol)
    element.find('.supplier_purchase_order_line_remaining_permanent').text(options.remaining_permanent)
    element.find('.supplier_purchase_order_supplier_purchase_order_lines_previous_buying_price_ht input').val(options.price_ht)
    options.select.find(':selected').prop('disabled', true)
    options.element.show()
    this.toggleStockableButton(options.select)
    this.checkCategories()
  }

  callbackBeforeRemoveSupplierPurchaseOrderLine(element) {
    return new Promise((resolve, reject) => { 
      let select = $(this[`${element.find('.supplier_purchase_order_line_stockable_type').val()}SelectTarget`])
      select.find(`option[value="${element.find('.supplier_purchase_order_line_stockable_id').val()}"]`).prop('disabled', false)
      this.toggleStockableButton(select)
      resolve() 
    })
  }

  callbackAfterRemoveSupplierPurchaseOrderLine() {
    // TODO
    this.calc_total()
    this.checkCategories()
  }

  toggleStockableButton(select) {
    if(select.length == 0) { return }
    select.val(select.find('option').not(':disabled').eq(0).val())
    select.parents('.supplier_purchase_orders_stockable_container').toggleClass('d-flex', select.find('option').not(':disabled').length > 0)

    updateSelect2(select)
    select.trigger('change')
  }

  loadStockable() {
    $.each($('.supplier_purchase_order_line_fields'), (index, element) => {
      let stockable_id = $(element).find('.supplier_purchase_order_line_stockable_id').val()
      let stockable_type = $(element).find('.supplier_purchase_order_line_stockable_type').val()
      $(element).find('.supplier_purchase_order_line_unit_symbol').text($(this.element).data().units[this.stockable_data[stockable_type][stockable_id].referent_unit])
      $(element).find('.supplier_purchase_order_line_name').text(this.stockable_data[stockable_type][stockable_id].name)
      $(this[`${stockable_type}SelectTarget`]).find(`option[value="${stockable_id}"]`).prop('disabled', true)
    })
    for(const stockable of ['Ingredient', 'ProductStock']) {
      if(Object.keys(this.stockable_data[stockable]).length) {
        this.toggleStockableButton($(this[`${stockable}SelectTarget`]))
      }
    }
  }



  calc_total() {
    this.total = 0
    $.each($('.supplier_purchase_order_supplier_purchase_order_lines_buying_price_ht:visible'), (index, element) => {
      this.total += parseFloat($(element).find('input').val())
    })
    $('.supplier_purchase_order_total').text(this.total.toFixed(2))
    $('#supplier_purchase_order_totalHT').val(this.total.toFixed(2))
    if($('#franco_de_port_remaining_form').length) {
      let franco = parseFloat($('#franco_de_port_remaining_form').data().franco) - this.total
      if(franco > 0) {
        $('#franco_de_port_remaining_form').text(franco)
      } else {
        $('#franco_de_port_remaining_form').text(0)
      }
    }
  }
}
