import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--group-selections-edit-contacts-footer"
export default class extends Controller {
  connect() {
  }

  toggleAll() {
    //$('#group-selection__select-all').prop('checked', !$('#group-selection__select-all').prop('checked'))
    $('#group-selection__edit-contacts').get(0).controller.toggleAll()
  }

  submitForm() {
    $('#group-selection__edit-contacts').get(0).controller.submitForm()
  }
}
