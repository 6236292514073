import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
import { loadDatepicker } from 'shared/load_datepicker';
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="utils--turbo-nav"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    this.key = $(this.element).data().key
    this.initNav()
    $(this.element).find('li').on('click', (e) => {
      if(!$(e.currentTarget).data().target) { return }
      if($(this.element).data().validation && this.currentTab.find(':input').length) {        
        if(!this.currentTab.find(':input').valid()) {
          return
        }
      }
      if(!$(e.currentTarget).hasClass('active')) {
        this.activeTab($(e.currentTarget))
        this.updateUrl($(e.currentTarget))
      }
    })
  }

  initNav() {
    let params = new URLSearchParams(window.location.search).get(this.key)
    let element;
    if(params) {
      if($(this.element).find('li').filter((e, i) => i.dataset.target == params).length) {
        element = $(this.element).find('li').filter((e, i) => i.dataset.target == params).eq(0)
      } else {
        element = $(this.element).find('li').eq(0)
      }
    } else {
      element = $(this.element).find('li').filter((e, i) => i.dataset.target).eq(0)
    }
    this.activeTab(element)
    this.updateUrl(element)
  }

  activeTab(element) {
    if(this.currentNav.length) {
      this.currentTab.removeClass('active')
      this.currentNav.removeClass('active')
    }

    element.addClass('active')
    this.currentTab.addClass('active')
    this.toggleCallback()
    initSelect2($('select:visible'))
    loadDatepicker($('.turbo_datepicker:visible'))


    if(this.currentNav.data().href && this.currentTab.html().length == 0) {
      ajaxTurboStream(this.currentNav.data().href, false)
    }
  }

  toggleCallback(e) { }

  updateUrl(element) {
    let params = new URLSearchParams(window.location.search)
    params.set(this.key, element.data().target)
    if(!$(this.element).data().noSave)
    history.replaceState(null, null, `${window.location.href.split('?')[0]}?${params.toString()}`.replace(/&amp;/g, "&"))
  }

  get currentNav() {
    return $(this.element).find('.active')
  }

  get currentTab() {
    return $(`#${this.currentNav.data().target}`)
  }
}
