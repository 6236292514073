import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from '../../shared/select2_init';
// Connects to data-controller="back--update-catalog-prices-form"
export default class extends Controller {
  connect() {
    $(this.element).find('#update_catalog_price_modifications_update_price').on('select2:select',() => {
      this.checkUpdatePriceSelect()
    })
    $(this.element).find('#update_catalog_price_modifications_update_price_method').on('select2:select',() => {
      this.checkUpdatePriceMethodSelect()
    })
    this.checkUpdatePriceSelect()
    this.checkUpdatePriceMethodSelect()
    this.checkDeleteAll()
    $(this.element).validate({
      rules: {
        'update_catalog_price[filters][categories][]' : {
          required: false
        },
        'update_catalog_price[filters][cards][]': {
          required: false
        },
        'update_catalog_price[modifications][cards][]': {
          required: false
        }
      }
    })
  }

  checkUpdatePriceSelect() {
    $('.update_catalog_price_modifications_update_price_options').toggleClass(
      'active',
      $('#update_catalog_price_modifications_update_price').val() != '_'
    )

    initSelect2($('#update_catalog_price_modifications_update_price_method:visible'))
  }

  checkUpdatePriceMethodSelect() {
    $('.update_catalog_price_modifications_update_price_amount .input-group-text').text(
      $('#update_catalog_price_modifications_update_price_method').val()
    )
  }

  checkDeleteAll() {
    $('.update_catalog_price_modifications_container').toggle(
      !$('#update_catalog_price_modifications_delete_all').prop('checked')
    )
    initSelect2($('select:visible'))
  }
}
