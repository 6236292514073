import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--franchisor-nav"
export default class extends Controller {
  connect() {
    this.franchisorId = $(this.element).data().id
  }
  clickTab(e) {
    if($($(e.currentTarget).attr('href')).find('div').length == 0) {
      this.loadTab($(e.currentTarget).data().type, $(e.currentTarget).attr('href'))
    }
  }

  loadTab(type, tab) {
    $('#loading_app').addClass('d-flex')
    $.ajax({
      dataType: 'script',
      url: `/admin/franchisor_tab/${this.franchisorId}?type=${type}&tab=${tab.replace('#', '')}`
    })
  }
}
