import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--listing--listing-prepare-print-stickers"
export default class extends Controller {
  connect() {
    $(this.element).html(`
      <p>Si vous souhaitez réutiliser du papier à étiquettes, vous pouvez insérer des cellules vides avant les autres étiquettes.</p>
      <p>Si vous ne souhaitez pas insérer de cellules vides laissez le champ à 0.</p>
      <div class="form-label-group float" style='width: 170px; margin: 0 auto;'>
        <label class="has-float-label input-group">
          <input class="form-control numeric float" type="number" step="1" value="0">
          <div class="input-group-append">
            <span class="input-group-text">étiquettes vides</span>
          </div>
        </label>
      </div>

      <div class='w-100 mt-3 btn btn-primary' data-action="click->back--listing--listing-prepare-print-stickers#print">
        Imprimer
      </div>      
    `)
  }

  print() {
    $('#modal_rails_5').modal('hide')
    let empty_cells = $(this.element).find('input').valF()
    empty_cells = isNaN(empty_cells) ? 0 : empty_cells
    if(empty_cells == 0) {
      $('#listing').get(0).listingmanager.print()
    } else {
      $('#listing').get(0).listingmanager.reloadView(empty_cells)
    }
    
  }
}
