import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import frLocale from '@fullcalendar/core/locales/fr';
import { initModal } from 'shared/modal'

// Connects to data-controller="admin--preparation-follow-calendar"
export default class extends Controller {
  connect() {
      this.loaded_event_dates = {}
      this.calendar = new Calendar($('#preparation_follow_calendar').get(0), {
      locale:  frLocale,
      plugins: [ timeGridPlugin, dayGridPlugin ],
      initialView: $(window).width() > 768 ? 'dayGridMonth' : 'timeGridWeek',
      eventClick: (info) => {
        window.open(`/admin/new_customers/${info.event.id}/edit`,'_blank');
      },
      customButtons: {   
        changeDayGridDay: {
          text: 'Par Mois',
          click: () => { this.calendar.changeView('dayGridMonth') }
        },
        changeTimeGridWeek: {
          text: 'Par Semaine',
          click: () => { this.calendar.changeView('timeGridWeek') }
        }
      },
      headerToolbar: {
        left:   'prev,next',
        center: 'title',
        right: 'changeDayGridDay,changeTimeGridWeek'
      },
      eventDidMount: (event) => {
        $(event.el).css('backgroundColor', event.backgroundColor)
      },
      eventContent: (arg) => {
        return { html: `
            <div class="fc-event-time d-flex justify-content-between px-1">
              <span>${arg.timeText}</span>
              <span>${arg.event.extendedProps.hours} heures</span>
            </div>
            <div class="fc-event-title">${arg.event.title}</div>
          `
        }
      }
    })
    this.fetch_events(null)
    //this.calendar.on('datesSet', (info) => { this.fetch_events(info) })
    this.calendar.render();
  }

  fetch_events(info) {
    console.log(info)
    //this.current_dates = info

    //let date_range = `${info.startStr.split('T')[0]}_${info.endStr.split('T')[0]}`

    if(0 == 0) {
      //console.log(date_range)
      $('#loading_app').addClass('d-flex')
      $.get(`/admin/new_customers/load_preparation_follow_calendar`, (data) => {
        data.forEach((event, index) => { 
          this.calendar.addEvent(event)
        })
        //this.loaded_event_dates[date_range] = data.map(o => o.id)
        $('#loading_app').removeClass('d-flex')
      })
    }
  }
}
