import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
import { flashMessage } from 'shared/notify';

// Connects to data-controller="admin--commercial-actions"
export default class extends Controller {
  connect() {
    this.element[this.identifier] = this

  }

  accomplish_action(e) {
    ajaxTurboStream(`/admin/commercial_actions/${$(e.currentTarget).data().id}/accomplished_action`)
  }

  add_action(e) {
    ajaxTurboStream(`/admin/commercial_actions/new?prospect_id=${parseInt($(e.currentTarget).data().id)}`)
  }

  toggleAll() {
    $('.data-table-rows-check input').prop('checked', true)
  }

  unToggleAll() {
    $('.data-table-rows-check input').prop('checked', false) 
  }

  applyBatchAction() {
    let selected_ids = $('#commercial_actions_tbody :checked').map((i, e) => {return $(e).parents('tr').data().id}).get()
    if(selected_ids.length == 0) {
      return false
    }

    switch($('#commercial_actions_batch_actions_container select').val()) {
      case 'update_date':
        ajaxTurboStream(`/admin/commercial_actions/prepare_update_batch_dates?commercial_action_ids=${selected_ids.join('_')}`)
        break;
      case 'transfer_prospects':
        ajaxTurboStream(`/admin/commercial_actions/prepare_batch_transfer_prospects?commercial_action_ids=${selected_ids.join('_')}`)
        break;
      case 'cancel_batch_commercial_actions':
        ajaxTurboStream(`/admin/commercial_actions/cancel_batch_commercial_actions?commercial_action_ids=${selected_ids.join('_')}`, false, () => {
          flashMessage('Les actions commerciales sélectionnées ont bien été archivées')
          $('#commercial_actions_filter_container').get(0).controller.filter()
        })
      default:
    }
  }

  toggleCheckbox(e) {
    $(e.currentTarget).find('input').prop('checked', !$(e.currentTarget).find('input').prop('checked'))
  }
}
