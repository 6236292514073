import { Controller } from "@hotwired/stimulus"
require ('spectrum-colorpicker');

// Connects to data-controller="catalog--product-categories--form"
export default class extends Controller {
  connect() {
    loadPreviewColor()
    $(this.element).find('#product_category_accounting_codes_shop_select').on('select2:select', (e) => {
      $('.product_category_accounting_codes_shop').removeClass('active')
      $(`#product_category_accounting_codes_shop_${$(e.currentTarget).val()}`).addClass('active')
    })
    $('.product_category_accounting_codes input').removeClass('required').addClass('optional')
  }

  showEmptyChanged() {
    $('.product_category_overrideShopCardId').toggleClass('d-none', !$('#product_category_showEmpty').prop('checked'))
  }

  nameChanged(e) {
    let name_sc_input = $('#product_category_local_product_category_name_sc')
    if(name_sc_input.val().length == 0 || !$(this.element).data().scActivated || $('#new_product_category').length) {
      name_sc_input.val($(e.currentTarget).val())
      name_sc_input.valid()
    }
  }
}
