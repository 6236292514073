import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--external-products"
export default class extends Controller {
  connect() {
    this.products = {}
    this.externalName = $(this.element).data().externalName
    this.init_search()
    this.init_select()
  }

  init_product() {
    if(Object.keys(this.products).length == 0) {
      $('#loading_app').addClass('d-flex')
      this.toggle_btn(true)
      $.get('/back/api_modules/fetch_external_products', (data) => {
        this.products = data.data
        this.toggle_btn(false)
        $('#loading_app').removeClass('d-flex')
        this.modal_products()
      })
    } else {
      this.modal_products()
    }
  }

  init_search() {
    $(document).on('keyup', '#search_external_product', () => {
      let value = latinize($('#search_external_product').val().toLowerCase())
      if(value.length > 0) {
        $('.external_product_modal .clickable').filter(function() {
          $(this).toggle(latinize($(this).data().name.toLowerCase()).indexOf(value) > -1)
        });
      } else {
        $('.external_product_modal .clickable').toggle(true)
      }
    })
  }

  init_select() {
    $(document).on('click', '#external_variation_modal_main p', (element) => {
      this.externalId = $(element.currentTarget).parent().data().id
      
      if($('.product_unitIds input:checked').length > 1) {
        let units = []
        $('.modal-title').text("Choix de l'unité de mesure")
        $.each($('.product_unitIds input:checked'), (i, e) => {
          units.push(`<p class='product_externalId_choose_unit btn btn-primary' data-unit-id="${$(e).next().text()}">${$(e).next().text()}</p>`)
        })
        $('.modal-body').html(`
          <p class='mb-3'>
            Votre produit dispose de plusieurs unités de mesure. Merci de choisir celle que vous souhaitez associer à votre produuit ${this.externalName}.
          </p>
          ${units.join()}
        `)
      } else {
        $('.modal').modal('hide')
        this.setExternalId()
        $('.ppu_externalId').val(this.externalId)
      }
    })

    $(document).on('click', '.product_externalId_choose_unit', (e) => {
      $(`.product_price_unit_fields[data-unit-name="${$(e.currentTarget).data().unitId}"] .ppu_externalId`).val(this.externalId)
      this.setExternalId()
      $('.modal').modal('hide')
    })
  }

  externalIdChanged(e) {
    $('.ppu_externalId').val($(e.currentTarget).val())
    this.externalId = $(e.currentTarget).val()
    if(this.externalId && this.externalId.length > 0) {
      this.setExternalId()
    } else {
      this.removeId()
    }
  }

  setExternalId() {
    $('#product_externalId').val(this.externalId)
    $('#product_externalId').parents('.product_externalId').next().find('.externalId_display').text(this.externalId)
    $('#product_externalId').parents('.product_externalId').next().addClass('has_externalId')  
    $('.external_product_modal p').unbind('click') 
  }

  removeId() {
    $('#product_externalId').val('')
    $('#product_externalId').parents('.product_externalId').next().eq(0).find('.externalId_display').text('')
    $('#product_externalId').parents('.product_externalId').next().removeClass('has_externalId')
    $('#product_price_unit_fields_container .externalId_container input').val('')
    $('#product_price_unit_fields_container .externalId_container').removeClass('has_externalId')
    $('.ppu_externalId').val('')
  }

  toggle_btn(loading) {
    $(this.element).find('.btn').toggleClass('btn-loading', loading)
    $(this.element).find('.btn').prop('disabled', loading)
    $(this.element).find('.position-absolute').toggle(loading)
  }

  modal_products() {
    initModal()
    $('#modal_rails_5').modal('show')
    $('.modal-title').text(`Choix du produit ${this.externalName}`)
    $('.modal-footer').html('')
    $('.modal-footer').hide()
    $('.modal-body').html(`
      <div class="external_product_modal" id="external_variation_modal_main">
        <div class="search-sm d-block mb-2 align-top">
          <input placeholder="Rechercher un produit..." id="search_external_product" class='w-100' data-action="keyup->externalProducts#search">
        </div>
        ${this.external_products()}
      </div>
    `)
    $('.external_product_modal').css('height', $(window).height() - 229)
  }

  external_products() {
    let products = ""
    $.each(this.products, (id, object) => {
      products += `
        <div class='py-1 mb-0 clickable' data-name='${latinize(object.name.toLowerCase())}' data-id="${object.id}">
          <p class='mb-0 text-79' style='font-size: .8em'><em>${object.categories}</em></p>
          <p class='mb-0'>${object.name}</p>
        </div>
      `
    })
    return products
  }
}
