import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--connector-realtime-inputs"
export default class extends Controller {
  connect() {
    this.input = $(this.element).find('.connector_realtime_endpoint_input')
    this.input.removeClass('optional')
    this.input.rules('add', {regex_url: true})
    this.input.rules('add', {required: (e) => {
      return $(e).parents('.connector_real_time_fields').find('.custom-control-input').prop('checked')
    }})
  }
}
