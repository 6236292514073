import Back__Statistics__V2__StatisticsAbstractFilterController from "./statistics_abstract_filter_controller"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="back--statistics--v2--statistics-promotions"
export default class extends Back__Statistics__V2__StatisticsAbstractFilterController {
  get autoRefresh() {
    return true
  }

  refresh(params) {
    ajaxPostTurboStream('/back/statistics/v2/promotion_statistics/load_table', params)
  }
}
