import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catalog--menu-categories-form"
export default class extends Controller {
  nameChanged(e) {
    let name_sc_input = $('#menu_category_local_menu_category_name_sc')
    if(name_sc_input.val().length == 0 || !$(this.element).data().scActivated || $('#new_menu_category').length) {
      name_sc_input.val($(e.currentTarget).val())
      name_sc_input.valid()
    }
  }
}
