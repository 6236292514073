import { Controller } from "@hotwired/stimulus"
import { flashMessage } from 'shared/notify'
// Connects to data-controller="copy-input-content"
export default class extends Controller {
  connect() {
  }

  copyInput() {
    var copyText = $(this.element).find('input')
    copyText.select();
    document.execCommand("copy");
    flashMessage('', `Le lien de partage a été copié dans le presse papier`, 'success')
  }
}
