import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import frLocale from '@fullcalendar/core/locales/fr';
import { initModal } from 'shared/modal'
import { CustomCommentCalendarForm } from './custom_comment_calendar_form';

export class CalendarHandler {
  constructor() {

    $(document).on('keyup', '#search_calendar_history', () => {
      let value = latinize($('#search_calendar_history').val().toLowerCase())
      if(value.length > 0) {
        $('.calendar_history_container a').filter(function() {
          $(this).toggle(latinize($(this).data().client.toLowerCase()).indexOf(value) > -1)
        });
      } else {
        $('.calendar_history_container a').toggle(true)
      }
    })

    $(document).on('click', '.calendar_history_container a', () => {
      $('.order_calendar_content').html('<div class="loading_card"><div class="loading_circle"></div></div>')
    })

    new CustomCommentCalendarForm(this)
    this.loaded_event_dates = {}
    this.calendar = new Calendar($('#orders_calendar').get(0), {
      locale:  frLocale,
      plugins: [ timeGridPlugin, dayGridPlugin ],
      initialView: $(window).width() > 768 ? 'timeGridWeek' : 'dayGridDay',
      eventClick: (info) => {
        this.currentCell = $(info.el)
        this.currentOrder = info.event
        this.edit_order()
      },
      customButtons: {
        changeDayGridDay: {
          text: 'Jour',
          click: () => { this.calendar.changeView('dayGridDay') }
        },
        changeDayGridWeek: {
          text: 'Semaine (liste)',
          click: () => { this.calendar.changeView('dayGridWeek') }
        },
        changeTimeGridWeek: {
          text: 'Semaine (calendrier)',
          click: () => { this.calendar.changeView('timeGridWeek') }
        }
      },
      headerToolbar: {
        left:   'prev,next',
        center: '',
        right:  'changeDayGridDay,changeDayGridWeek,changeTimeGridWeek'
      },
      eventDidMount: (event) => {
        $(event.el).css('backgroundColor', event.backgroundColor)
      },
      eventContent: (arg) => {
        return { html: `
            <div class="fc-event-time d-flex justify-content-between px-1">
              <span>${arg.timeText}</span>
              <span>${arg.event.extendedProps.minutes} mins</span>
            </div>
            <div class="fc-event-title">${arg.event.title}</div>
          `
        }
      }
    })
    this.calendar.on('datesSet', (info) => { this.fetch_events(info) })
    this.calendar.render();
    $(document).on('click', '.local_order_statuses_option', (e) => { this.select_status($(e.currentTarget)) })

    this.interval = setInterval(() => {
      if($('#orders_calendar').length != 0) {
        clearInterval(this.interval)
      } else {
        let date_range = `${this.current_dates.startStr.split('T')[0]}_${this.current_dates.endStr.split('T')[0]}`
        if(this.loaded_event_dates[date_range] && $('.modal:visible').length == 0) {
          this.loaded_event_dates[date_range].forEach((id, index) => {this.calendar.getEventById(id).remove()})
          this.loaded_event_dates[date_range] = undefined
          this.fetch_events(this.current_dates)
        }
      }

    }, 30000)
  }

  get_currentOrder() {
    return this.currentOrder
  }

  fetch_events(info) {
    this.current_dates = info

    let date_range = `${info.startStr.split('T')[0]}_${info.endStr.split('T')[0]}`
    if(!this.loaded_event_dates[date_range]) {
      $('#loading_app').addClass('d-flex')
      $.get(`/back/orders/calendar/load_orders?start=${info.startStr}&end=${info.endStr}`, (data) => {
        data.forEach((event, index) => { this.calendar.addEvent(event) })
        this.loaded_event_dates[date_range] = data.map(o => o.id)
        $('#loading_app').removeClass('d-flex')
      })
    }
  }

  edit_order() {
    initModal()
    $('#modal_rails_5').modal('show')
    $('.modal-title').text(`Modification du statut de la commande #${this.currentOrder.extendedProps.continousSequence} - client : ${this.currentOrder.title}`)
    $('.modal-dialog').addClass('modal-lg')
    $('.modal-body').html(`
      <div class='row'>
        <div class="col-md-5 local_order_statuses_container">
          ${gon.statuses.map(status => this.render_status(status)).join('')}
        </div>
        <div class='col-md-7 order_calendar_content'>
          <div class="loading_card"><div class="loading_circle"></div></div>
        </div>
      </div>
    `)
    $.get(`/back/orders/calendar/${this.currentOrder.id}`)
    $('.modal-footer').html('')
    $('.modal-footer').hide()
  }

  render_status(status) {
    return `
      <div
        data-status="${status.id}"
        class='local_order_statuses_option ${status.id == this.currentOrder.extendedProps.local_order_status_id ? 'active' : ''} position-relative mb-2 p-3 pl-5 text-white text-13'
        style='background-color: ${status.color}'
      '>
        <div class='local_order_statuses_check position-absolute'>
          <i class="fas fa-check"></i>
        </div>
        ${status.name}
      </div>
    `
  }

  select_status(option) {
    if(!option.hasClass('active')) {
      $.ajax({
        method: 'PUT',
        url: `/back/orders/calendar/update_status/${this.currentOrder.id}`,
        data: {field: 'local_order_status_id', value: option.data().status}
      })
      this.currentOrder.setProp('backgroundColor', option.css('backgroundColor'))
      this.currentOrder.setProp('borderColor', option.css('backgroundColor'))
      this.currentOrder.setExtendedProp('local_order_status_id', option.data().status)
      this.currentCell.css('backgroundColor', option.css('backgroundColor'))
      $('.modal').modal('hide')
    }
  }
}
