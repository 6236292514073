import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="back--statistics--product-prices-filter"
export default class extends Controller {
  connect() {
    $(this.element).find('#stats_product_prices_shop_select').on('select2:select', () => {
      ajaxTurboStream(`/back/statistics/product_prices?shopId=${$('#stats_product_prices_shop_select').val()}`, true)  
    })
  }
}
