import Utils__NestedFormController from "controllers/utils/nested_form_controller"
import { Controller } from "@hotwired/stimulus"
import { classAgregator } from 'shared/class_agregator'
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="admin--toporder-pay-configuration-form"
export default class extends classAgregator(Controller, Utils__NestedFormController) {
  static targets = ['nav', 'templateTopOrderPayFee', 'contentTopOrderPayFee', 'paymentSelect']
  
  connect() {
    $(this.element).validate()
    this.populatePayments()
    this.loadPayments()
  }

  populatePayments() {
    let html = ''
    for(const id in $(this.element).data().payments) { 
      html += `<option value='${id}'>${$(this.element).data().payments[id]}</option>`
    }
    this.paymentSelect.html(html)
  }

  fixedFeesChanged(e) {
    $('.toporder_pay_configuration_percentageFee .input-group-text').text($(e.currentTarget).prop('checked') ? "€" : "%")
  }

  callbackAfterAddTopOrderPayFee(element, options) {
    setTimeout(() => {
      this.decoratePayment(element, options.id)
      element.find('.toporder_pay_configuration_top_order_pay_fees_paymentId input').val(options.id)
    })
  }

  decoratePayment(element, id) {
    element.find('.toporder_pay_configuration_top_order_pay_fees_feesAmount input').rules('add', {required: true})
    element.find('.top_order_fee_name').text($(this.element).data().payments[id])
  }

  callbackBeforeRemoveTopOrderPayFee(element) {
    return new Promise((resolve, reject) => { 
      let id = $(element).find('.toporder_pay_configuration_top_order_pay_fees_paymentId input').val()
      this.paymentSelect.find(`option[value="${id}"]`).prop('disabled', false)
      this.togglePaymentSelect()
      resolve() 
    })
  }
  callbackAfterRemoveTopOrderPayFee() {}

  get paymentSelect() {
    return $('.toporder_pay_configuration_payments_container select')
  }

  addPayment() {
    this.addAssociation('TopOrderPayFee', {id: this.paymentSelect.val()})
    this.paymentSelect.find(':selected').prop('disabled', true)
    this.togglePaymentSelect()
  }

  togglePaymentSelect() {
    this.paymentSelect.val(this.paymentSelect.find('option').not(':disabled').eq(0).val())
    this.paymentSelect.parent().parent().toggle(this.paymentSelect.find('option').not(':disabled').length > 0)
    if(this.paymentSelect.hasClass('select2-hidden-accessible')) {
      updateSelect2(this.paymentSelect)
    } else if(this.paymentSelect.is(':visible')) {
      initSelect2(this.paymentSelect)
    }
    this.paymentSelect.trigger('change')
  }

  loadPayments() {
    $.each($('.top-order-pay-fee-fields'), (i, element) => {
      let id = $(element).find('.toporder_pay_configuration_top_order_pay_fees_paymentId input').val()
      this.paymentSelect.find(`option[value="${id}"]`).prop('disabled',true)
      this.decoratePayment($(element), id)
    })
    this.togglePaymentSelect()
  }
}
