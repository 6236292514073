import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--dashboard-sponsorship"
export default class extends Controller {
  validPayment(e) {
    let card = $(e.currentTarget).parents('.card').eq(0)
    $.ajax({
      url: `/admin/shop_group_sponsorships/${$(e.currentTarget).data().id}/pay`,
      method: 'PATCH'
    })
    $(e.currentTarget).parents('tr').remove()
    if(card.find('td').length == 0) {
      card.remove()
    }
  }
}
