import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--commercial-actions-form"
export default class extends Controller {
  connect() {
    this.event_duration_type_changed()
    setTimeout(() => {
      this.subs = $(this.element).data().commercialActionSubTypes
      this.results = $(this.element).data().commercialActionTypeResults
      this.checkSubs() 
      $('#commercial_action_commercial_action_type_id').on('select2:select', () => {
        this.checkSubs()
      })    
      $('#commercial_action_commercial_action_sub_type_id').on('select2:select', () => {
        if(!$('.commercial_action_duration').hasClass('d-none')) {
          this.checkDuration()
        }
      })     
    }, 300)

  }

  checkSubs() {
    $('#commercial_action_commercial_action_sub_type_id option').remove()
    $('#commercial_action_commercial_action_sub_type_id').val('')
    if(this.subs[$('#commercial_action_commercial_action_type_id').val()]) {
      let subs = this.subs[$('#commercial_action_commercial_action_type_id').val()]
      for(const sub in subs) {
        $('#commercial_action_commercial_action_sub_type_id').append(`<option value='${subs[sub].id}'>${subs[sub].name}</option>`)
      }
      $('.commercial_action_commercial_action_sub_type_id').removeClass('d-none')
      initElementSelect2($('#commercial_action_commercial_action_sub_type_id'))
    } else {
      $('.commercial_action_commercial_action_sub_type_id').addClass('d-none')
    }

    $('#commercial_action_commercial_action_type_result_id').val('')
    $('#commercial_action_commercial_action_type_result_id option').remove()
    if(this.results && this.results[$('#commercial_action_commercial_action_type_id').val()]) {
      let results = this.results[$('#commercial_action_commercial_action_type_id').val()]
      for(const result in results) {
        $('#commercial_action_commercial_action_type_result_id').append(`<option value='${results[result].id}'>${results[result].name}</option>`)
      }
      if($('#commercial_action_is_accomplished').prop('checked')) {
        $('.commercial_action_commercial_action_type_result_id_container').removeClass('d-none')
        initElementSelect2($('#commercial_action_commercial_action_type_result_id'))        
      }

    } else {
      $('.commercial_action_commercial_action_type_result_id_container').addClass('d-none')
    }

    if($('#commercial_action_commercial_action_type_id').val().length) {
      this.checkDuration()
    }
  }

  checkDuration() {
    let commercialActionTypeData = $(this.element).data().commercialActionTypes[$('#commercial_action_commercial_action_type_id').val()]
    $('.commercial_action_duration').toggleClass('d-none', !commercialActionTypeData.timeable)
    $('.commercial_action_hour_container').toggleClass('d-none', !commercialActionTypeData.timeable)
    initElementSelect2($('.commercial_action_hour_container select:visible'))
    if($('.commercial_action_commercial_action_sub_type_id:visible').length) {
      $('#commercial_action_duration').val($(this.element).data().commercialActionSubTypeDurations[$('#commercial_action_commercial_action_sub_type_id').val()])
    }
  }

  accomplishedChanged() {
    if($('#commercial_action_commercial_action_type_result_id option').length && $('#commercial_action_is_accomplished').prop('checked')) {
      $('.commercial_action_commercial_action_type_result_id_container').removeClass('d-none')
      initElementSelect2($('#commercial_action_commercial_action_type_result_id'))
    } else {
      $('.commercial_action_commercial_action_type_result_id_container').addClass('d-none')
    }
  }

  event_duration_type_changed() {
    if($('.commercial_action_event_time_container').length) {
      $('.commercial_action_event_time_container').toggleClass('d-none', $('.commercial_action_event_duration_type :checked').val() != '1')
    }
  }

  hour_min_changed(e) {
    $('#commercial_action_event_time_hour_max').val(parseInt($(e.currentTarget).val()) + 1)
  }
}
