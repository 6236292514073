import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="back--stock--recipe--import-recipe"
export default class extends Controller {
  connect() {
    this.id = $(this.element).data().id
  }

  emptyRecipe() {
    this.newRecipe(null)
  }

  chooseRecipe(e) {
    this.newRecipe($(e.currentTarget).parent().find('select').val())
  }

  newRecipe(recipe_id) {
    let url = `/back/recipes/new?product_stock_id=${this.id}`
    if(recipe_id) { url += `&recipe_id=${recipe_id}` }
    ajaxTurboStream(url, false)
  }
}
