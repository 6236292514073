import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="admin--export"
export default class extends Controller {
  export(e) {
    let params = `?from=${$('.admin_export_filter_since').val().replace(/\//g, '-')}&to=${$('.admin_export_filter_to').val().replace(/\//g, '-')}&commercial=${$('#invoice_client').val()}&step=${$('#admin_export_filter_step').val()}`

    if($(e.currentTarget).data().url == 'new_commercial_performance') {
      $.get(`${window.location.href}/${$(e.currentTarget).data().url}${params}`)
      $('.modal-header').hide()
      $('#modal_rails_5').modal('show')
      $('.modal-body').html(`
        <p>Le document que vous avez demandé est en cours de génération.</p>
        <p>Vous recevrez un mail quand il sera disponible.</p>
        <p class='mb-0'>Il restera disponible pour une durée de 7 jours.</p>
      `)
      $('.modal-footer').html(`
        <p class="btn btn-outline-primary mb-0" data-dismiss="modal">J'ai compris</p>
      `)
    } else {
      window.location = `${window.location.href}/${$(e.currentTarget).data().url}${params}`
    }
    
  }
}
