import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream'
// Connects to data-controller="back--toporder-ia-dashboard"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    $(this.element).find('#toporder_ia_dashboard_select_week').on('select2:select',(e) => {
      this.reloadCards()
    })
    this.reloadCards()
    this.reloadEvents()
  }

  reloadCards() {
    let params = [
      `startDate=${$('#toporder_ia_dashboard_select_week').val().split('/').join('-')}`,
    ]
    if($('#toporder_ia_page_title_shop').length) {
      params.push(`shopId=${$('#toporder_ia_page_title_shop').val()}`)
    }

    $.get(`/back/toporder_ia/dashboard/fetch_data_cards?${params}`).done(data => {
      $.each(data, (index, value) => {
        animateValue($('.toporder_ia_dashboard_day_cell_ca').get(index), 0, parseInt(value), 500)
      })
    })
  }

  reloadEvents() {
    ajaxTurboStream(`/back/toporder_ia/dashboard/fetch_events?shopId=${$('#toporder_ia_page_title_shop').val()}`)
  }

  clickWeeklyOption(e) {
    if(!$(e.currentTarget).hasClass('active')) {
      $('.toporder_ia_dashboard_weekly_stats_period_option.active').removeClass('active')
      $(e.currentTarget).addClass('active')
      $('#toporder_ia_dashboard_weekly_stats_period').data('startDate', $(e.currentTarget).data().date)
      reloadDatas()
    }
  }
}
