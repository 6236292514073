import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--local-shop-borne-setting-form"
export default class extends Controller {
  connect() {
    $(this.element).find('.local_shop_borne_setting_distribution_types label').on('click', (e) => {
      if($(e.currentTarget).parent().find('input.custom-control-input').prop('checked') && $(this.element).find('.local_shop_borne_setting_distribution_types input:checked').length == 1) {
        e.preventDefault()
      }
    })
  }
}
