import { Controller } from "@hotwired/stimulus"
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="admin--prospect-sponsorship"
export default class extends Controller {
  connect() {
    $.get(`/admin/prospects/fetch_sponsorships?prospect_id=${$(this.element).data().prospectId}`).done((data => {
      this.sponsorships = data
      $(this.element).show()
      initSelect2($(this.element).find('select:visible'))
      this.populateSelect()
      $('#prospect_sponsored_by').on('select2:select', () => {
        this.populateSelect()
      })
    }))
  }

  populateSelect() {
    $('.prospect_sponsorship_result').toggle($('#prospect_sponsored_by').val() && $('#prospect_sponsored_by').val().trim().length > 1)
    if($('#prospect_sponsored_by').val() == ' ' || !this.sponsorships[$('#prospect_sponsored_by').val()]) {
      $('#prospect_shop_group_sponsorship_id').val('')
      $('#prospect_sponsorship_shop_group_sponsorships').hide()
    } else {
      let html = ''
      for(const sponsorship of this.sponsorships[$('#prospect_sponsored_by').val()]) {
        let selected = $(this.element).data().selected && sponsorship.id == $(this.element).data().selected ? 'selected' : ''
        html += `<option ${selected} value='${sponsorship.id}'>${sponsorship.fullname}</option>`
      }
      html += "<option value=' '>Autre</option>"
      $('#prospect_sponsorship_shop_group_sponsorships select').html(html)
      $('#prospect_sponsorship_shop_group_sponsorships').show()
      updateSelect2($('#prospect_sponsorship_shop_group_sponsorships select'))
    }
  }
}
