import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--commercial-actions-update-batch-date"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      $('#commercial_action_date').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
        var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
        $(".datepicker").css("top", top);
      });
    }, 500)
  }
}
