import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="utils--turbo-toporder-table"
export default class extends Controller {
  connect() {
    this.table = $(this.element)
    //this.table.find('thead').css('top', $('body > nav').innerHeight())
    this.default = this.table.data()
    this.setSortable()
    this.sort = 'none'
    this.table.on('click', '.sort_arrow', (element) => {
      this.sort_table($(element.currentTarget))
    })
    $('.digg_pagination').css('top',  $('body > nav').innerHeight())
  }

  sort_table(element) {
    this.direction = element.attr('class').split(/\s+/)[1] == 'fa-long-arrow-alt-up' ? 'sort_up' : 'sort_down'
    this.th = element.parent()
    this.table.find('th').not(this.th).removeClass('sort_up sort_down')
    if(this.direction == this.default.sort && this.th.data().field == this.default.field && this.th.hasClass(this.direction)) {
      return;
    }
    if(this.th.hasClass(this.direction)) {
      this.th.removeClass(this.direction)
      this.sort = 'none'
    } else {
      this.th.removeClass('sort_up sort_down')
      this.th.addClass(this.direction)
      this.sort = this.direction
    }
    this.reload()
  }


  filter(value) {
    if(value.length == 0) {
      this.table.find('tbody tr').toggle(true)
      return;
    }
    $.each(this.table.find('tbody tr'), (index, tr) => {
      let content = []
      $.each($(tr).find('.searchable_td'), (index, td) => {
        content.push($(td).data().search ? $(td).data().search : $(td).text().trim())
      })
      $(tr).toggle(content.join(' ').includes(value))
    })
  }

  reload() {
    this.table.addClass('toporder_table_reloading')
    let params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if(this.sort != 'none') {
      params['sort'] = this.sort
      params['field'] = this.th.data().field
    }
    params = new URLSearchParams(params).toString();
    ajaxTurboStream(`${window.location.href.split('?')[0]}${params.length ? '?' + params : ''}`, true)
  }

  setSortable() {
    this.table.append('<div class="toporder_table_loader"></div>')
    $.each(this.table.find('th'), (index, th) => {
      if($(th).data().field) {
        $(th).append(`<i class="fas fa-long-arrow-alt-up ml-2 sort_arrow"></i><i class="fas fa-long-arrow-alt-down sort_arrow"></i>`)
        $(th).addClass('tp_sortable')
        if(this.table.data().field == $(th).data().field) {
          $(th).addClass(this.table.data().sort)
        }
      }
    })
  }
}
