import { Controller } from "@hotwired/stimulus"
import { loadDatepicker } from 'shared/load_datepicker';
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="back--print-list"
export default class extends Controller {
  static targets = ['shop', 'dateSince', 'dateTo', 'continousSequence']
  connect() {
    this.element['controller'] = this
    loadDatepicker($('.turbo_datepicker'))
    $('#back-print-list__orders').tagsinput({
      allowDuplicates: false
    });
  }

  submitForm() {
    ajaxPostTurboStream(
      '/back/listing/print_list', {
        shopId: $(this.shopTarget).val(),
        dateSince: $(this.dateSinceTarget).val(),
        dateTo: $(this.dateToTarget).val(),
        continousSequence: $(this.continousSequenceTarget).val()
      }
    )
  }
}
