import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init';
require('perfect-scrollbar');

// Connects to data-controller="utils--turbo-modal-form-slider"
export default class extends Controller {
  static targets = ['nav']
  connect() {
    this.initFormSlider()
  }

  initFormSlider() {
    this.controllerName = $(this.element).data().controller
    this.currentIndex = 0
    $(this.element).modal({backdrop: 'static', keyboard: false})
    new PerfectScrollbar('#turbo-modal-form-slider__nav')
    $('#loading_app').removeClass('d-flex')
  }

  get currentSlide() {
    return $(this.element).find('.turbo-modal-form-slider__slide.active')
  }

  get currentSlideInputs() {
    return $(this.element).find('.turbo-modal-form-slider__slide.active input:visible').not('.optional')
  }

  get currentNav() {
    return $(this.element).find('#turbo-modal-form-slider__nav .active')
  }

  changeSlideNext() {
    if((this.currentSlideInputs.length && !this.currentSlideInputs.valid()) || $('.modal-footer .float-right').hasClass('disabled')){
      return;
    }
    if($(this.element).hasClass('intern_slider')) {
      $('.carousel-item.active .nav-item.active').next().find('a').click()
      return;
    } else {
      this.currentIndex = this.currentNav.nextAll('li:visible').eq(0).index()
      if(this.currentNav.is($('#turbo-modal-form-slider__nav li:visible:last'))) {
        if($('.modal form').valid()) {
          $('.modal-footer').hide()
          //$('.modal form').submit()
          this.submitForm()
        }
        return;
      }
    }
    this.jumpToSlide('next')
  }

  submitForm() {
    if($(this.element).find('form').valid()) {
      $('.modal-content').addClass('closing-alert')
      if($(this.element).find('form').data().model) {

        this.updatingModal(i18n.t(`updating_modal.${$(this.element).find('form').data().model}`))
      }
      $(this.element).find('input[type=submit]').click()
    }
  }

  updatingModal(text) {
    $('.modalception-body').html(`
      <div class="loading_modal">
        ${text}
        <div class="loading_circle"></div>
      </div>
    `)
    $('.modal-body').hide()
    $('.modalception-footer').hide()
    $('.modalception-footer').removeClass('d-flex')
    $('.custommodalception').slideDown(400)

    $('.modal-header').hide()
    $('.modal-footer').hide()
  }  

  changeSlidePrev() {
    if($(this.element).hasClass('intern_slider') && !$('.carousel-item.active .nav-item.active').is($('.carousel-item.active .nav-item:first-child'))) {
      $('.carousel-item.active .nav-item.active').prev().find('a').click()
    } else {
      this.currentIndex = this.currentNav.prevAll('li:visible').eq(0).index()
      this.jumpToSlide('prev')
    }
  }

  jumpToSlide(direction) {
    $('.modal-body').scrollTop(0, 0)
    this.currentSlide.removeClass('active')
    $(this.element).find('.turbo-modal-form-slider__slide').eq(this.currentIndex).addClass('active')
    initSelect2(this.currentSlide.find('select:visible'))
    $(this.navTarget).find('.active').removeClass('active')
    $(this.navTarget).find('li').eq(this.currentIndex).addClass('active')
    this.setButtonsFooter()
    if(this.currentIndex > 2 && direction == "next") {
      $(this.navTarget).scrollLeft((this.currentIndex - 3) * 150)
    } else if (this.currentSlide < 4 && action == "prev") {
      $(this.navTarget).scrollLeft($(this.navTarget).find('.active').offset().left - $(this.navTarget).offset().left - 500)
    }
  }

  setButtonsFooter() {
    $('.modal-footer .btn').removeClass('d-none')
    $('.modal-footer .btn:last-child').text('Suivant')
    $('.modal-footer .float-left').toggleClass('d-none', this.currentIndex == 0)
    $('.modal-footer .btn:last-child').text(this.currentNav.is($(this.navTarget).find('li:last')) ? "Terminer" : "Suivant")
  }
}
