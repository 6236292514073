import { Controller } from "@hotwired/stimulus"
import { MarkerClusterer } from "@googlemaps/markerclusterer";

// Connects to data-controller="admin--new-customers-map"
export default class extends Controller {
  connect() {
    const lat = gon.toporder_employee.lat
    const lng = gon.toporder_employee.lng
    const map = new google.maps.Map(document.getElementById("map_new_customers"), {
      zoom: 7,
      center: {lat: lat, lng: lng},
    });
    var markers =  gon.new_customers.map((new_customer, i) => {
      if(!new_customer.lat || !new_customer.lng) {
        return false
      }
      var contentString = '<div id="content"><a href="/admin/new_customers/'+ new_customer.id +'/edit"><h3>' + new_customer.project_name +
      '</h3></a><a href="https://www.google.com/maps/search/?api=1&query='+ new_customer.lat+'%2C'+new_customer.lng+'">Voir sur Google Maps</a></div>';
      const marker = new google.maps.Marker({
        position: {lat: new_customer.lat, lng: new_customer.lng},
        map,
      })
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 200
      });
      marker.addListener('click', function () {
        infowindow.open(marker.get('map'), marker);
      });
      return marker
    });

    new MarkerClusterer({ markers, map });
  }

  filter() {
    let params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if($('#prospect_business_category').val() == '_') {
      delete params.api_business_category_uuid
    } else {
      params.api_business_category_uuid = $('#prospect_business_category').val()
    }
    params = new URLSearchParams(params).toString();
    //console.log(`${window.location.href.split('?')[0]}${params.length ? '?' + params : ''}`)
    Turbo.visit(`${window.location.href.split('?')[0]}${params.length ? '?' + params : ''}`)
  }
}
