import { Controller } from "@hotwired/stimulus"
import { ajaxPatchTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="admin--commercial-actions-edit-employee"
export default class extends Controller {
  updateEmployee(e) {
    ajaxPatchTurboStream(`/admin/commercial_actions/${$(this.element).data().id}/update_employee`, {
      employeeId: $(e.currentTarget).data().employee
    })
  }
}
