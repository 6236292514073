import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--stock--product-stock--replenishment"
export default class extends Controller {
  connect() {
    $(document).off('click', '.send_modal_replenishement')
    $(document).off('click', '.next_modal_replenishement')
    $(document).off('click', '#replenishment_stock_container .rsc')
    $(document).on('click', '.send_modal_replenishement', () => {
      if(!$('.stock_movement_form').data().producedInWarehouse || $('.stock_movement_form').valid()) {
        this.send_mobile()
      }
      
    })
    $(document).on('click', '.next_modal_replenishement', () => {
      this.next()
    })

    $(document).on('click', '#replenishment_stock_container .rsc', (e) => {
      this.click(e)
    })
  }

  click(e) {
    var cell = $(e.currentTarget)
    this.active_cell = cell
    initModal()
    $('.modal-body').data('cell', this.active_cell.get(0).id)
    let data = this.active_cell.data()
    let data_parent = this.active_cell.parent().data()
    if(data.stockMovement) {
      $.get(`/back/stock_movements/${data.stockMovement}/edit?stock_type=${this.active_cell.data().stockType}`, () => {
        if((this.active_cell.parent().index() + 1) < $('.dataTables_scrollBody tbody:visible tr').length) {
          $('.modal-footer').append('<button id="modal-action" type="button" class="btn btn-primary next_modal_replenishement">Suivant</button>')
        }
      })
    } else {
      this.new(data, data_parent)
    }

  }

  new(data, data_parent){
    let params = [
      `date=${data.date}`,
      `productStock=${data_parent.productStock}`,
      `stock_type=${this.active_cell.data().stockType}`,
    ]
    if(this.active_cell.parents('table').data().entityType == 'Warehouse') {
      params.push(`warehouse=${this.active_cell.parents('table').data().entityId}`)
    }
    if(this.active_cell.parents('table').data().entityType == 'LocalShop') {
      params.push(`shop=${this.active_cell.parents('table').data().entityId}`)
    }

    $.get(`/back/stock_movements/new?${params.join('&')}`, () => {
      if($('.stock_movement_form').data().producedInWarehouse) {
        $('#stock_movement_quantity').removeClass('optional')
        $('.stock_movement_form').validate({
          rules: {
            'stock_movement[quantity]': {
              produced_in_warehouse_greater_than: true,
              produced_in_warehouse_has_transferts: true,
              produced_in_warehouse_quantity_equals_transferts: true
            }
          },
          messages: {
            'stock_movement[quantity]': {
              produced_in_warehouse_greater_than: "Vous devez renseigner une quantité totale produite",
              produced_in_warehouse_has_transferts: "Vous devez renseigner au moins une production vers une boutique",
              produced_in_warehouse_quantity_equals_transferts: "La quantité totale produite doit être égale à la somme des transferts"
            }            
          }
        })
        $('.stock_movement_form').on('change', '.stock_movement_remove_stock_movements_quantity input', () => {
          $('.stock_movement_form').valid()
        })
      }
      if((this.active_cell.parent().index() + 1) < $('.dataTables_scrollBody tbody:visible tr').length) {
        $('.modal-footer').append('<button id="modal-action" type="button" class="btn btn-primary next_modal_replenishement">Suivant</button>')
      }
    })
  }

  send_computer(e) {
    this.send($(e.currentTarget))
  }

  send_mobile() {
    this.send()
    //$('.modal').modal('hide')
  }

  next() {
    this.send()
    let row = this.active_cell.parent().index()
    let col = this.active_cell.index()
    $('tbody:visible tr').eq(row + 1).find('td').eq(col).click()
  }

  send() {
    this.active_cell = $(`#${$('.stock_movement_form').get(0).id}`)
    this.form = $('form').serializeHash()
    this.method = $('form').attr('method')
    this.action = $('form').attr('action')
    updatingModal("Votre stock est en cours de réapprovisionnement.")
    if(this.active_cell.parents('tr').data().first) {
      $.each(this.active_cell.parents('tr').find('td'), (index, element) => {
        if (index < this.active_cell.index() && index != 0) $(element).replaceWith('<td class="replenishement_stock_cell"></td>')
      })
    }
    this.active_cell.parents('tr').data('first', false)
    if(!this.active_cell.hasClass('preservable_stock_cell')) {
      this.active_cell.addClass('idle_stock_cell')
    }
    this.working_cell = this.active_cell
    this.create()
  }

  create() {
    $.ajax({
      type: this.method,
      url: this.action,
      data: this.form
    }).done((data) => {
      $('.modal').unbind('hide.bs.modal')
      $('.modal').modal('hide')
      $('#loading_app').removeClass('d-flex')
      this.build_cell(JSON.parse(data.stock_movement))
      this.working_cell.addClass('existing_stock_cell')
      $('.DTFC_LeftBodyLiner tr:visible').eq(this.working_cell.parent().index() + 1).outerHeight(this.working_cell.parent().outerHeight())
    })
  }

  build_cell(movement) {
    console.log(movement)
    console.log(movement.quantity)
    let html = ''
    if(movement.quantity) {
      html += `
        <p class="${movement.quantity == 0 && movement.supply_mode == 0 ? 'd-none' : ''}">
          <i class="fas ${movement.supply_mode == 0 ? 'fa-sign-in-alt' : 'fa-calculator'} text-success"></i>
          <span>${movement.quantity}</span>
        </p>
      `
    }
    console.log(movement.stock_broken)
    let broken = Object.values(movement.stock_broken).reduce((a, b) => a + b, 0)
    html += `
      <p class="stock_broken ${broken == 0 ? 'd-none' : ''}">
        <i class="fas fa-arrow-down text-danger"></i>
        <span>${broken}</span>
      </p>
    `
    $.each(movement.remove_stock_movements, (index, remove) => {
      if(remove.quantity && remove.quantity > 0) {
        html += `
          <p class="stock_removed_permanent ${!remove.quantity || remove.quantity == 0 ? 'd-none' : ''}">
            ${remove.quantity} <i class="fas fa-sign-out-alt text-info"></i> <i class="fas fa-${remove.shape}" style='color: ${remove.color}'></i> ${remove.acronym}
          </p>
        `
      }
    })
    $.each(movement.transferred_from_detailed, (index, transfert) => {
      html += `
        <p><i class="fas fa-${transfert.shape}" style="color: ${transfert.color}"></i> ${transfert.acronym} <i class="fas fa-sign-in-alt text-success"></i> ${transfert.quantity}</p>
      `
    })
    if(html && html.length) {
      $(`#${movement.cell_id}`).html(html)
    }
    
    $(`#${movement.cell_id}`).data('stockMovement', movement.id)
    $.each(movement.connected_ressupply, (index, ressupply) => {
      this.build_cell(ressupply)
    })
  }
}
