import { Controller } from "@hotwired/stimulus"
import { initTurboModal } from 'shared/modal'
// Connects to data-controller="admin--customer-invoice"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    $(this.element).find('select').on('select2:select',function(){this.dispatchEvent(new Event('change',{bubbles:true}))})

  }

  reload() {
    $(this.element).find('select').on('select2:select',function(){this.dispatchEvent(new Event('change',{bubbles:true}))})
  }

  data_filter(e) {
    var params = []

    if($('#invoice_status').val() != '_' && $('#invoice_status').val() !== null) {
      params.push(`status=${parseFloat($('#invoice_status').val())}`)
    }
    if($('#invoice_client').val() != '_' && $('#invoice_client').val() !== null) {
      params.push(`new_customer_id=${parseFloat($('#invoice_client').val())}`)
    }
    if($('.filter_card').data().dates.length) {
      params.push(`startDate=${$('.filter_card').data().dates.split('-')[0]}&endDate=${$('.filter_card').data().dates.split('-')[1]}`)
    }
    if($('#invoice_locam').val() != '_' && $('#invoice_locam').val() !== null) {
      params.push(`locam_status=${parseFloat($('#invoice_locam').val())}`)
    }

    if($('#invoice_type').val() != '_' && $('#invoice_type').val() !== null) {
      params.push(`invoice_type=${$('#invoice_type').val()}`)
    }
    let url = params.length ? `/admin/customer_invoices?${params.join('&')}` : `/admin/customer_invoices`
    Turbo.visit(url)
  }

  changeStatus(e) {
    let status = parseFloat($(e.currentTarget).val())
    if (status == 1){
      let cell = $(e.currentTarget).parents('td').parents('tr')
      let input = cell.find('.edit_payment_td')
      let input_group = input.find('.payment_row_quantity_container')
      input_group.find('input').val('')
      input_group.removeClass('d-none')
      cell.find('.edit_payment').addClass('d-none')
      input_group.find('input').focus()
    }else{
      let cell = $(e.currentTarget).parents('td').parents('tr')
      let input = cell.find('.edit_payment_td')
      let input_group = input.find('.payment_row_quantity_container')
      input_group.find('input').val('')
      input_group.addClass('d-none')
      cell.find('.edit_payment').removeClass('d-none')
      cell.find('.edit_payment').text('')
      input_group.find('input').focus()
    }
    let field = $(e.currentTarget).parent().parent().find('.invoice_color')
    $.ajax({
      type: 'PUT',
      data: {status: status},
      url: `/admin/customer_invoices/${$(e.currentTarget).data().id}/update_status`,
    }).done(function() {
        field.removeClass('bg-success bg-warning bg-info bg-danger')
        switch (status) {
        case 0:
          field.addClass('bg-danger')
          break;
        case 1:
          field.addClass('bg-info')
          break;

        case 2:
          field.addClass('bg-warning')
          break;

        case 3:
          field.addClass('bg-success')
          break;

        }
   })

  }

  delete(e){
    initModal()
    $('#modal_rails_5 .modal-title').text("Archivage d'une facture")
    $('#modal_rails_5 .modal-footer').hide()
    $('#modal_rails_5').modal('show')
    $('#modal_rails_5 .modal-body').html(`
      <p>Êtes-vous sûr de vouloir archiver cette facture ?</p>
      <hr>
      <div class='d-flex justify-content-end' data-controller="admin--customer-invoice-delete-modal" data-id="${$(e.currentTarget).data().id}">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Annuler</button>
        <p class='mb-0 btn btn-primary ml-2 clickable' data-action="click->admin--customer-invoice-delete-modal#deleteInvoice">Confirmer</p>
      </div>
    `)
  }


  editPayment(e) {
    $(e.currentTarget).after(`
      <div class='payment_row_quantity_container'>
        <label class="has-float-label input-group">
          <input class="form-control numeric float optional" type="number" step="any" value="">
          <div class="input-group-append validate_payment_interaction_quantity"  data-action="click->admin--customer-invoice#cancel_value">
            <span class="input-group-text"><i class="fas fa-times"></i></span>
          </div>
          <div class="input-group-append validate_payment_interaction_quantity" data-action="click->admin--customer-invoice#validate_price">
            <span class="input-group-text"><i class="fas fa-check"></i></span>
          </div>
        </label>
      </div>
    `)
    $(e.currentTarget).hide()
    $(e.currentTarget).parents('td').find('input').focus()
  }

  editDate(e) {
    $(e.currentTarget).after(`
      <div class='payment_row_quantity_container'>
        <label class="has-float-label input-group">
          <input class="form-control  float optional" value="">
          <div class="input-group-append validate_payment_interaction_quantity"  data-action="click->admin--customer-invoice#cancel_value">
            <span class="input-group-text"><i class="fas fa-times"></i></span>
          </div>
          <div class="input-group-append validate_payment_interaction_quantity" data-action="click->admin--customer-invoice#validate_date">
            <span class="input-group-text"><i class="fas fa-check"></i></span>
          </div>
        </label>
      </div>
    `)

    $(e.currentTarget).parents('td').find('input').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
      var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
      $(".datepicker").css("top", top);
    });
    $(e.currentTarget).hide()
    $(e.currentTarget).parents('td').find('input').focus()    
  }

  validate_date(e) {
    let id=$(e.currentTarget).parents('tr').data().id
    let cell = $(e.currentTarget).parents('td')
    let new_quantity = cell.find('input').val()
    cell.find('.payment_row_quantity_container').remove()
    cell.find('.edit_date_td').show()
    cell.find('.edit_date_td').text(new_quantity)
    $.ajax({
      method: 'PUT',
      url: '/admin/customer_invoices/'+id+'/update_date',
      data: {date : new_quantity}
    })    
  }


  validate_price(e) {
    let id=$(e.currentTarget).parents('tr').data().id
    let cell = $(e.currentTarget).parents('td')
    let new_quantity = parseFloat(cell.find('input').val())
    cell.find('.payment_row_quantity_container').remove()
    cell.find('.edit_payment').show()
    cell.find('.edit_payment').text(new_quantity +" €")
    $.ajax({
      method: 'PUT',
      url: '/admin/customer_invoices/'+id+'/update_payment',
      data: {waiting_rent : new_quantity}
    })
  }

  editValue(e) {
    $(e.currentTarget).after(`
      <div class='value_row_quantity_container'>
        <label class="has-float-label input-group">
          <input class="form-control numeric float optional" type="number" step="any" value="">
          <div class="input-group-append validate_value_interaction_quantity" data-action="click->admin--customer-invoice#cancel_value">
            <span class="input-group-text clickable"><i class="fas fa-times"></i></span>
          </div>
          <div class="input-group-append validate_value_interaction_quantity" data-action="click->admin--customer-invoice#validate_value">
            <span class="input-group-text clickable"><i class="fas fa-check"></i></span>
          </div>
        </label>
      </div>
    `)
    $(e.currentTarget).hide()
    $(e.currentTarget).parents('td').find('input').focus()
  }

  cancel_value(e) {
    $(e.currentTarget).parents('td').find('> span').show()
    $(e.currentTarget).parents('td').find('> div').remove()
  }

  validate_value(e) {
    let id=$(e.currentTarget).parents('tr').data().id
    let cell = $(e.currentTarget).parents('td')
    let new_quantity = parseFloat(cell.find('input').val())
    cell.find('> div').remove()
    cell.find('.edit_value').show()
    cell.find('.edit_value').text(new_quantity +" €")
    let row = cell.parents('tr')
    let cell2 = row.find('.edit_value_ttc_td')
    let tax = row.find('.tax_value_td').text()
    cell2.find(".edit_value_ttc").text(new_quantity + (new_quantity * tax / 100) + " €")
    $.ajax({
      method: 'PUT',
      url: `/admin/customer_invoices/${id}/update_value`,
      data: {value : new_quantity}
    })
  }

  editValueTtc(e) {
    $(e.currentTarget).hide()
    $(e.currentTarget).after(`
      <div class='value_row_quantity_container'>
        <label class="has-float-label input-group">
          <input class="form-control numeric float optional" type="number" step="any" value="">
          <div class="input-group-append validate_value_interaction_quantity"  data-action="click->admin--customer-invoice#cancel_value">
            <span class="input-group-text clickable"><i class="fas fa-times"></i></span>
          </div>
          <div class="input-group-append validate_value_interaction_quantity" data-action="click->admin--customer-invoice#validate_value_ttc">
            <span class="input-group-text clickable"><i class="fas fa-check"></i></span>
          </div>
        </label>
      </div>
    `)
    $(e.currentTarget).parents('td').find('input').focus()
  }

  validate_value_ttc(e) {
    let id=$(e.currentTarget).parents('tr').data().id
    let cell = $(e.currentTarget).parents('td')
    let new_quantity = parseFloat(cell.find('input').val())
    cell.find('> div').remove()
    cell.find('.edit_value_ttc').show()
    cell.find('.edit_value_ttc').text(new_quantity +" €")
    let row = cell.parents('tr')
    let cell2 = row.find('.edit_value_td')
    let tax = row.find('.tax_value_td').text()
    cell2.find(".edit_value").text((new_quantity * 100 / (100 + parseFloat(tax))).toFixed(2) + " €")
    $.ajax({
      method: 'PUT',
      url: '/admin/customer_invoices/'+id+'/update_value',
      data: {value : (new_quantity * 100 / (100 + parseFloat(tax))).toFixed(2)}
    })
  }

  editValueTax(e) {
    $(e.currentTarget).hide()
    $(e.currentTarget).after(`
      <div class='value_row_quantity_container'>
        <label class="has-float-label input-group">
          <input class="form-control numeric float optional" type="number" step="any" value="">
          <div class="input-group-append validate_value_interaction_quantity"  data-action="click->admin--customer-invoice#cancel_value">
            <span class="input-group-text clickable"><i class="fas fa-times"></i></span>
          </div>
          <div class="input-group-append validate_value_interaction_quantity" data-action="click->admin--customer-invoice#validate_value_tax">
            <span class="input-group-text clickable"><i class="fas fa-check"></i></span>
          </div>
        </label>
      </div>
    `)
    $(e.currentTarget).parents('td').find('input').focus()
  }

  validate_value_tax(e) {
    let id=$(e.currentTarget).parents('tr').data().id
    let cell = $(e.currentTarget).parents('td')
    let new_tax = parseFloat(cell.find('input').val())
    cell.find('.value_row_quantity_container').remove()
    cell.find('> span').show()
    cell.find('.edit_value_tax').text(new_tax)
    let row = cell.parents('tr')
    let cell_ht = row.find('.edit_value_td')
    let cell_ttc = row.find('.edit_value_ttc_td')

    let ht = cell_ht.find('.edit_value').text()
    var new_ht = parseFloat(ht.substring(0, ht.length - 1))
    cell_ttc.find(".edit_value_ttc").text(new_ht + (new_ht * new_tax / 100))

    $.ajax({
      method: 'PUT',
      url: '/admin/customer_invoices/'+id+'/update_tax',
      data: {tax : new_tax}
    })
  }

}
