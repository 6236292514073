import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="back--promotion-index"
export default class extends Controller {
  applyBatchAction() {
    let selected_ids = $('#promotion_index tbody input:checked').map((i, e) => {return $(e).parents('tr').data().id}).get()
    console.log(selected_ids)
    if(selected_ids.length == 0) {
      return false
    }

    switch($('#promotions_batch_actions_container select').val()) {
      case 'update_shops':
        ajaxPostTurboStream(
          '/back/promotion_update_shops/new_update',
          {promotionIds: selected_ids}
        )        
        break;
      default:
    }
  }

  toggleAll(e) {
    if($(e.currentTarget).find('input').prop('checked')) {
      $('#promotion_index tbody input').prop('checked', false)
      $(e.currentTarget).find('input').prop('checked', false)  
    } else {
      $('#promotion_index tbody input').prop('checked', true)
      $(e.currentTarget).find('input').prop('checked', true)  
    }
    
  }

  toggleCheckbox(e) {
    $('#promotion_index thead input').prop('checked', false)
    $(e.currentTarget).find('input').prop('checked', !$(e.currentTarget).find('input').prop('checked'))
  }  
}
