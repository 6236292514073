import Utils__TurboNavController from "controllers/utils/turbo_nav_controller"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="back--replenishment-stock-tabs"
export default class extends Utils__TurboNavController {
  toggleCallback() {
    console.log(this.currentTab)
    if(this.currentTab.html().trim().length == 0) {
      this.currentTab.html("<p>loading</p>")
      let params = Object.fromEntries(new URLSearchParams(window.location.search).entries())
      params.current_tab = this.currentNav.data().target
      console.log(new URLSearchParams(window.location.search))
      console.log(this.currentNav)
      ajaxPostTurboStream(
        '/back/replenishement_stocks/load_tab',
        params
      )
    }
  }
}
