import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
import { flashMessage } from 'shared/notify'
// Connects to data-controller="admin--shop-edit"
export default class extends Controller {
  connect() {
    this.shopId = $(this.element).data().id
    this.shopGroupId = $(this.element).data().shopGroup
  }

  refreshAction(e) {
    let container = $(e.currentTarget).parents('.shop_action')
    if(window.location.href.includes('backend.toporder.fr')) {
      if(container.find('input').length && container.find('input').val().length == 0) {
        container.find('span').text('Veuillez entrer le code de vérification')
        return false
      }
      if(container.find('input').length && container.find('input').val() != '1992') {
        container.find('span').text("Le code de vérification n'est pas bon")
        return false
      }
      container.find('span').text("")
      ajaxTurboStream(`${$(e.currentTarget).data().url}&code=${container.find('input').val()}`, false)
    } else {
      ajaxTurboStream(`${$(e.currentTarget).data().url}&code=dev`, false)
    }
  }

  updateActivationStatus(e) {
    $.ajax({
      url: `/admin/shops/${$(e.currentTarget).parents('fieldset').data().shop}/toggle_activationStatus`,
      method: 'POST',
      data: {status: $(e.currentTarget).find('input').val()}
    })
  }

  updateFiscalOpeningHour() {
    let mom = moment()
    mom.minute($('#shop_fiscal_open_minute').valF())
    mom.hour($('#shop_fiscal_open_hour').valF())
    let midnight = mom.clone().startOf('day')

    $.ajax({
      url: `/admin/shops/${this.shopId}/change_fiscal_open_hour`,
      method: 'PUT',
      data: {minutes: mom.diff(midnight, 'minutes')}
    }).done((result) => {
      if(result.success) {
        flashMessage('', "L'heure d'ouverture a bien été modifiée", 'success')
      } else {
        flashMessage('', "Une journée est ouverte. L'heure n'a donc pas pu être modifiée", 'danger')
      }
      
    })
}

  updateMultiCashRegister(e) {
    $.ajax({
      url: '/admin/shops/toggle_multicashregister',
      data: {setting: $(e.currentTarget).data().setting, value: $(e.currentTarget).prop('checked'), shop_group_id: this.shopGroupId},
      method: 'POST'
    }).done(() => {
      flashMessage('', "Le réglage a bien été modifié", 'success')
    })
  }

  updateShopTimeZone() {
    $.ajax({
      url: `/admin/shops/${this.shopId}/change_timezone`,
      method: 'PUT',
      data: {timezone: $(`#timezone_select_${this.shopId}`).val()}
    }).done(() => {
      flashMessage('', "La timezone a bien été modifiée", 'success')
    })
  }
}
